// This is the validation rules used to validate each feild in the form
import * as Yup from "yup";
import moment from "moment";

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  idRequired: Yup.string().required("This Field Is Required"),
  text: Yup.string().nullable(),
  textRequired: Yup.string().required("This Field Is Required").nullable(),
  email: Yup.string().email(),
  emailRequired: Yup.string().email().required("This Field Is Required"),
  selectorId: Yup.string().matches(/^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/, {
    message: "Please Select an Option",
  }),
  number: Yup.string()
    .required("This Field Is Required")
    .matches(/^\d*[.]?\d*$/, { message: "Please provide availd number" }),
  selectorIdRequired: Yup.string()
    .matches(/^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/, {
      message: "Please Select an Option",
    })
    .required("This Field Is Required"),
  selectorBoolean: Yup.string(),
  selectorBooleanRequired: Yup.string().required("This Field Is Required"),
  array: Yup.array(),
  arrayRequired: Yup.array().required("This Field Is Required"),
  time: Yup.string().required("start time cannot be empty"),
  start_time: Yup.string()
    .required("start time cannot be empty")
    .test(
      "is-greater",
      "start time should be before end time",
      function (startTime) {
        const { endTime } = this.options.context;

        return moment(endTime, "HH:mm").isSameOrAfter(
          moment(startTime, "HH:mm")
        );
      }
    ),
  end_time: Yup.string()
    .required("end time cannot be empty")
    .test(
      "is-greater",
      "end time should be after start Time",
      function (endTime) {
        const { startTime } = this.options.context;

        return moment(endTime, "HH:mm").isSameOrAfter(
          moment(startTime, "HH:mm")
        );
      }
    ),
  start_date: Yup.string()
    .required("start time cannot be empty")
    .test(
      "is-greater",
      "start time should be before end time",
      function (startDate) {
        const { endDate } = this.options.context;

        return moment(endDate, "yyyy/MM/DD").isSameOrAfter(
          moment(startDate, "yyyy/MM/DD")
        );
      }
    ),
  end_date: Yup.string()
    .required("end time cannot be empty")
    .test(
      "is-greater",
      "end time should be after start Time",
      function (endDate) {
        const { startDate } = this.options.context;
        console.log(endDate,startDate)

        return moment(endDate, "yyyy/MM/DD").isSameOrAfter(
          moment(startDate, "yyyy/MM/DD")
        );
      }
    ),
});

const ValidateEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
});
const ValidateTextSchema = Yup.object().shape({
  text: Yup.string().required("Field is required"),
});

export default {
  ValidateEmailSchema,
  ValidateTextSchema,
  validationSchema,
};
