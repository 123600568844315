import React from "react";
import { connect } from "react-redux";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import CloseIcon from "@material-ui/icons/Close";
import {
  removePickupDonorAction,
  showDonorFormAction,
} from "../../Store/Actions";
import DonorForm from "../DonorsList/DonorForm";

const AddDonor = (props) => {
  return (
    <>
      <div className="add_donor_pickup_modal_wrapper_style">
        <label>donor</label>
        <div className="d-flex justify-content-between">
          <div className="d-flex donor_info_in_modal_style">
            <h1 className="mr-4">{props?.donor_name}</h1>
          </div>
          {!props.edit ? (
            <div className="d-flex">
              {!Boolean(+props.donor_id) ? (
                <div>
                  <button
                    className="btn add_new_btn_style"
                    type="button"
                    onClick={() => props.showDonorFormAction("New")}
                  >
                    <PersonAddOutlinedIcon />
                    <p>new donor</p>
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    className="btn delete_btn_style ml-2"
                    type="button"
                    onClick={() => props.removePickupDonorAction()}
                  >
                    <CloseIcon />
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <DonorForm />
    </>
  );
};

export default connect(null, { removePickupDonorAction, showDonorFormAction })(
  AddDonor
);
