import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


import { Privileges, Roles, RolePrivilege } from './Privileges';

import Store from '../../../Store';




// export const useCheckPrivileges = ({ can = [] }) => {
//     const authRole = Store.getState().auth?.userProfile?.role?.id?.toString();
//     const authPrivileges = RolePrivilege[authRole];

//     if (can?.some((priv) => authPrivileges?.includes(priv))) {
//         return true
//     } else {
//         return false
//     }

// }

export const useCheckPrivileges = ({ can = [] }) => {
    const [authrized, setAuthrized] = useState(false);
    const authRole = useSelector((state) => state.auth?.userProfile?.role?.id)?.toString();
    const authPrivileges = RolePrivilege[authRole];

    useEffect(() => {
        if (can?.some((priv) => authPrivileges?.includes(priv))) {
            setAuthrized(true)
        } else {
            setAuthrized(false)
        }
    }, [can, authRole]);
    return authrized

}




const HasPrivileges = (props) => {

    const authRole = useSelector((state) => state.auth?.userProfile?.role?.id)?.toString();
    const authPrivileges = RolePrivilege[authRole];

    if (props.can?.some((priv) => authPrivileges?.includes(priv))) {
        return props.children
    }

    return props?.altChildren ?? null
}

export default HasPrivileges