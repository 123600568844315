import Types from "../Actions/types";
import initStore from "../InitialState";

export const initState = {
  allUsersList: initStore.usersList,
  usersFilterOptions: [],
  userForm: initStore.userForm,
  userFormValidation: [],
  userFormModal: {
    isOpen: false,
    type: "",
    isLoading: false,
    errorMessage: "",
  },
  allDonorsList: initStore.donorsList,
  DonorsFiler: {
    cityOptions: [],
    areaOptions: [],
  },
  donorForm: {
    ...initStore.donorForm,
    addresses: [
      {
        id: "",
        name: "",
        map_link: "",
        city_id: "13",
        area_id: "",
        default_address: 1,
      },
    ],
  },
  donorFormValidation: [],
  donorLocation: initStore.addressInput,
  donorFormModal: {
    isOpen: false,
    type: "",
    isLoading: false,
    errorMessage: "",
  },
  donorFormServerValidation: {},
  allpickupsList: initStore.pickupsList,
  pickupsFiler: {
    cityOptions: [],
    areaOptions: [],
    donorOptions: [],
    collectorOptions: [],
  },

  pickupForm: initStore.pickupForm,
  pickupFormValidation: [],
  pickupFormServerValidation: {},
  pickupFormModal: {
    isOpen: false,
    type: "",
    isLoading: false,
    errorMessage: "",
  },
  pickupAddressForm: initStore.pickupAddressForm,
  pickupAddressFormValidation: [],

  selectedCollectorProfile: initStore.collectorProfile,
  collectionsList: initStore.collections,

  donorPickupsList: initStore.donorPickups,
  donorProfile: {
    id: "",
    name: "",
    phone: "",
    alt_phone: "",
    email: "",
    addresses: [],
    donations: [],
  },
  transactionInput: {
    id: "",
    amount: "",
    currency_id: "",
  },
  balanceFormModal: {
    isOpen: false,
    type: "",
    isLoading: false,
    errorMessage: "",
  },
  balanceForm: {
    id: "",
    collector_id: "",
    type_id: "",
    transactions: [
      {
        id: "",
        amount: "",
        currency_id: "",
      },
    ],
  },
  balanceFormValidation: [],
  balanceFormServerValidation: {},

  addBalanceForm: initStore.addBalanceForm,
  addBalanceFormValidation: [],
  addBalanceFormServerValidation: {},
  addBalanceFormModal: {
    isOpen: false,
    type: "",
    isLoading: false,
    errorMessage: "",
    isEdit: false,
  },

  pickupDonor: {
    phone: "",
  },
  pickupDonorValid: [],
  pickupCollectorsList: {
    data: [],
  },
  editPickupCollectionModal: {
    isOpen: false,
    isLoading: false,
    name: "",
  },
  editPickupCollection: {
    id: null,
    collector_id: null,
    type_id: "Pickup",
    has_cash: 0,
    has_material: 0,
    material_note: "",
    transactions: [
      {
        id: "",
        amount: 0,
        currency_id: "",
      },
    ],
  },
  editPickupCollectionValidation: [],
  editPickupCollectionServerValidation: {},
  isDeleteBalaceLoading: false,
  fetchCollectedPickupLoading: false,
  deleteTransactionLoading: false,
};

export const superReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.FETCH_USERS_ATTEMPT:
    case Types.FETCH_DONORS_ATTEMPT:
    case Types.FETCH_COLLECTIONS_LIST_ATTEMPT:
    case Types.FETCH_PICKUPS_LIST_ATTEMPT:
    case Types.FETCH_DONOR_PICKUPS_LIST_ATTEMPT:
    case Types.FETCH_PICKUP_COLLECTORS_ATTEMPT:
    case Types.FETCH_DONOR_VIEW_ATTEMPT:
    case Types.FETCH_DONORS_VIEW_ATTEMPT:
    case Types.FETCH_PICKUPS_ATTEMPT:
    case Types.FETCH_PICKUP_COLLECTORS_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_USERS_SUCCESS:
    case Types.FETCH_DONORS_SUCCESS:
    case Types.FETCH_COLLECTIONS_LIST_SUCCESS:
    case Types.FETCH_PICKUPS_LIST_SUCCESS:
    case Types.FETCH_DONOR_PICKUPS_LIST_SUCCESS:
    case Types.FETCH_PICKUP_COLLECTORS_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action.payload.list.data,
          pagination: action.payload.list.paginatorInfo,
        },
      };
    case Types.FETCH_USERS_FAILED:
    case Types.FETCH_DONORS_FAILED:
    case Types.FETCH_COLLECTIONS_LIST_FAILED:
    case Types.FETCH_PICKUPS_LIST_FAILED:
    case Types.FETCH_DONOR_PICKUPS_LIST_FAILED:
    case Types.FETCH_PICKUP_COLLECTORS_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: [],
          pagination: initState[action.payload.ref]?.pagination,
        },
      };
    case Types.FETCH_USERS_FILTER:
      return {
        ...state,
        usersFilterOptions: action.payload,
      };

    case Types.FETCH_PICKUPS_LIST_FILTER:
      return {
        ...state,
        pickupsFiler: {
          ...state.pickupsFiler,
          donorOptions: action.payload.donorsFilter,
          collectorOptions: action.payload.collectorsFilter,
        },
      };

    case Types.SHOW_USER_FORM:
      return {
        ...state,
        userFormModal: {
          ...state.userFormModal,
          isOpen: true,
          type: action.payload.type,
        },
        userForm: action.payload.user ?? initState.userForm,
      };

    case Types.DISMISS_USER_FORM:
    case Types.UPSERT_USER_SUCCESS:
      return {
        ...state,
        userFormModal: initState.userFormModal,
        userForm: initState.userForm,
      };

    case Types.UPSERT_USER_ATTEMPT:
      return {
        ...state,
        userFormModal: {
          ...state.userFormModal,
          isLoading: true,
          errorMessage: "",
        },
      };
    case Types.UPSERT_USER_FAILED:
      return {
        ...state,
        userFormModal: {
          ...state.userFormModal,
          isLoading: false,
          errorMessage: action.payload,
        },
      };

    //handle donors actions

    case Types.FETCH_DONORS_FILTER:
      return {
        ...state,
        DonorsFiler: {
          cityOptions: action.payload.citiesFilter,
          areaOptions: action.payload.areasFilter,
        },
      };

    //handle collector Profile actions

    case Types.FETCH_COLLECTOR_PROFILE_ATTEMPT:
      return {
        ...state,
        selectedCollectorProfile: {
          ...state.selectedCollectorProfile,
          isLoading: true,
        },
      };

    case Types.FETCH_COLLECTOR_PROFILE_SUCCESS:
      return {
        ...state,
        selectedCollectorProfile: {
          ...state.selectedCollectorProfile,
          isLoading: false,
          ...action.payload.collector,
        },
      };

    case Types.FETCH_COLLECTOR_PROFILE_FAILED:
      return {
        ...state,
        selectedCollectorProfile: {
          ...state.selectedCollectorProfile,
          isLoading: false,
        },
      };

    case Types.RESET_COLLECTOR_PROFILE:
      return {
        ...state,
        selectedCollectorProfile: initState.selectedCollectorProfile,
      };

    case Types.ADD_DONOR_ADDRESS:
      return {
        ...state,
        donorForm: {
          ...state.donorForm,
          addresses: [
            ...state.donorForm.addresses,
            { ...initState.donorLocation },
          ],
        },
      };

    case Types.REMOVE_DONOR_ADDRESS:
      return {
        ...state,
        donorForm: {
          ...state.donorForm,
          addresses: state.donorForm.addresses.filter(
            (_, i) => i !== action.payload
          ),
        },
      };

    case Types.ADDRESS_INPUT_CHANGE:
      if (action.payload.name == "default_address") {
        return {
          ...state,
          donorForm: {
            ...state.donorForm,
            addresses: state.donorForm.addresses.map((address, i) =>
              i == action.payload.index
                ? { ...address, default_address: 1 }
                : { ...address, default_address: 0 }
            ),
          },
        };
      }
      const values = [...state.donorForm.addresses];
      values[action.payload.index][action.payload.name] = action.payload.value;
      return {
        ...state,
        donorForm: {
          ...state.donorForm,
          addresses: values,
        },
      };
    case Types.BALANCE_INPUT_CHANGE:
      let transactionValues = [...state[action.payload.formName].transactions];
      transactionValues[action.payload.index][action.payload.name] =
        action.payload.value;
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          transactions: transactionValues,
        },
      };

    case Types.SHOW_DONOR_FORM:
      return {
        ...state,
        donorFormModal: {
          ...state.donorFormModal,
          isOpen: true,
          type: action.payload.type,
        },
        donorForm: action.payload.user ?? {
          ...initState.donorForm,
          addresses: [
            {
              id: "",
              name: "",
              map_link: "",
              city_id: "13",
              area_id: "",
              default_address: 1,
            },
          ],
        },
      };
    case Types.SHOW_SETTLE_BALANCE_FORM:
      return {
        ...state,
        balanceFormModal: {
          ...state.balanceFormModal,
          isOpen: true,
          type: action.payload.type,
        },
        balanceForm: action.payload.balance,
      };
    case Types.DISMISS_SETTLE_BALANCE_FORM:
      return {
        ...state,
        balanceFormModal: initState.balanceFormModal,
        balanceForm: initState.balanceForm,
        balanceFormServerValidation: initState.balanceFormServerValidation,
      };

    case Types.DISMISS_DONOR_FORM:
    case Types.UPSERT_DONOR_SUCCESS:
      return {
        ...state,
        donorFormModal: initState.donorFormModal,
        donorForm: {
          ...initState.donorForm,
          addresses: [
            {
              id: "",
              name: "",
              map_link: "",
              city_id: "13",
              area_id: "",
              default_address: 1,
            },
          ],
        },
        donorFormServerValidation: initState.donorFormServerValidation,
      };

    case Types.UPSERT_DONOR_ATTEMPT:
      return {
        ...state,
        donorFormModal: {
          ...state.donorFormModal,
          isLoading: true,
          errorMessage: "",
        },
        donorFormServerValidation: initState.donorFormServerValidation,
      };
    case Types.UPSERT_DONOR_FAILED:
      return {
        ...state,
        donorFormModal: {
          ...state.donorFormModal,
          isLoading: false,
        },
        donorFormServerValidation: {
          ...action.payload.validation,
        },
      };
    case Types.FETCH_DONOR_SUCCESS:
      let { addresses, address, donations, ...user } = action.payload.user;
      return {
        ...state,
        donorForm: {
          ...user,
          addresses: addresses.map(({ id, name, map_link, area }) => ({
            id,
            name,
            map_link,
            area_id: area.id,
            city_id: area.city.id,
            default_address: +(id == address.id),
          })),
        },
        donorFormModal: {
          ...state.donorFormModal,
          isLoading: false,
          isOpen: true,
          type: "edit",
        },
      };

    case Types.FETCH_DONOR_PROFILE_SUCCESS:
      return {
        ...state,
        donorProfile: action.payload?.user,
      };
    case Types.SETTLE_COLLECTOR_BALANCE_SUCCESS:
      return {
        ...state,
        balanceFormModal: initState.balanceFormModal,
        balanceForm: {
          ...initState.balanceForm,
          transactions: [
            {
              id: "",
              amount: "",
              currency_id: "",
            },
          ],
        },
        balanceFormServerValidation: initState.balanceFormServerValidation,
      };

    case Types.SETTLE_COLLECTOR_BALANCE_ATTEMPT:
      return {
        ...state,
        balanceFormModal: {
          ...state.balanceFormModal,
          isLoading: true,
          errorMessage: "",
        },
        balanceFormServerValidation: initState.balanceFormServerValidation,
      };
    case Types.SETTLE_COLLECTOR_BALANCE_FAILED:
      return {
        ...state,
        balanceFormModal: {
          ...state.balanceFormModal,
          isLoading: false,
        },
        balanceFormServerValidation: action.payload.validation
          ? {
              ...action.payload.validation,
            }
          : initState.balanceFormServerValidation,
      };
    //pickup upsert
    case Types.UPSERT_PICKUP_FORM_SUCCESS:
      return {
        ...state,
        pickupFormModal: initState.pickupFormModal,
        pickupForm: initState.pickupForm,
        pickupFormServerValidation: initState.pickupFormServerValidation,
        pickupDonor: initState.pickupDonor,
      };

    case Types.UPSERT_PICKUP_FORM_ATTEMPT:
      return {
        ...state,
        pickupFormModal: {
          ...state.pickupFormModal,
          isLoading: true,
          errorMessage: "",
        },
        pickupFormServerValidation: initState.pickupFormServerValidation,
      };
    case Types.UPSERT_PICKUP_FORM_FAILED:
      return {
        ...state,
        pickupFormModal: {
          ...state.pickupFormModal,
          isLoading: false,
        },
        pickupFormServerValidation: action.payload.validation
          ? {
              ...action.payload.validation,
            }
          : initState.pickupFormServerValidation,
      };

    case Types.FETCH_PICKUP_DONOR_ATTEMPT:
      return {
        ...state,
        pickupForm: {
          ...initState.pickupForm,
          donor: {
            name: "loading",
            id: "",
          },
        },
      };

    case Types.FETCH_PICKUP_DONOR_SUCCESS:
      if (action.payload.list.length !== 1)
        return {
          ...state,
          pickupForm: {
            ...initState.pickupForm,
            donor: {
              name: "No results found",
              id: "",
              addresses: [],
            },
          },
        };
      return {
        ...state,
        pickupDonor: action.payload.list[0],
        pickupForm: {
          ...state.pickupForm,
          donor_id: action.payload.list[0].id,
          address_id: action.payload.list[0]?.address?.id,
          donor: {
            name: action.payload.list[0]?.name,
            id: action.payload.list[0]?.id,
            addresses: action.payload.list[0]?.addresses,
          },
        },
      };

    case Types.FETCH_PICKUP_DONOR_FAILED:
      return {
        ...state,
        pickupDonor: initState.pickupDonor,
        pickupForm: {
          ...initState.pickupForm,
          donor: {
            name: "No results found",
            id: "",
            addresses: [],
          },
        },
      };

    case Types.REMOVE_PICKUP_DONOR:
      return {
        ...state,
        pickupDonor: {
          phone: "",
        },
        pickupForm: {
          ...initState.pickupForm,
          donor: {
            name: "No results found",
            id: "",
            addresses: [],
          },
        },
      };

    case Types.SHOW_PICKUP_FORM:
      if (action.payload.type) {
        return {
          ...state,
          pickupFormModal: {
            ...state.pickupFormModal,
            isOpen: true,
            type: action.payload.type,
          },
          pickupDonor: {
            phone: "",
          },
          pickupForm: action.payload.pickup,
        };
      }
      return {
        ...state,
        pickupFormModal: {
          ...state.pickupFormModal,
          isOpen: true,
        },
        pickupDonor: {
          phone: "",
        },
        pickupForm: initState.pickupForm,
      };

    case Types.DISMISS_PICKUP_FORM:
      return {
        ...state,
        pickupFormModal: initState.pickupFormModal,
        pickupForm: initState.pickupForm,
        pickupFormServerValidation: [],
        pickupDonor: {
          phone: "",
        },
      };

    case Types.SHOW_ADD_BALANCE_FORM:
      return {
        ...state,
        addBalanceFormModal: {
          ...state.addBalanceFormModal,
          isOpen: true,
          isEdit: false,
        },
        addBalanceForm: {
          id: null,
          collector_id: action.payload?.collector_id,
          type_id: "Add_Balance",
          transactions: [
            {
              id: null,
              amount: 0,
              currency_id: "1",
            },
          ],
        },
      };

    case Types.SHOW_EDIT_ADD_BALANCE_FORM:
      return {
        ...state,
        addBalanceFormModal: {
          ...state.addBalanceFormModal,
          isOpen: true,
          isEdit: true,
        },
        addBalanceForm: action.payload.collection,
      };

    case Types.DISMISS_ADD_BALANCE_FORM:
    case Types.ADD_COLLECTOR_BALANCE_SUCCESS:
      return {
        ...state,
        addBalanceForm: {
          id: null,
          collector_id: null,
          type_id: "Add_Balance",
          transactions: [
            {
              id: null,
              amount: 0,
              currency_id: "1",
            },
          ],
        },
        addBalanceFormModal: initState.addBalanceFormModal,
        addBalanceFormValidation: [],
        addBalanceFormServerValidation: {},
      };

    case Types.ADD_COLLECTOR_BALANCE_ATTEMPT:
      return {
        ...state,
        addBalanceFormModal: {
          ...state.addBalanceFormModal,
          isLoading: true,
        },
        addBalanceFormServerValidation: {},
      };

    case Types.ADD_COLLECTOR_BALANCE_FAILED:
      return {
        ...state,
        addBalanceFormModal: {
          ...state.addBalanceFormModal,
          isLoading: false,
        },
        addBalanceFormServerValidation: action.payload.validation
          ? {
              ...action.payload.validation,
            }
          : initState.addBalanceFormServerValidation,
      };

    case Types.ADD_BALANCE_FIELD:
      return {
        ...state,
        addBalanceForm: {
          ...state.addBalanceForm,
          transactions: [
            ...state.addBalanceForm.transactions,
            {
              id: null,
              amount: 0,
              currency_id: "",
            },
          ],
        },
      };

    case Types.REMOVE_BALANCE_FIELD:
      return {
        ...state,
        addBalanceForm: {
          ...state.addBalanceForm,
          transactions: state.addBalanceForm.transactions.filter(
            (_, i) => i !== action.payload
          ),
        },
      };

    case Types.SHOW_EDIT_PICKUP_COLLECTION:
      return {
        ...state,
        editPickupCollectionModal: {
          ...initState.editPickupCollectionModal,
          isOpen: true,
          name: action.payload?.name,
        },
        editPickupCollection: action.payload?.collection,
      };

    case Types.DISMISS_EDIT_PICKUP_COLLECTION:
    case Types.EDIT_PICKUP_COLLECTION_SUCCESS:
      return {
        ...state,
        editPickupCollectionModal: initState.editPickupCollectionModal,
        editPickupCollection: {
          id: null,
          collector_id: null,
          type_id: "Pickup",
          has_cash: 0,
          has_material: 0,
          material_note: "",
          transactions: [
            {
              id: "",
              amount: 0,
              currency_id: "",
            },
          ],
        },
        editPickupCollectionServerValidation: {},
        editPickupCollectionValidation: [],
      };

    case Types.EDIT_PICKUP_COLLECTION_ATTEMPT:
      return {
        ...state,
        editPickupCollectionModal: {
          ...state.editPickupCollectionModal,
          isLoading: true,
        },
        editPickupCollectionServerValidation: {},
      };
    case Types.EDIT_PICKUP_COLLECTION_FAILED:
      return {
        ...state,
        editPickupCollectionModal: {
          ...state.editPickupCollectionModal,
          isLoading: false,
        },
        editPickupCollectionServerValidation: action.payload.validation
          ? {
              ...action.payload.validation,
            }
          : initState.editPickupCollectionServerValidation,
      };

    case Types.DELETE_BALANCE_ATTEMPT:
    case Types.CANCEL_PICKUP_ATTEMPT:
      if (action.payload.collectorId) {
        return {
          ...state,
          isDeleteBalaceLoading: true,
        };
      }
      return state;

    case Types.DELETE_BALANCE_SUCCESS:
    case Types.CANCEL_PICKUP_SUCCESS:
    case Types.DELETE_BALANCE_FAILED:
    case Types.CANCEL_PICKUP_FAILED:
      return {
        ...state,
        isDeleteBalaceLoading: false,
      };
    case Types.FETCH_COLLECTED_PICKUP_ATTEMPT:
      return {
        ...state,
        fetchCollectedPickupLoading: true,
      };
    case Types.FETCH_COLLECTED_PICKUP_SUCCESS:
    case Types.FETCH_COLLECTED_PICKUP_FAILED:
      return {
        ...state,
        fetchCollectedPickupLoading: false,
      };

    case Types.DELETE_TRANSACTION_ATTEMPT:
      return {
        ...state,
        deleteTransactionLoading: true,
      };
    case Types.DELETE_TRANSACTION_SUCCESS:
    case Types.DELETE_TRANSACTION_FAILED:
      return {
        ...state,
        deleteTransactionLoading: false,
      };

    case Types.LOG_OUT:
      return { ...initState };

    default:
      return state;
  }
};
