import Types from "./types";

/* Start Builder Actions */

// HAMNDLE INPUTS CHANGE
export const onInputChangeAction = (formName, event, type = "", value = "") => {
  switch (type) {
    case "radio":
      return {
        type: Types.ON_RADIO_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };
    case "checkbox":
      return {
        type: Types.ON_CHECKBOX_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };

    case "booleanCheckbox":
      return {
        type: Types.ON_CHECKBOX_BOOLEAN_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };

    case "select":
      return {
        type: Types.ON_SELECT_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };
    case "append":
      return {
        type: Types.ON_APPEND_INPUT_CHANGE,
        payload: {
          formName,
          event,
          value,
        },
      };

    default:
      return {
        type: Types.ON_INPUT_CHANGE,
        payload: {
          formName,
          event,
        },
      };
  }
};

// HAMNDLE INPUTS Increment
export const onInputIncrementAction = (formName, name) => {
  return {
    type: Types.ON_INPUT_INCREMENT,
    payload: {
      formName,
      name,
    },
  };
};

// HAMNDLE INPUTS Dencrement
export const onInputDecrementAction = (formName, name) => {
  return {
    type: Types.ON_INPUT_DECREMENT,
    payload: {
      formName,
      name,
    },
  };
};

// HAMNDLE INPUTS RESET
export const onInputResetAction = (formName, name) => {
  return {
    type: Types.ON_INPUT_RESET,
    payload: {
      formName,
      name,
    },
  };
};

//Handle input form validation
export const setInputValidateAction = (formNameValidation, name) => {
  return {
    type: Types.SET_INPUT_VALID,
    payload: {
      formNameValidation,
      name,
    },
  };
};

export const setInputInValidateAction = (formNameValidation, name) => {
  return {
    type: Types.SET_INPUT_INVALID,
    payload: {
      formNameValidation,
      name,
    },
  };
};

//handle dataTable #sorting #paginate #filter
export const setDataTableSortingAction = (ref, key, dir) => {
  return {
    type: Types.SET_DATATABLE_SORTING,
    payload: {
      ref,
      key,
      dir,
    },
  };
};

export const setDataTableFilterAction = (ref, filter = "") => {
  return {
    type: Types.SET_DATATABLE_FILTER,
    payload: {
      ref,
      filter,
    },
  };
};
export const setDataTablePaginateAction = (ref, pagination) => {
  return {
    type: Types.SET_DATATABLE_PAGINATE,
    payload: {
      ref,
      pagination,
    },
  };
};
/* End Builder Actions */

/** >>>> START FETCH USERS DATATABLE <<<< **/

export const fetchAllUsersAction = ({ ...filter }) => {
  return {
    type: Types.FETCH_USERS_ATTEMPT,
    payload: {
      ...filter,
    },
  };
};

export const fetchAllUsersSuccess = (ref, list) => {
  return {
    type: Types.FETCH_USERS_SUCCESS,
    payload: { ref, list },
  };
};

export const fetchAllUsersFilter = ({ data }) => {
  return {
    type: Types.FETCH_USERS_FILTER,
    payload: data,
  };
};

export const fetchAllUsersFailed = (ref) => {
  return {
    type: Types.FETCH_USERS_FAILED,
    payload: {
      ref,
    },
  };
};

/** >>>> END FETCH USERS DATATABLE <<<< **/

/** >>>> Start  Upsert USER <<<< **/

export const showUserFormAction = (type, user) => {
  return {
    type: Types.SHOW_USER_FORM,
    payload: {
      type,
      user,
    },
  };
};

export const dismissUserFormAction = () => {
  return {
    type: Types.DISMISS_USER_FORM,
  };
};

export const upsertUserAction = (user, path) => {
  console.log(user);
  return {
    type: Types.UPSERT_USER_ATTEMPT,
    payload: { user, path },
  };
};

export const upsertUserSuccess = (response) => {
  return {
    type: Types.UPSERT_USER_SUCCESS,
    payload: response,
  };
};

export const upsertUserFailed = (error) => {
  return {
    type: Types.UPSERT_USER_FAILED,
    payload: error,
  };
};

/** >>>> END Upsert USER <<<< **/

/** >>>> START FETCH USERS DATATABLE <<<< **/

export const fetchAllDonorsAction = ({ ...filter }) => {
  return {
    type: Types.FETCH_DONORS_ATTEMPT,
    payload: {
      ...filter,
    },
  };
};

export const fetchAllDonorsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_DONORS_SUCCESS,
    payload: { ref, list },
  };
};

export const fetchAllDonorsFilter = (areasFilter, citiesFilter) => {
  return {
    type: Types.FETCH_DONORS_FILTER,
    payload: { areasFilter, citiesFilter },
  };
};

export const fetchAllDonorsFailed = (ref) => {
  return {
    type: Types.FETCH_DONORS_FAILED,
    payload: {
      ref,
    },
  };
};

/** >>>> Start Donors form <<<< **/

export const addDonorAddressAction = () => {
  return {
    type: Types.ADD_DONOR_ADDRESS,
  };
};

export const removeDonorAddressAction = (index) => {
  return {
    type: Types.REMOVE_DONOR_ADDRESS,
    payload: index,
  };
};

export const onAddreesInputChange = (formName, index, name, value) => {
  return {
    type: Types.ADDRESS_INPUT_CHANGE,
    payload: { formName, index, name, value },
  };
};

export const onBalanceInputChange = (formName, index, name, value) => {
  return {
    type: Types.BALANCE_INPUT_CHANGE,
    payload: { formName, index, name, value },
  };
};

// fetchDonorsView
export const fetchDonorsViewAction = () => {
  return {
    type: Types.FETCH_DONORS_VIEW_ATTEMPT,
    payload: {
      ref: "allDonorsList",
    },
  };
};

export const fetchDonorProfileSuccess = (user) => {
  return {
    type: Types.FETCH_DONOR_PROFILE_SUCCESS,
    payload: {
      user,
    },
  };
};

export const showDonorFormAction = (type, user) => {
  return {
    type: Types.SHOW_DONOR_FORM,
    payload: {
      type,
      user,
    },
  };
};

export const dismissDonorFormAction = () => {
  return {
    type: Types.DISMISS_DONOR_FORM,
  };
};

export const upsertDonorAction = ({ user, donorId = null }) => {
  console.log(user);
  return {
    type: Types.UPSERT_DONOR_ATTEMPT,
    payload: { user, donorId },
  };
};

export const upsertDonorSuccess = (response) => {
  return {
    type: Types.UPSERT_DONOR_SUCCESS,
    payload: response,
  };
};

export const upsertDonorFailed = (validation) => {
  return {
    type: Types.UPSERT_DONOR_FAILED,
    payload: { validation },
  };
};

export const fetchDonorAction = (id) => {
  return {
    type: Types.FETCH_DONOR_ATTEMPT,
    payload: { id },
  };
};
export const fetchDonorSuccess = (user) => {
  return {
    type: Types.FETCH_DONOR_SUCCESS,
    payload: {
      user,
    },
  };
};

export const fetchDonorFailed = (error) => {
  return {
    type: Types.FETCH_DONOR_FAILED,
    payload: {
      error,
    },
  };
};

/** >>>> END Donors form <<<< **/

/** >>>> END FETCH USERS DATATABLE <<<< **/

/** >>>> Start FETCH Collector Profile <<<< **/

export const fetchCollectorProfileAction = (id) => {
  return {
    type: Types.FETCH_COLLECTOR_PROFILE_ATTEMPT,
    payload: {
      id,
    },
  };
};

export const fetchCollectorProfileSuccess = (collector) => {
  return {
    type: Types.FETCH_COLLECTOR_PROFILE_SUCCESS,
    payload: {
      collector,
    },
  };
};

export const fetchCollectorProfileFailed = (error) => {
  return {
    type: Types.FETCH_COLLECTOR_PROFILE_FAILED,
    payload: {
      error,
    },
  };
};

export const resetCollectorProfileAction = () => {
  return {
    type: Types.RESET_COLLECTOR_PROFILE,
  };
};

export const fetchCollectionsListAction = ({ ...filter }) => {
  return {
    type: Types.FETCH_COLLECTIONS_LIST_ATTEMPT,
    payload: {
      ...filter,
    },
  };
};

export const fetchCollectionsListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_COLLECTIONS_LIST_SUCCESS,
    payload: { ref, list },
  };
};

export const fetchCollectionsListFailed = (ref) => {
  return {
    type: Types.FETCH_COLLECTIONS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};

/** >>>> END FETCH Collector Profile <<<< **/

/** >>>> Start FETCH Pickups List <<<< **/

export const fetchPickupsViewAction = () => {
  return {
    type: Types.FETCH_PICKUPS_ATTEMPT,
    payload: {
      ref: "allpickupsList",
    },
  };
};

export const fetchPickupsListAction = ({ ...filter }) => {
  return {
    type: Types.FETCH_PICKUPS_LIST_ATTEMPT,
    payload: {
      ...filter,
    },
  };
};

export const fetchPickupsListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_PICKUPS_LIST_SUCCESS,
    payload: { ref, list },
  };
};

export const fetchPickupsListFailed = (ref) => {
  return {
    type: Types.FETCH_PICKUPS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};

export const fetchPickupsListFilter = (donorsFilter, collectorsFilter) => {
  return {
    type: Types.FETCH_PICKUPS_LIST_FILTER,
    payload: {
      donorsFilter,
      collectorsFilter,
    },
  };
};

/** >>>> END FETCH Pickups List <<<< **/

/** >>>> Start FETCH  DONOR Pickups List <<<< **/

export const fetchDonorProfileViewAction = (id) => {
  return {
    type: Types.FETCH_DONOR_VIEW_ATTEMPT,
    payload: {
      ref: "donorPickupsList",
      id,
    },
  };
};

export const fetchDonorPickupsListAction = ({ ...filter }) => {
  return {
    type: Types.FETCH_DONOR_PICKUPS_LIST_ATTEMPT,
    payload: {
      ...filter,
    },
  };
};

export const fetchDonorPickupsListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_DONOR_PICKUPS_LIST_SUCCESS,
    payload: { ref, list },
  };
};

export const fetchDonorPickupsListFailed = (ref) => {
  return {
    type: Types.FETCH_DONOR_PICKUPS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};

/** >>>> END FETCH  DONOR Pickups List <<<< **/

/** >>>> START Settle balance <<<< **/

export const settleCollectorBalanceAction = (balance) => {
  return {
    type: Types.SETTLE_COLLECTOR_BALANCE_ATTEMPT,
    payload: {
      balance,
    },
  };
};

export const settleCollectorBalanceSuccess = (balance) => {
  return {
    type: Types.SETTLE_COLLECTOR_BALANCE_SUCCESS,
    payload: {
      balance,
    },
  };
};
export const settleCollectorBalanceFailed = (validation = null) => {
  return {
    type: Types.SETTLE_COLLECTOR_BALANCE_FAILED,
    payload: { validation },
  };
};

export const showSettleBalanceFormAction = (type = "", balance) => {
  return {
    type: Types.SHOW_SETTLE_BALANCE_FORM,
    payload: {
      type,
      balance,
    },
  };
};

export const dismissSettleBalanceFormAction = () => {
  return {
    type: Types.DISMISS_SETTLE_BALANCE_FORM,
  };
};

/** >>>> END Settle balance <<<< **/

// Start Handle Login Actions

export const loginAction = (user) => {
  return {
    type: Types.LOGING_IN_ATTEMPT,
    payload: {
      user,
    },
  };
};
/** >>>> Start Pickup form <<<< **/

export const fetchPickupDonorAction = (phone) => {
  return {
    type: Types.FETCH_PICKUP_DONOR_ATTEMPT,
    payload: {
      phone,
    },
  };
};

export const loginSuccess = (token, user) => {
  return {
    type: Types.LOGED_IN_SUCCESS,
    payload: { token, user },
  };
};

export const loginFailed = (err = "") => {
  return {
    type: Types.LOGED_IN_FAILED,
    payload: err,
  };
};

// handle LogOut
export const logoutAction = () => {
  return {
    type: Types.LOG_OUT,
  };
};

// RESET PASSWORD
export const forgotPasswordAttempt = (email) => ({
  type: Types.FORGOT_PASSWORD_ATTEMPT,
  payload: email,
});

export const forgotPasswordFinish = () => ({
  type: Types.FORGOT_PASSWORD_FINISH,
});

export const resetPasswordAttempt = (data) => ({
  type: Types.RESET_PASSWORD_ATTEMPT,
  payload: data,
});

export const resetPasswordSuccess = () => ({
  type: Types.RESET_PASSWORD_SUCCESS,
});
export const resetPasswordFailed = () => ({
  type: Types.RESET_PASSWORD_FAILED,
});
export const fetchPickupDonorSuccess = (list) => {
  return {
    type: Types.FETCH_PICKUP_DONOR_SUCCESS,
    payload: {
      list,
    },
  };
};

export const fetchPickupDonorFailed = (error = "") => {
  return {
    type: Types.FETCH_PICKUP_DONOR_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchPickupCollectorsAction = () => {
  return {
    type: Types.FETCH_PICKUP_COLLECTORS_ATTEMPT,
    payload: {
      ref: "pickupCollectorsList",
    },
  };
};

export const fetchPickupCollectorsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_PICKUP_COLLECTORS_SUCCESS,
    payload: {
      list,
      ref,
    },
  };
};

export const fetchPickupCollectorsFailed = (error = "") => {
  return {
    type: Types.FETCH_PICKUP_COLLECTORS_FAILED,
    payload: {
      error,
    },
  };
};

export const removePickupDonorAction = () => {
  return {
    type: Types.REMOVE_PICKUP_DONOR,
  };
};

export const showPickupFormAction = (type = null, pickup = null) => {
  return {
    type: Types.SHOW_PICKUP_FORM,
    payload: {
      type,
      pickup,
    },
  };
};

export const dismissPickupFormAction = () => {
  return {
    type: Types.DISMISS_PICKUP_FORM,
  };
};

export const upsertPickupAction = (pickup, donorId = null) => {
  return {
    type: Types.UPSERT_PICKUP_FORM_ATTEMPT,
    payload: {
      pickup,
      donorId,
    },
  };
};

export const upsertPickupSuccess = (pickup) => {
  return {
    type: Types.UPSERT_PICKUP_FORM_SUCCESS,
    payload: {
      pickup,
    },
  };
};
export const upsertPickupFailed = (validation = null) => {
  return {
    type: Types.UPSERT_PICKUP_FORM_FAILED,
    payload: { validation },
  };
};

export const cancelPickupAction = (cancel, collectorId = null) => {
  return {
    type: Types.CANCEL_PICKUP_ATTEMPT,
    payload: {
      cancel,
      collectorId,
    },
  };
};

export const cancelPickupSuccess = () => {
  return {
    type: Types.CANCEL_PICKUP_SUCCESS,
  };
};
export const cancelPickupFailed = () => {
  return {
    type: Types.CANCEL_PICKUP_FAILED,
  };
};
/** >>>> END Pickup form <<<< **/

/** >>>> START Add balance <<<< **/

export const addCollectorBalanceAction = (balance) => {
  return {
    type: Types.ADD_COLLECTOR_BALANCE_ATTEMPT,
    payload: {
      balance,
    },
  };
};

export const addCollectorBalanceSuccess = (balance) => {
  return {
    type: Types.ADD_COLLECTOR_BALANCE_SUCCESS,
    payload: {
      balance,
    },
  };
};
export const addCollectorBalanceFailed = (validation = null) => {
  return {
    type: Types.ADD_COLLECTOR_BALANCE_FAILED,
    payload: { validation },
  };
};

export const showAddBalanceFormAction = (collector_id) => {
  return {
    type: Types.SHOW_ADD_BALANCE_FORM,
    payload: {
      collector_id,
    },
  };
};

export const showEditAddBalanceFormAction = (collection) => {
  return {
    type: Types.SHOW_EDIT_ADD_BALANCE_FORM,
    payload: {
      collection,
    },
  };
};

export const dismissAddBalanceFormAction = () => {
  return {
    type: Types.DISMISS_ADD_BALANCE_FORM,
  };
};

export const addBalanceInput = () => {
  return {
    type: Types.ADD_BALANCE_FIELD,
  };
};

export const removeBalanceInput = (index) => {
  return {
    type: Types.REMOVE_BALANCE_FIELD,
    payload: index,
  };
};

/** >>>> END Add balance <<<< **/

/** >>>> START EDIT PICKUP COLLECTION <<<< **/

export const editCollectionPickupAction = (collection) => {
  return {
    type: Types.EDIT_PICKUP_COLLECTION_ATTEMPT,
    payload: {
      collection,
    },
  };
};

export const editCollectionPickupSuccess = () => {
  return {
    type: Types.EDIT_PICKUP_COLLECTION_SUCCESS,
  };
};
export const editCollectionPickupFailed = (validation = null) => {
  return {
    type: Types.EDIT_PICKUP_COLLECTION_FAILED,
    payload: { validation },
  };
};

export const showPickupCollectionFormAction = (name, collection) => {
  return {
    type: Types.SHOW_EDIT_PICKUP_COLLECTION,
    payload: {
      name,
      collection,
    },
  };
};

export const dismissPickupCollectionFormAction = () => {
  return {
    type: Types.DISMISS_EDIT_PICKUP_COLLECTION,
  };
};

/** >>>> END EDIT PICKUP COLLECTION <<<< **/

/** >>>> START DELETE BALANCE <<<< **/

export const deleteBalanceAction = (id, collectorId) => {
  return {
    type: Types.DELETE_BALANCE_ATTEMPT,
    payload: {
      id,
      collectorId,
    },
  };
};

export const deleteBalanceSuccess = () => {
  return {
    type: Types.DELETE_BALANCE_SUCCESS,
  };
};

export const deleteBalanceFailed = () => {
  return {
    type: Types.DELETE_BALANCE_FAILED,
  };
};

/** >>>> END DELETE BALANCE <<<< **/

/** >>>> START FETCH COLLECTED PICKUP <<<< **/

export const fetchCollectedPickupAction = (id) => {
  return {
    type: Types.FETCH_COLLECTED_PICKUP_ATTEMPT,
    payload: {
      id,
    },
  };
};

export const fetchCollectedPickupSuccess = () => {
  return {
    type: Types.FETCH_COLLECTED_PICKUP_SUCCESS,
  };
};

export const fetchCollectedPickupFailed = () => {
  return {
    type: Types.FETCH_COLLECTED_PICKUP_FAILED,
  };
};

/** >>>> END FETCH COLLECTED PICKUP <<<< **/

/** >>>> START DELETE TRANSACTION <<<< **/

export const deleteTransactionAction = (id, collectorId) => {
  return {
    type: Types.DELETE_TRANSACTION_ATTEMPT,
    payload: { id, collectorId },
  };
};
export const deleteTransactionSuccess = () => {
  return {
    type: Types.DELETE_TRANSACTION_SUCCESS,
  };
};
export const deleteTransactionFailed = () => {
  return {
    type: Types.DELETE_TRANSACTION_FAILED,
  };
};

/** >>>> END DELETE TRANSACTION <<<< **/
