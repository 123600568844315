import React, { useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { resetPasswordAttempt } from "../../Store/Actions";
import { useParams, useLocation } from "react-router-dom";

const ResetPasswordPage = (props) => {
  // Data from sent email
  const { token } = useParams();
  const email = new URLSearchParams(useLocation().search).get("email");

  // to handle show/hide password btn
  const [showPasswordState, setShowPasswordState] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  // password inputs value
  const [passwordValue, setPasswordValue] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  // password confirm validation message
  const [validationMsg, setValidationMsg] = useState("");

  const handleToggleShowPassword = (type) => {
    setShowPasswordState((state) => ({
      ...state,
      [type]: !state[type],
    }));
  };

  const handlePasswordInput = (e, type) => {
    setValidationMsg("");
    setPasswordValue((state) => ({
      ...state,
      [type]: e.target.value,
    }));
  };

  const handleSubmitBtn = (e) => {
    e.preventDefault();

    // Handle Empty password
    if (!passwordValue.newPassword || !passwordValue.confirmPassword) {
      return setValidationMsg("Please Enter your password");
    }
    // Handle Not matching password
    if (passwordValue.newPassword !== passwordValue.confirmPassword) {
      return setValidationMsg("Please make sure your passwords match");
    }

    props.resetPasswordAttempt({
      email,
      token,
      password: passwordValue.newPassword,
      password_confirmation: passwordValue.confirmPassword,
    });
  };

  return (
    <LoginLayout>
      <form className="w-100">
        <div className="col-12 mb-3">
          <div className="input-container-style-default">
            <input
              placeholder="Your E-mail"
              type="text"
              className="input-style-default"
              value={email}
              disabled
            ></input>
          </div>
        </div>

        <div className="col-12 mb-3">
          <div className="input-container-style-default">
            <input
              placeholder="New Password"
              type="password"
              className="input-style-default"
              value={passwordValue.newPassword}
              onChange={(e) => handlePasswordInput(e, "newPassword")}
            ></input>
          </div>
        </div>

        <div className="col-12 mb-3">
          <div className="input-container-style-default">
            <input
              placeholder="Confirm New Password"
              type="password"
              className="input-style-default"
              value={passwordValue.confirmPassword}
              onChange={(e) => handlePasswordInput(e, "confirmPassword")}
            ></input>
          </div>
        </div>

        <div className="col-12">
          <button
            className="btn btn-block login_btn_style mt-0"
            type="submit"
            disabled={props.resetPasswordLoading}
            onClick={handleSubmitBtn}
          >
            {props.resetPasswordLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordLoading: state.user.resetPasswordLoading,
  };
};

export default connect(mapStateToProps, { resetPasswordAttempt })(
  ResetPasswordPage
);
