import React, { useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { forgotPasswordAttempt } from "../../Store/Actions";

const ForgotPasswordPage = (props) => {
  const [userMail, setUserMail] = useState("");
  const [mailValidationMsg, setMailValidationMsg] = useState("");
  const handleEmailInputChange = (e) => {
    setMailValidationMsg("");
    setUserMail(e.target.value);
  };

  const handleSubmitBtn = (e) => {
    e.preventDefault();
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        userMail
      )
    ) {
      props.forgotPasswordAttempt(userMail);
    } else {
      return setMailValidationMsg("You have entered an invalid email address!");
    }
    // props.loginAction(userMail);
  };
  return (
    <LoginLayout>
      <form className="w-100">
        <div className="col-12 mb-3">
          <div className="input-container-style-default">
            <input
              placeholder="Your Email"
              type="text"
              className="input-style-default"
              onChange={handleEmailInputChange}
              name="username"
            ></input>
          </div>
        </div>

        <div className="col-12">
          <button
            className="btn btn-block login_btn_style mt-0"
            type="submit"
            onClick={handleSubmitBtn}
            disabled={props.forgotPasswordLoading}
          >
            {props.forgotPasswordLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    forgotPasswordLoading: state.user.forgotPasswordLoading,
  };
};

export default connect(mapStateToProps, { forgotPasswordAttempt })(
  ForgotPasswordPage
);
