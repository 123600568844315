import React, { useEffect } from "react";
import {
  faTimes,
  faSearch,
  faPencilAlt,
  faPowerOff,
  faPlay,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Store from "../../Store";

import UsersSearchCard from "../../Components/UsersSearchCard";
import Pagination from "../../Components/Pagination";
import {
  fetchAllUsersAction,
  setDataTableSortingAction,
  onInputResetAction,
  showUserFormAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Loader from "../../Components/Loader";
import HasPrivileges, { useCheckPrivileges } from "../../Helpers/HelperFns/Roles/HasPrivileges";
import { Privileges } from "../../Helpers/HelperFns/Roles/Privileges";




const UsersList = (props) => {
  const dataTableRef = "allUsersList";
  const columns = [
    {
      name: "Name",
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      cell: (row) => <HasPrivileges can={[Privileges.CAN_VIEW_COLLECTOR_PROFILE]} altChildren={<span>{row?.name}</span>}>
        {row?.role?.id === "3" ?
          <Link to={`collector-profile/${row?.id}`}>{row?.name}</Link>
          : <span>{row?.name}</span>
        }
      </HasPrivileges>,
    },

    {
      name: "Email",
      wrap: true,
      selector: "email",
      sortable: false,
      grow: 1,
    },
    {
      name: "phone number",
      wrap: true,
      selector: "phone",
      sortable: false,
      grow: 1,
    },
    {
      name: "role",
      wrap: true,
      selector: "role",
      sortable: false,
      grow: 1,
      cell: (row) => `${row.role?.name}`,
    },
    {
      name: "status",
      wrap: true,
      selector: "status",
      sortable: false,
      grow: 1,
      cell: (row) => `${row.status?.name}`,
    },
    {
      name: "Actions",
      wrap: true,
      selector: "action",
      omit: !useCheckPrivileges({ can: [Privileges.CAN_ADD_ADMIN, Privileges.CAN_ADD_USER] }),
      // right: true,
      grow: 0.5,
      cell: ({ role, status, ...row }) => (
        <HasPrivileges can={[Privileges.CAN_ADD_ADMIN, Privileges.CAN_ADD_USER]}>

          <div className="general-data-table-action">
            <span
              className="btn"
              onClick={() => props.showUserFormAction("Edit", { ...row, role_id: role.id })}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
            {/* {status.name == "Pending" ? (
            <span className="btn" onClick={() => console.log(row)}>
            <FontAwesomeIcon icon={faPlay} />
            </span>
            ) : (
              <span className="btn" onClick={() => console.log(row)}>
              <FontAwesomeIcon icon={faPowerOff} />
              </span>
            )} */}
          </div>
        </HasPrivileges>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    props.fetchAllUsersAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onInputResetAction(dataTableRef, "ids");
      props.onInputResetAction(dataTableRef, "phone");
      props.onInputResetAction(dataTableRef, "email");
      props.onInputResetAction(dataTableRef, "status_id");
      props.onInputResetAction(dataTableRef, "role_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchAllUsersAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };
  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].ids,
    props.data[dataTableRef].status_id,
    props.data[dataTableRef].email,
    props.data[dataTableRef].phone,
    props.data[dataTableRef].role_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchAllUsersAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, dir) => {
    props.fetchAllUsersAction({
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };
  return (
    <div>
      <UsersSearchCard formName={dataTableRef} />
      <div className="mt-3">
        <DataTable
          className="general-data-list"
          columns={columns}
          data={props.data[dataTableRef]?.data}
          noHeader
          persistTableHead
          sortServer
          onSort={handleSorting}
          defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
          defaultSortField={props.data[dataTableRef].sorting.key}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data[dataTableRef].isLoading}
          progressComponent={<Loader />}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    auth: state.auth.userProfile
  };
};

export default connect(mapStateToProps, {
  fetchAllUsersAction,
  setDataTableSortingAction,
  onInputResetAction,
  showUserFormAction
})(UsersList);
