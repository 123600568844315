import Types from "../Actions/types";

export const initState = {
  token: null,
  userProfile: {},
  authForm: {
    username: "",
    password: "",
  },
  isLoading: false,
  loginErr: "",
};

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.LOGING_IN_ATTEMPT:
      return { ...state, isLoading: true, loginErr: null };

    case Types.LOGED_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token ?? null,
        userProfile: action.payload.user,
        authForm: initState.authForm,
      };

    case Types.LOGED_IN_FAILED:
      return { ...state, isLoading: false };

    // fetching Profile
    case Types.LOG_OUT:
      if (!state.authForm.remember_me) {
        return { ...initState, token: null };
      }
      return {
        ...initState,
        token: null,
        authForm: {
          ...state.authForm,
          password: "",
        },
      };
    default:
      return state;
  }
};
