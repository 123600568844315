import { combineReducers } from "redux";

import { initState as authInitState, authReducer } from "./AuthReducer";
import { initState as userInitState, userReducer } from "./UserReducer";
import { initState as superInitState, superReducer } from "./SuperReducer";

import withBuilder from "../../Helpers/HOC/withBuilder";

export default combineReducers({
  auth: withBuilder(authInitState, authReducer),
  user: withBuilder(userInitState, userReducer),
  super: withBuilder(superInitState, superReducer),
});
