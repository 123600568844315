import React, { useState, useEffect } from "react";
import {
  faPencilAlt,
  faPowerOff,
  faClipboard,
  faExclamationCircle,
  faPlus,
  faTimes,
  faFile
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Store from "../../Store";

import PickupsSearchCard from "../../Components/PickupsSearchCard";
import Pagination from "../../Components/Pagination";
import {
  fetchPickupsListAction,
  setDataTableSortingAction,
  onInputResetAction,
  showPickupFormAction,
  cancelPickupAction,
  fetchCollectedPickupAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import PickupModal from "../../Components/PickupModal";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { default as sweet } from "sweetalert";
import swal from "sweetalert2";
import Loader from "../../Components/Loader";
import HasPrivileges, { useCheckPrivileges } from "../../Helpers/HelperFns/Roles/HasPrivileges";
import { Privileges } from "../../Helpers/HelperFns/Roles/Privileges";

const handleCancelPickup = (id) => {
  swal
    .fire({
      title: "Are you sure?",
      text: "You won't be able to revert this !",
      icon: "warning",
      input: "textarea",
      inputPlaceholder: "Cancelation note",
      showCancelButton: true,
      dangerMode: true,
      buttons: true,
      reverseButtons: true,
      confirmButtonText: "Yes, cancel it",
      cancelButtonText: "No, keep it",
      preConfirm: function (value) {
        if (value.length) {
          return value;
        }
        sweet({
          title: "cancelation note is required",
          text: " ",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
        return false;
      },
    })
    .then((res) => {
      if (res.isConfirmed) {
        console.log(res);
        Store.dispatch(
          cancelPickupAction({ id, cancellation_note: res.value })
        );
      }
    });
};

const PickupsList = (props) => {
  const columns = [
    {
      name: "collection ID",
      wrap: true,
      selector: "name",
      sortable: true,
      grow: 0.75,
      cell: (row) =>
        <HasPrivileges can={[Privileges.CAN_VIEW_PICKUP_AUDITS]} altChildren={<span> {row.name}</span>}>
          <span role="button" onClick={() => showPickupModal(row)}>
            {row.name}
          </span>
        </HasPrivileges>

    },

    {
      name: "Donor",
      wrap: true,
      selector: "donor",
      sortable: false,
      grow: 1,
      cell: (row) =>
        <HasPrivileges can={[Privileges.CAN_VIEW_DONOR_PROFILE]} altChildren={<span>{row?.donor?.name}</span>}>
          <Link to={`donor-profile/${row?.donor?.id}`}>{row?.donor?.name}</Link>
        </HasPrivileges>

    },
    {
      name: "Collector",
      wrap: true,
      selector: "collector",
      sortable: false,
      grow: 1,
      cell: (row) =>
        <HasPrivileges can={[Privileges.CAN_VIEW_COLLECTOR_PROFILE]} altChildren={<span>{row?.collector?.name ?? ""}</span>}>
          {row?.collector ?
            <Link to={`collector-profile/${row?.collector?.id}`}>
              {row?.collector?.name}
            </Link> : <span>{row?.collector?.name ?? ""}</span>}
        </HasPrivileges>

      // <span className="btn" onClick={() => console.log("row")}>
      //   <FontAwesomeIcon color="green" icon={faPlus} />
      // </span>
    },
    {
      name: "area",
      wrap: true,
      sortable: false,
      grow: 0.75,
      cell: (row) => `${row.address?.area?.name ?? ""}`,
    },
    {
      name: "city",
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => `${row.address?.area?.city?.name ?? ""}`,
    },
    {
      name: "contact date",
      wrap: true,
      selector: "contact_date",
      sortable: true,
      grow: 0.5,
      conditionalCellStyles: [
        {
          when: (row) =>
            row?.status?.name == "Pending" && Boolean(row?.contact_date),
          style: (row) => ({
            backgroundColor:
              new Date(row.contact_date) < moment().startOf("day").toDate()
                ? "#fee9e9"
                : "transparent",
            color:
              new Date(row.contact_date) < moment().startOf("day").toDate()
                ? "#242424 !important"
                : "#576271",
          }),
        },
      ],
      cell: (row) =>
        Boolean(row?.contact_date)
          ? moment(row?.contact_date).format("DD-MM-yyyy")
          : "",
    },
    {
      name: "collected date",
      wrap: true,
      selector: "collection_date",
      sortable: true,
      grow: 1,
      conditionalCellStyles: [
        {
          when: (row) => row?.status?.name == "Scheduled",
          style: (row) => ({
            backgroundColor:
              new Date(row.collection_date) < moment().startOf("day").toDate()
                ? "#fee9e9"
                : "transparent",
            color:
              new Date(row.collection_date) < moment().startOf("day").toDate()
                ? "#242424 !important"
                : "#576271",
          }),
        },
      ],
      cell: (row) =>
        Boolean(row?.collection_date)
          ? moment(row?.collection_date).format("DD-MM-yyyy hh:mm a")
          : "",
    },
    {
      name: "status",
      wrap: true,
      selector: "status",
      sortable: false,
      grow: 0.75,
      cell: (row) => renderStatusCell(row),
      // renderStatusCell(status, schedule_note, cancellation_note),
    },
    {
      name: "note",
      wrap: true,
      selector: "main_note",
      sortable: false,
      grow: 0.5,
      cell: (row) =>
        <>
          {Boolean(row?.main_note) ? (
            <div className="">
              <Tooltip
                classes={{ popper: "custom_tooltip_wrapper_style" }}
                title={`main note : ${row?.main_note}`}
                placement="left"
                arrow
              >
                <span className="btn px-2">
                  <FontAwesomeIcon color="#a7a7a7" icon={faClipboard} />
                </span>
              </Tooltip>
            </div>
          ) : null}
          {
            Boolean(row?.material_note) ? (
              <div className="">
                <Tooltip
                  classes={{ popper: "custom_tooltip_wrapper_style" }}
                  title={`material note : ${row?.material_note}`}
                  placement="left"
                  arrow
                >
                  <span className="btn px-2">
                    <FontAwesomeIcon color="#a7a7a7" icon={faFile} />
                  </span>
                </Tooltip>
              </div>
            ) : null
          }
        </>
    },
    {
      name: "Actions",
      wrap: true,
      selector: "action",
      // right: true,
      omit: !useCheckPrivileges({ can: [Privileges.CAN_DELETE_PICKUP, Privileges.CAN_ADD_PICKUP] }),
      grow: 1,
      cell: ({ status, ...row }) => {
        let pickupData = {
          id: row.id,
          donor_id: row?.donor.id,
          collector_id: row.collector ? row.collector?.id : "",
          address_id: row?.address?.id,
          contact_date: row?.contact_date,
          main_note: row?.main_note,
          donor: row?.donor,
          collection_date: row?.collection_date,
          pickup_type_id: row?.pickupType?.id,
          has_cash: row?.has_cash,
          has_material: row?.has_material,
        };
        if (status.name == "Cancelled") return;

        if (status.name == "Collected") {
          return (
            <HasPrivileges can={[Privileges.CAN_DELETE_PICKUP, Privileges.CAN_ADD_PICKUP]}>
              <div className="general-data-table-action">
                <HasPrivileges can={[Privileges.CAN_ADD_PICKUP]}>
                  <span
                    className="btn invisible">
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </span>
                </HasPrivileges>

                <HasPrivileges can={[Privileges.CAN_DELETE_PICKUP]}>
                  <span
                    className="btn"
                    onClick={() => handleCancelPickup(row.id)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </HasPrivileges>
              </div>
            </HasPrivileges>
          );
        }

        return (
          <div className="general-data-table-action">
            <HasPrivileges can={[Privileges.CAN_ADD_PICKUP]}>
              <span
                className="btn"
                onClick={() =>
                  Store.dispatch(showPickupFormAction("edit", pickupData))
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </span>
            </HasPrivileges>
            <HasPrivileges can={[Privileges.CAN_DELETE_PICKUP]}>
              <span className="btn" onClick={() => handleCancelPickup(row.id)}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </HasPrivileges>
          </div>
        );
      },

      ignoreRowClick: true,
    },
  ];

  const renderStatusCell = ({
    status,
    schedule_note,
    cancellation_note,
    id,
  }) => {
    switch (status.name) {
      case "Cancelled":
        return (
          <div className={`${status.name}_status_style`}>
            <span>{status.name}</span>
            {Boolean(cancellation_note) ? (
              <Tooltip
                classes={{ popper: "custom_tooltip_wrapper_style" }}
                title={cancellation_note}
                placement="left"
                arrow
              >
                <span className="btn">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </span>
              </Tooltip>
            ) : null}
          </div>
        );

        break;
      case "Scheduled":
        return (
          <div className={`${status.name}_status_style`}>
            <span>{status.name}</span>
            {Boolean(schedule_note) ? (
              <Tooltip
                classes={{ popper: "custom_tooltip_wrapper_style" }}
                title={schedule_note}
                placement="left"
                arrow
              >
                <span className="btn">
                  <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
                </span>
              </Tooltip>
            ) : null}
          </div>
        );

        break;

      case "Collected":
        return (
          <HasPrivileges can={[Privileges.CAN_EDIT_TRANSATION]} altChildren={
            <div className={`${status.name}_status_style`}>
              <span>{status.name}</span>
            </div>
          }>
            <button
              className={`${status.name}_status_style border-0`}
              role="button"
              onClick={() => props.fetchCollectedPickupAction(id)}
            >
              <span>{status.name}</span>
            </button>
          </HasPrivileges>
        )

      default:
        return (
          <div className={`${status.name}_status_style`}>
            <span>{status.name}</span>
          </div>
        );
    }
  };
  // Toggle function for pickup Modal
  const [pickupModal, setPickupModal] = useState({
    isOpen: false,
    pickupLogs: [],
    name: "",
  });
  const dismissPickupModal = () => {
    if (pickupModal.isOpen) {
      setPickupModal({
        isOpen: false,
        pickupLogs: [],
        name: "",
      });
    }
  };

  const showPickupModal = (pickup) => {
    setPickupModal({
      isOpen: true,
      pickupLogs: pickup.logs,
      name: pickup.name,
    });
  };

  const dataTableRef = "allpickupsList";
  useEffect(() => {
    // props.fetchPickupsListAction({
    //   ref: dataTableRef,
    //   [dataTableRef]: props.data[dataTableRef],
    // });
    return () => {
      props.onInputResetAction(dataTableRef, "ids");
      props.onInputResetAction(dataTableRef, "phone");
      props.onInputResetAction(dataTableRef, "email");
      props.onInputResetAction(dataTableRef, "status_id");
      props.onInputResetAction(dataTableRef, "role_id");
      props.onInputResetAction(dataTableRef, "time_range");
      props.onInputResetAction(dataTableRef, "collection_date_from");
      props.onInputResetAction(dataTableRef, "collection_date_to");
    };
  }, []);

  const handleFilter = () => {
    props.fetchPickupsListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };
  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].name,
    props.data[dataTableRef].status_id,
    props.data[dataTableRef].donor_phone,
    props.data[dataTableRef].donor_id,
    props.data[dataTableRef].collector_id,
    props.data[dataTableRef].city_id,
    props.data[dataTableRef].area_id,
    props.data[dataTableRef].time_range,
    props.data[dataTableRef].collection_date_from,
    props.data[dataTableRef].collection_date_to,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchPickupsListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, order) => {
    props.fetchPickupsListAction({
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
        sorting: {
          order,
          column: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, order);
  };

  return (
    <div>
      {/* <button className="btn btn-primary" onClick={togglePickupModal}>
        Pickup Modal
      </button> */}
      <PickupModal
        ModalIsOpen={pickupModal.isOpen}
        toggleModal={dismissPickupModal}
        pickup_name={pickupModal.name}
        pickup_timeline_data={pickupModal.pickupLogs}
      />
      <PickupsSearchCard formName={dataTableRef} authUser={props.user} />
      <div className="mt-3">
        <DataTable
          className="general-data-list pickup_data_list_wrapper_style"
          columns={columns}
          data={props.data[dataTableRef].data}
          noHeader
          persistTableHead
          sortServer
          onSort={handleSorting}
          // defaultSortAsc={props.data[dataTableRef].sorting.order == "asc"}
          // defaultSortField={props.data[dataTableRef].sorting.column}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={
            props.data[dataTableRef].isLoading ||
            props.fetchCollectedPickupLoading
          }
          progressComponent={<Loader />}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    user: state.auth.userProfile,
    fetchCollectedPickupLoading: state.super.fetchCollectedPickupLoading,
  };
};

export default connect(mapStateToProps, {
  fetchPickupsListAction,
  setDataTableSortingAction,
  onInputResetAction,
  fetchCollectedPickupAction,
})(PickupsList);
