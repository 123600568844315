import React, { useState } from "react";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import "../../Builder/Form/InputFormStyle.scss";

import { showDonorFormAction } from "../../Store/Actions";

import { InputForm, BSelect } from "../../Builder/Form";
import Const from "../../Constants";

const DonorsSearchCard = (props) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="custom_nav_tabs_wrapper_style card_wrapper_style pt-2">
      <div className="d-flex justify-content-end">
        <div>
          <div className="d-flex">
            <div>
              <label
                className="btn search_btn_style mr-2"
                type="button"
                onClick={handleToggleFilter}
              >
                <SearchOutlinedIcon />
              </label>
            </div>

            <div>
              <button
                className="btn add_new_btn_style"
                type="button"
                onClick={() => props.showDonorFormAction("New", null)}
              >
                <PersonAddOutlinedIcon />
                <p>Add new donor</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Collapse isOpen={showFilter}>
        <div className="mt-2 pl-2 pl-md-0">
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <InputForm
                name="phone"
                placeholder="Donor Phone"
                containerStyle=" "
                inputStyle="input-style-default"
                formName={props.formName}
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <InputForm
                name="name"
                placeholder="Name"
                containerStyle=" "
                inputStyle="input-style-default"
                formName={props.formName}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <BSelect
                name="city_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti
                optionLabel="name"
                optionValue="id"
                options={Const.Cities}
                placeholder="City"
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <BSelect
                name="area_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti
                optionLabel="name"
                optionValue="id"
                options={Const.Areas}
                placeholder="Area"
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super.DonorsFiler,
  };
};

export default connect(mapStateToProps, {
  showDonorFormAction,
})(DonorsSearchCard);
