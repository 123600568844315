import React from "react";
import MerqahNavbar from "./MerqahNavbar";

const Layout = ({ children, ...props }) => {
  return (
    <>
      <MerqahNavbar />
      <div className="content">{children}</div>
    </>
  );
};

export default Layout;
