import React, { useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { Link } from "react-router-dom";
import { loginAction } from "../../Store/Actions";
import { InputForm } from "../../Builder/Form";

const LoginPage = (props) => {
  const handleLogIn = (e) => {
    e.preventDefault();
    props.loginAction(props.authInfo);
  };
  return (
    <LoginLayout>
      <form method="POST" onSubmit={handleLogIn} className="w-100">
        <div className="col-12">
          <div className="input-container-style-default">
            <InputForm
              formName="authForm"
              name="username"
              reducer="auth"
              placeholder="Email"
              type="text"
              className="input-style-default"
            />
          </div>
        </div>

        <div className="col-12 my-3">
          <div className="input-container-style-default">
            <InputForm
              formName="authForm"
              name="password"
              reducer="auth"
              placeholder="Password"
              type="password"
              className="input-style-default"
            />
          </div>
        </div>

        <div className="col-12">
          <button
            className="btn btn-block login_btn_style mt-0"
            type="submit"
            disabled={props.loggingInLoading}
            onClick={handleLogIn}
          >
            {props.loggingInLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              "Login"
            )}
          </button>
        </div>

        <div className="col-12 text-center mt-3">
          <Link
            exact={true}
            to="/forgot-password"
            className="forgot_pass_style"
          >
            Forgot Password?
          </Link>
        </div>
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    authInfo: state.auth.authForm,
    loggingInLoading: state.user.loggingInLoading,
  };
};

export default connect(mapStateToProps, { loginAction })(LoginPage);
