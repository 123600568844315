import React, { useState } from "react";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import "../../Builder/Form/InputFormStyle.scss";
import Const from "../../Constants";

import { onInputChangeAction } from "../../Store/Actions";

import { InputForm, BSelect } from "../../Builder/Form";

const CollectionSearchCard = (props) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="custom_nav_tabs_wrapper_style card_wrapper_style pb-1">
      <div className="d-flex justify-content-between">
        <div className="mt-1 collection_history_wrapper_style">
          <h3 className="mb-0">collection history</h3>
        </div>

        <div className="mb-md-1">
          <label
            className="btn search_btn_style"
            type="button"
            onClick={handleToggleFilter}
          >
            <SearchOutlinedIcon />
          </label>
        </div>
      </div>

      <Collapse isOpen={showFilter}>
        <div className="mt-3 pl-2 pl-md-0">
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <InputForm
                name="phone"
                placeholder="Dnor Phone"
                containerStyle=" "
                inputStyle="input-style-default"
                formName={props.formName}
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <InputForm
                name="name"
                placeholder="name"
                containerStyle=" "
                inputStyle="input-style-default"
                formName={props.formName}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <BSelect
                placeholder="city"
                name="city_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti
                optionLabel="name"
                optionValue="id"
                options={Const.Cities}
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <BSelect
                placeholder="area"
                name="area_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti
                optionLabel="name"
                optionValue="id"
                options={Const.Areas}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps)(CollectionSearchCard);
