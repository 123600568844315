/* >>>> Start Builder Actions types <<<< */
// handle on Form Input change
const ON_INPUT_CHANGE = "ON_INPUT_CHANGE";
const ON_SELECT_INPUT_CHANGE = "ON_SELECT_INPUT_CHANGE";
const ON_INPUT_INCREMENT = "ON_INPUT_INCREMENT";
const ON_INPUT_DECREMENT = "ON_INPUT_DECREMENT";
const ON_RADIO_INPUT_CHANGE = "ON_RADIO_INPUT_CHANGE";
const ON_CHECKBOX_INPUT_CHANGE = "ON_CHECKBOX_INPUT_CHANGE";
const ON_CHECKBOX_BOOLEAN_INPUT_CHANGE = "ON_CHECKBOX_BOOLEAN_INPUT_CHANGE";
const ON_APPEND_INPUT_CHANGE = "ON_APPEND_INPUT_CHANGE";

// Reset Form Input
const ON_INPUT_RESET = "ON_INPUT_RESET";

// handle input Form Validation
const SET_INPUT_VALID = "SET_INPUT_VALID";
const SET_INPUT_INVALID = "SET_INPUT_INVALID";

//handle dataTable #sorting #paginate #filter

const SET_DATATABLE_SORTING = "SET_DATATABLE_SORTING";
const SET_DATATABLE_FILTER = "SET_DATATABLE_FILTER";
const SET_DATATABLE_PAGINATE = "SET_DATATABLE_PAGINATE";

/* >>>> End Builder Actions types <<<< */

/* >>>> Start fetch users datatable <<<< */

const FETCH_USERS_ATTEMPT = "FETCH_USERS_ATTEMPT";
const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

const FETCH_USERS_FILTER = "FETCH_USERS_FILTER";

/* >>>> End fetch users datatable <<<< */

/* >>>> Start Upsert users  <<<< */

const UPSERT_USER_ATTEMPT = "UPSERT_USER_ATTEMPT";
const UPSERT_USER_SUCCESS = "UPSERT_USER_SUCCESS";
const UPSERT_USER_FAILED = "UPSERT_USER_FAILED";

const SHOW_USER_FORM = "SHOW_USER_FORM";
const DISMISS_USER_FORM = "DISMISS_USER_FORM";

/* >>>> End Upsert users  <<<< */
/* >>>> Start Upsert Donor  <<<< */

const UPSERT_DONOR_ATTEMPT = "UPSERT_DONOR_ATTEMPT";
const UPSERT_DONOR_SUCCESS = "UPSERT_DONOR_SUCCESS";
const UPSERT_DONOR_FAILED = "UPSERT_DONOR_FAILED";

const SHOW_DONOR_FORM = "SHOW_DONOR_FORM";
const DISMISS_DONOR_FORM = "DISMISS_DONOR_FORM";

/* >>>> End Upsert Donor  <<<< */

/* >>>> Start fetch Donors datatable <<<< */

const FETCH_DONORS_VIEW_ATTEMPT = "FETCH_DONORS_VIEW_ATTEMPT";

const FETCH_DONORS_ATTEMPT = "FETCH_DONORS_ATTEMPT";
const FETCH_DONORS_SUCCESS = "FETCH_DONORS_SUCCESS";
const FETCH_DONORS_FAILED = "FETCH_DONORS_FAILED";

const FETCH_DONORS_FILTER = "FETCH_DONORS_FILTER";

const ADD_DONOR_ADDRESS = "ADD_DONOR_ADDRESS";
const REMOVE_DONOR_ADDRESS = "REMOVE_DONOR_ADDRESS";

const ADDRESS_INPUT_CHANGE = "ADDRESS_INPUT_CHANGE";

const FETCH_DONOR_ATTEMPT = "FETCH_DONOR_ATTEMPT";
const FETCH_DONOR_SUCCESS = "FETCH_DONOR_SUCCESS";
const FETCH_DONOR_FAILED = "FETCH_DONOR_FAILED";

/* >>>> End fetch Donors datatable <<<< */

/* >>>> Start fetch Collector View <<<< */

const FETCH_COLLECTOR_PROFILE_ATTEMPT = "FETCH_COLLECTOR_PROFILE_ATTEMPT";
const FETCH_COLLECTOR_PROFILE_SUCCESS = "FETCH_COLLECTOR_PROFILE_SUCCESS";
const FETCH_COLLECTOR_PROFILE_FAILED = "FETCH_COLLECTOR_PROFILE_FAILED";

const RESET_COLLECTOR_PROFILE = "RESET_COLLECTOR_PROFILE";

const FETCH_COLLECTIONS_LIST_ATTEMPT = "FETCH_COLLECTIONS_LIST_ATTEMPT";
const FETCH_COLLECTIONS_LIST_SUCCESS = "FETCH_COLLECTIONS_LIST_SUCCESS";
const FETCH_COLLECTIONS_LIST_FAILED = "FETCH_COLLECTIONS_LIST_FAILED";

/* >>>> End fetch Collector View <<<< */

/* >>>> Start fetch PICKUPS datatable <<<< */

const FETCH_PICKUPS_ATTEMPT = "FETCH_PICKUPS_ATTEMPT";

const FETCH_PICKUPS_LIST_ATTEMPT = "FETCH_PICKUPS_LIST_ATTEMPT";
const FETCH_PICKUPS_LIST_SUCCESS = "FETCH_PICKUPS_LIST_SUCCESS";
const FETCH_PICKUPS_LIST_FAILED = "FETCH_PICKUPS_LIST_FAILED";

const FETCH_PICKUPS_LIST_FILTER = "FETCH_PICKUPS_LIST_FILTER";

/* >>>> End fetch PICKUPS datatable <<<< */

/* >>>> Start fetch PICKUPS datatable <<<< */

const FETCH_DONOR_VIEW_ATTEMPT = "FETCH_DONOR_VIEW_ATTEMPT";

const FETCH_DONOR_PROFILE_SUCCESS = "FETCH_DONOR_PROFILE_SUCCESS";

const FETCH_DONOR_PICKUPS_LIST_ATTEMPT = "FETCH_DONOR_PICKUPS_LIST_ATTEMPT";
const FETCH_DONOR_PICKUPS_LIST_SUCCESS = "FETCH_DONOR_PICKUPS_LIST_SUCCESS";
const FETCH_DONOR_PICKUPS_LIST_FAILED = "FETCH_DONOR_PICKUPS_LIST_FAILED";

/* >>>> End fetch PICKUPS datatable <<<< */

/* >>>> Start Settle balance <<<< */

const SETTLE_COLLECTOR_BALANCE_ATTEMPT = "SETTLE_COLLECTOR_BALANCE_ATTEMPT";
const SETTLE_COLLECTOR_BALANCE_SUCCESS = "SETTLE_COLLECTOR_BALANCE_SUCCESS";
const SETTLE_COLLECTOR_BALANCE_FAILED = "SETTLE_COLLECTOR_BALANCE_FAILED";

const BALANCE_INPUT_CHANGE = "BALANCE_INPUT_CHANGE";

const SHOW_SETTLE_BALANCE_FORM = "SHOW_SETTLE_BALANCE_FORM";
const DISMISS_SETTLE_BALANCE_FORM = "DISMISS_SETTLE_BALANCE_FORM";

/* >>>> End Settle balance <<<< */

// Login Action Types
const LOGING_IN_ATTEMPT = "LOGING_IN_ATTEMPT";
const LOGED_IN_SUCCESS = "LOGED_IN_SUCCESS";
const LOGED_IN_FAILED = "LOGED_IN_FAILED";

const FORGOT_PASSWORD_ATTEMPT = "FORGOT_PASSWORD_ATTEMPT";
const FORGOT_PASSWORD_FINISH = "FORGOT_PASSWORD_FINISH";

const RESET_PASSWORD_ATTEMPT = "RESET_PASSWORD_ATTEMPT";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

const LOG_OUT = "LOG_OUT";
/* >>>> Start Pickup form <<<< */

const FETCH_PICKUP_DONOR_ATTEMPT = "FETCH_PICKUP_DONOR_ATTEMPT";
const FETCH_PICKUP_DONOR_SUCCESS = "FETCH_PICKUP_DONOR_SUCCESS";
const FETCH_PICKUP_DONOR_FAILED = "FETCH_PICKUP_DONOR_FAILED";

const FETCH_PICKUP_COLLECTORS_ATTEMPT = "FETCH_PICKUP_COLLECTORS_ATTEMPT";
const FETCH_PICKUP_COLLECTORS_SUCCESS = "FETCH_PICKUP_COLLECTORS_SUCCESS";
const FETCH_PICKUP_COLLECTORS_FAILED = "FETCH_PICKUP_COLLECTORS_FAILED";

const UPSERT_PICKUP_FORM_ATTEMPT = "UPSERT_PICKUP_FORM_ATTEMPT";
const UPSERT_PICKUP_FORM_SUCCESS = "UPSERT_PICKUP_FORM_SUCCESS";
const UPSERT_PICKUP_FORM_FAILED = "UPSERT_PICKUP_FORM_FAILED";

const REMOVE_PICKUP_DONOR = "REMOVE_PICKUP_DONOR";

const SHOW_PICKUP_FORM = "SHOW_PICKUP_FORM";
const DISMISS_PICKUP_FORM = "DISMISS_PICKUP_FORM";

const CANCEL_PICKUP_ATTEMPT = "CANCEL_PICKUP_ATTEMPT";
const CANCEL_PICKUP_SUCCESS = "CANCEL_PICKUP_SUCCESS";
const CANCEL_PICKUP_FAILED = "CANCEL_PICKUP_FAILED";

/* >>>> End Pickup form <<<< */

/* >>>> Start ADD balance <<<< */

const ADD_COLLECTOR_BALANCE_ATTEMPT = "ADD_COLLECTOR_BALANCE_ATTEMPT";
const ADD_COLLECTOR_BALANCE_SUCCESS = "ADD_COLLECTOR_BALANCE_SUCCESS";
const ADD_COLLECTOR_BALANCE_FAILED = "ADD_COLLECTOR_BALANCE_FAILED";

const ADD_BALANCE_FIELD = "ADD_BALANCE_FIELD";
const REMOVE_BALANCE_FIELD = "REMOVE_BALANCE_FIELD";

const SHOW_ADD_BALANCE_FORM = "SHOW_ADD_BALANCE_FORM";
const SHOW_EDIT_ADD_BALANCE_FORM = "SHOW_EDIT_ADD_BALANCE_FORM";
const DISMISS_ADD_BALANCE_FORM = "DISMISS_ADD_BALANCE_FORM";

/* >>>> End ADD balance <<<< */

/* >>>> Start EDIT PICKUP COLLECTION <<<< */

const EDIT_PICKUP_COLLECTION_ATTEMPT = "EDIT_PICKUP_COLLECTION_ATTEMPT";
const EDIT_PICKUP_COLLECTION_SUCCESS = "EDIT_PICKUP_COLLECTION_SUCCESS";
const EDIT_PICKUP_COLLECTION_FAILED = "EDIT_PICKUP_COLLECTION_FAILED";

const SHOW_EDIT_PICKUP_COLLECTION = "SHOW_EDIT_PICKUP_COLLECTION";
const DISMISS_EDIT_PICKUP_COLLECTION = "DISMISS_EDIT_PICKUP_COLLECTION";

/* >>>> End EDIT PICKUP COLLECTION <<<< */

/* >>>> Start Delete balance <<<< */

const DELETE_BALANCE_ATTEMPT = "DELETE_BALANCE_ATTEMPT";
const DELETE_BALANCE_SUCCESS = "DELETE_BALANCE_SUCCESS";
const DELETE_BALANCE_FAILED = "DELETE_BALANCE_FAILED";

/* >>>> End Delete balance <<<< */

/* >>>> Start fetch collected pickups <<<< */

const FETCH_COLLECTED_PICKUP_ATTEMPT = "FETCH_COLLECTED_PICKUP_ATTEMPT";
const FETCH_COLLECTED_PICKUP_SUCCESS = "FETCH_COLLECTED_PICKUP_SUCCESS";
const FETCH_COLLECTED_PICKUP_FAILED = "FETCH_COLLECTED_PICKUP_FAILED";

/* >>>> End fetch collected pickups <<<< */

/* >>>> Start Delete transaction <<<< */

const DELETE_TRANSACTION_ATTEMPT = "DELETE_TRANSACTION_ATTEMPT";
const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
const DELETE_TRANSACTION_FAILED = "DELETE_TRANSACTION_FAILED";

/* >>>> End Delete transaction <<<< */

export default {
  /* >>Start Builder Actions types<< */

  // handle on Form Input change
  ON_INPUT_CHANGE,
  ON_SELECT_INPUT_CHANGE,
  ON_INPUT_INCREMENT,
  ON_INPUT_DECREMENT,
  ON_CHECKBOX_INPUT_CHANGE,
  ON_CHECKBOX_BOOLEAN_INPUT_CHANGE,
  ON_RADIO_INPUT_CHANGE,
  ON_INPUT_RESET,
  ON_APPEND_INPUT_CHANGE,

  // HANDLE fORM VALIDATION
  SET_INPUT_VALID,
  SET_INPUT_INVALID,

  //handle dataTable #sorting #paginate #filter

  SET_DATATABLE_SORTING,
  SET_DATATABLE_FILTER,
  SET_DATATABLE_PAGINATE,
  /* >>End Builder Actions types<< */

  FETCH_USERS_ATTEMPT,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILED,

  FETCH_USERS_FILTER,

  SHOW_USER_FORM,
  DISMISS_USER_FORM,

  UPSERT_USER_ATTEMPT,
  UPSERT_USER_SUCCESS,
  UPSERT_USER_FAILED,

  FETCH_DONORS_VIEW_ATTEMPT,

  FETCH_DONORS_ATTEMPT,
  FETCH_DONORS_SUCCESS,
  FETCH_DONORS_FAILED,

  FETCH_DONORS_FILTER,
  ADD_DONOR_ADDRESS,
  REMOVE_DONOR_ADDRESS,
  ADDRESS_INPUT_CHANGE,

  FETCH_COLLECTOR_PROFILE_ATTEMPT,
  FETCH_COLLECTOR_PROFILE_SUCCESS,
  FETCH_COLLECTOR_PROFILE_FAILED,

  RESET_COLLECTOR_PROFILE,

  FETCH_COLLECTIONS_LIST_ATTEMPT,
  FETCH_COLLECTIONS_LIST_SUCCESS,
  FETCH_COLLECTIONS_LIST_FAILED,

  FETCH_PICKUPS_ATTEMPT,
  FETCH_PICKUPS_LIST_ATTEMPT,
  FETCH_PICKUPS_LIST_SUCCESS,
  FETCH_PICKUPS_LIST_FAILED,

  FETCH_PICKUPS_LIST_FILTER,

  SHOW_DONOR_FORM,
  DISMISS_DONOR_FORM,

  UPSERT_DONOR_ATTEMPT,
  UPSERT_DONOR_SUCCESS,
  UPSERT_DONOR_FAILED,

  FETCH_DONOR_ATTEMPT,
  FETCH_DONOR_SUCCESS,
  FETCH_DONOR_FAILED,

  FETCH_DONOR_VIEW_ATTEMPT,
  FETCH_DONOR_PROFILE_SUCCESS,

  FETCH_DONOR_PICKUPS_LIST_ATTEMPT,
  FETCH_DONOR_PICKUPS_LIST_SUCCESS,
  FETCH_DONOR_PICKUPS_LIST_FAILED,

  SETTLE_COLLECTOR_BALANCE_ATTEMPT,
  SETTLE_COLLECTOR_BALANCE_SUCCESS,
  SETTLE_COLLECTOR_BALANCE_FAILED,

  BALANCE_INPUT_CHANGE,

  SHOW_SETTLE_BALANCE_FORM,
  DISMISS_SETTLE_BALANCE_FORM,

  FORGOT_PASSWORD_ATTEMPT,
  FORGOT_PASSWORD_FINISH,

  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,

  LOGING_IN_ATTEMPT,
  LOGED_IN_SUCCESS,
  LOGED_IN_FAILED,

  LOG_OUT,

  FETCH_PICKUP_DONOR_ATTEMPT,
  FETCH_PICKUP_DONOR_SUCCESS,
  FETCH_PICKUP_DONOR_FAILED,

  FETCH_PICKUP_COLLECTORS_ATTEMPT,
  FETCH_PICKUP_COLLECTORS_SUCCESS,
  FETCH_PICKUP_COLLECTORS_FAILED,

  REMOVE_PICKUP_DONOR,

  SHOW_PICKUP_FORM,
  DISMISS_PICKUP_FORM,

  UPSERT_PICKUP_FORM_ATTEMPT,
  UPSERT_PICKUP_FORM_SUCCESS,
  UPSERT_PICKUP_FORM_FAILED,

  CANCEL_PICKUP_ATTEMPT,
  CANCEL_PICKUP_SUCCESS,
  CANCEL_PICKUP_FAILED,

  ADD_COLLECTOR_BALANCE_ATTEMPT,
  ADD_COLLECTOR_BALANCE_SUCCESS,
  ADD_COLLECTOR_BALANCE_FAILED,

  SHOW_ADD_BALANCE_FORM,
  SHOW_EDIT_ADD_BALANCE_FORM,
  DISMISS_ADD_BALANCE_FORM,

  ADD_BALANCE_FIELD,
  REMOVE_BALANCE_FIELD,

  SHOW_EDIT_PICKUP_COLLECTION,
  DISMISS_EDIT_PICKUP_COLLECTION,

  EDIT_PICKUP_COLLECTION_ATTEMPT,
  EDIT_PICKUP_COLLECTION_SUCCESS,
  EDIT_PICKUP_COLLECTION_FAILED,

  DELETE_BALANCE_ATTEMPT,
  DELETE_BALANCE_SUCCESS,
  DELETE_BALANCE_FAILED,

  FETCH_COLLECTED_PICKUP_ATTEMPT,
  FETCH_COLLECTED_PICKUP_SUCCESS,
  FETCH_COLLECTED_PICKUP_FAILED,

  DELETE_TRANSACTION_ATTEMPT,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILED,
};
