import { put, call, takeLatest, takeEvery, select } from "redux-saga/effects";
import Types from "../Actions/types";
import swal from "sweetalert";
import {
  dismissUserFormAction,
  fetchAllDonorsFailed,
  fetchAllDonorsFilter,
  fetchAllDonorsSuccess,
  fetchAllUsersAction,
  fetchAllUsersFailed,
  fetchAllUsersFilter,
  fetchAllUsersSuccess,
  fetchCollectorProfileFailed,
  fetchCollectorProfileSuccess,
  fetchCollectionsListSuccess,
  fetchCollectionsListFailed,
  upsertUserFailed,
  upsertUserSuccess,
  fetchCollectorProfileAction,
  fetchPickupsListSuccess,
  fetchPickupsListFailed,
  fetchPickupsListFilter,
  upsertDonorSuccess,
  dismissDonorFormAction,
  upsertDonorFailed,
  fetchDonorsViewAction,
  fetchDonorSuccess,
  fetchDonorFailed,
  fetchDonorPickupsListSuccess,
  fetchDonorProfileSuccess,
  fetchDonorPickupsListFailed,
  settleCollectorBalanceSuccess,
  settleCollectorBalanceFailed,
  fetchPickupDonorFailed,
  fetchPickupDonorSuccess,
  upsertPickupFailed,
  upsertPickupSuccess,
  fetchPickupsViewAction,
  cancelPickupFailed,
  cancelPickupSuccess,
  fetchDonorProfileViewAction,
  addCollectorBalanceSuccess,
  dismissAddBalanceFormAction,
  addCollectorBalanceFailed,
  editCollectionPickupSuccess,
  editCollectionPickupFailed,
  deleteBalanceSuccess,
  deleteBalanceFailed,
  fetchCollectedPickupSuccess,
  fetchCollectedPickupFailed,
  showPickupCollectionFormAction,
  deleteTransactionSuccess,
  deleteTransactionFailed,
} from "../Actions";

import {
  fetchAllUsers,
  upsertUser,
  fetchAllDonors,
  fetchCollectorProfile,
  fetchCollectionsList,
  fetchPickupsList,
  fetchPickups,
  upsertDonor,
  fetchDonor,
  fetchDonorProfileView,
  fetchDonorPickups,
  SettleCollectorBalance,
  fetchPickupDonor,
  upsertPickup,
  cancelPickup,
  addCollectorBalance,
  editPickupCollection,
  deleteBalance,
  deleteTransaction,
  fetchCollectedPickup,
} from "../../Services/Client";

export const getSuperReducer = (state) => state.super;

export function* handleFetchAllUsersSaga({ payload }) {
  try {
    const response = yield call(fetchAllUsers, payload);
    yield put(fetchAllUsersSuccess(payload.ref, response.data.users));
    yield put(fetchAllUsersFilter(response.data.usersFilter));
  } catch (error) {
    yield put(fetchAllUsersFailed(payload.ref, error));
    console.log("err", error);
  }
}

function* onFetchAllUsers() {
  yield takeLatest(Types.FETCH_USERS_ATTEMPT, handleFetchAllUsersSaga);
}

export function* handleUpsertUserSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(upsertUser, payload.user);
    yield put(upsertUserSuccess(response.data.upsertUser));
    yield put(dismissUserFormAction());
    if (payload.path.includes("users")) {
      yield put(
        fetchAllUsersAction({
          ref: "allUsersList",
          ["allUsersList"]: superReducer["allUsersList"],
        })
      );
    }
    if (payload.path.includes("collector-profile")) {
      let id = payload.path.split("/").pop();
      yield put(fetchCollectorProfileAction(id));
    }
  } catch (error) {
    if (error?.graphQLErrors[0]?.extensions?.validation) {
      let validationKey = Object.keys(
        error?.graphQLErrors[0]?.extensions?.validation
      )[0];
      yield put(
        upsertUserFailed(
          error?.graphQLErrors[0]?.extensions?.validation[validationKey][0]
        )
      );
      return;
    }
    yield put(upsertUserFailed("Something Went Wrong!"));
    console.log("err", { error });
  }
}

function* onUpsertUser() {
  yield takeLatest(Types.UPSERT_USER_ATTEMPT, handleUpsertUserSaga);
}

// fetch Donors List
export function* handleFetchAllDonorsSaga({ payload }) {
  try {
    const response = yield call(fetchAllDonors, payload);
    yield put(fetchAllDonorsSuccess(payload.ref, response.data.donors));
  } catch (error) {
    yield put(fetchAllDonorsFailed(payload.ref, error));
    console.log("err", error);
  }
}

function* onFetchAllDonors() {
  yield takeLatest(Types.FETCH_DONORS_ATTEMPT, handleFetchAllDonorsSaga);
}

// fetch Collector Profile
export function* handleFetchCollectorProfileSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(fetchCollectorProfile, {
      id: payload.id,
      collectionsList: superReducer.collectionsList,
    });
    yield put(fetchCollectorProfileSuccess(response.data.user));
    yield put(
      fetchCollectionsListSuccess("collectionsList", response.data.collections)
    );
  } catch (error) {
    yield put(fetchCollectorProfileFailed(error));
    console.log("err", error);
  }
}

function* onFetchCollectorProfile() {
  yield takeLatest(
    Types.FETCH_COLLECTOR_PROFILE_ATTEMPT,
    handleFetchCollectorProfileSaga
  );
}

export function* handleFetchCollectionsListSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(fetchCollectionsList, payload);
    yield put(
      fetchCollectionsListSuccess(payload.ref, response.data.collections)
    );
  } catch (error) {
    yield put(fetchCollectionsListFailed(payload.ref));
    console.log("err", error);
  }
}

function* onFetchCollectionsList() {
  yield takeLatest(
    Types.FETCH_COLLECTIONS_LIST_ATTEMPT,
    handleFetchCollectionsListSaga
  );
}

// HANDLLE FETCH PICKUPS

export function* handleFetchPickupsSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(fetchPickups, superReducer.allpickupsList);
    yield put(fetchPickupsListSuccess(payload.ref, response.data.pickups));
    yield put(
      fetchPickupsListFilter(
        response.data.donorsFilter.data,
        response.data.collectorsFilter.data
      )
    );
  } catch (error) {
    yield put(fetchPickupsListFailed(payload.ref));
    console.log("err", error);
  }
}

function* onFetchPickups() {
  yield takeLatest(Types.FETCH_PICKUPS_ATTEMPT, handleFetchPickupsSaga);
}

export function* handleFetchPickupsListSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(fetchPickupsList, payload);
    yield put(fetchPickupsListSuccess(payload.ref, response.data.pickups));
    yield put(
      fetchPickupsListFilter(
        response.data.donorsFilter.data,
        response.data.collectorsFilter.data
      )
    );
  } catch (error) {
    yield put(fetchPickupsListFailed(payload.ref));
    console.log("err", error);
  }
}

function* onFetchPickupsList() {
  yield takeLatest(
    Types.FETCH_PICKUPS_LIST_ATTEMPT,
    handleFetchPickupsListSaga
  );
}

// fetch Donors
export function* handleFetchDonorsSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(fetchAllDonors, superReducer);
    yield put(fetchAllDonorsSuccess(payload.ref, response.data.donors));
  } catch (error) {
    yield put(fetchAllDonorsFailed(payload.ref, error));
    console.log("err", error);
  }
}

function* onFetchDonors() {
  yield takeLatest(Types.FETCH_DONORS_VIEW_ATTEMPT, handleFetchDonorsSaga);
}

//upsert donor

export function* handleUpsertDonorSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(upsertDonor, payload.user);
    yield put(upsertDonorSuccess(response.data.upsertDonor));
    yield put(dismissDonorFormAction());
    if (superReducer.pickupFormModal.isOpen) {
      console.log([{ ...response.data.upsertDonor.user }]);
      yield put(
        fetchPickupDonorSuccess([{ ...response.data.upsertDonor.user }])
      );
      return;
    }
    if (payload.donorId) {
      yield put(fetchDonorProfileViewAction(payload.donorId));
      return;
    } else {
      yield put(fetchDonorsViewAction());
      return;
    }
  } catch (error) {
    console.log("err", { error });
    if (error?.graphQLErrors[0]?.extensions?.validation) {
      let validationKeys = Object.keys(
        error?.graphQLErrors[0]?.extensions?.validation
      );
      let validations = Object.fromEntries(
        validationKeys.map((key) => [
          [key],
          error?.graphQLErrors[0]?.extensions?.validation[key][0],
        ])
      );

      yield put(upsertDonorFailed(validations));
      return;
    }
    console.log("err", { error });
  }
}

function* onUpsertDonor() {
  yield takeLatest(Types.UPSERT_DONOR_ATTEMPT, handleUpsertDonorSaga);
}

export function* handleFetchDonorSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(fetchDonor, payload);
    yield put(fetchDonorSuccess(response.data.donor));
  } catch (error) {
    console.log("err", { error });
    yield put(fetchDonorFailed(error));
  }
}

function* onFetchDonor() {
  yield takeLatest(Types.FETCH_DONOR_ATTEMPT, handleFetchDonorSaga);
}

export function* handleFetchDonorProfileViewSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the superReducer
    const response = yield call(fetchDonorProfileView, {
      donorPickupsList: superReducer.donorPickupsList,
      id: payload.id,
    });
    yield put(fetchDonorProfileSuccess(response.data.donor));
    yield put(
      fetchDonorPickupsListSuccess(payload.ref, response.data.donorPickups)
    );
    yield put(fetchPickupsListFilter([], response.data.collectorsFilter.data));
  } catch (error) {
    console.log("err", { error });
    yield put(fetchDonorFailed(error));
  }
}

function* onFetchDonorProfileView() {
  yield takeLatest(
    Types.FETCH_DONOR_VIEW_ATTEMPT,
    handleFetchDonorProfileViewSaga
  );
}

export function* handleFetchDonorPickupsSaga({ payload }) {
  try {
    const response = yield call(fetchDonorPickups, {
      donorPickupsList: payload.donorPickupsList,
      id: payload.id,
    });
    yield put(
      fetchDonorPickupsListSuccess(payload.ref, response.data.donorPickups)
    );
  } catch (error) {
    console.log("err", { error });
    yield put(fetchDonorPickupsListFailed(payload.ref));
  }
}

function* onFetchDonorPickups() {
  yield takeLatest(
    Types.FETCH_DONOR_PICKUPS_LIST_ATTEMPT,
    handleFetchDonorPickupsSaga
  );
}

export function* handleSettleCollectorBalanceSaga({ payload }) {
  try {
    const response = yield call(SettleCollectorBalance, payload);
    yield put(settleCollectorBalanceSuccess(response.data.donorPickups));
    yield put(fetchCollectorProfileAction(payload.balance?.collector_id));
  } catch (error) {
    console.log("err", { error });
    if (error?.graphQLErrors[0]?.extensions?.validation) {
      let validationKeys = Object.keys(
        error?.graphQLErrors[0]?.extensions?.validation
      );
      let validations = Object.fromEntries(
        validationKeys.map((key) => [
          [key],
          error?.graphQLErrors[0]?.extensions?.validation[key][0],
        ])
      );

      yield put(settleCollectorBalanceFailed(validations));
      return;
    }
    yield put(settleCollectorBalanceFailed());
  }
}

function* onSettleCollectorBalance() {
  yield takeLatest(
    Types.SETTLE_COLLECTOR_BALANCE_ATTEMPT,
    handleSettleCollectorBalanceSaga
  );
}

export function* handleAddCollectorBalanceSaga({ payload }) {
  try {
    const response = yield call(addCollectorBalance, payload);
    yield put(addCollectorBalanceSuccess());
    yield put(dismissAddBalanceFormAction());
    yield put(fetchCollectorProfileAction(payload.balance?.collector_id));
  } catch (error) {
    console.log("err", { error });
    if (error?.graphQLErrors[0]?.extensions?.validation) {
      let validationKeys = Object.keys(
        error?.graphQLErrors[0]?.extensions?.validation
      );
      let validations = Object.fromEntries(
        validationKeys.map((key) => [
          [key],
          error?.graphQLErrors[0]?.extensions?.validation[key][0],
        ])
      );

      yield put(addCollectorBalanceFailed(validations));
      return;
    }
    yield put(addCollectorBalanceFailed());
  }
}

function* onAddCollectorBalance() {
  yield takeEvery(
    Types.ADD_COLLECTOR_BALANCE_ATTEMPT,
    handleAddCollectorBalanceSaga
  );
}

export function* handlefetchPickupDonorSaga({ payload }) {
  try {
    const response = yield call(fetchPickupDonor, payload);
    yield put(fetchPickupDonorSuccess(response.data.donors.data));
  } catch (error) {
    console.log("err", { error });

    yield put(fetchPickupDonorFailed(error.message));
  }
}

function* onfetchPickupDonor() {
  yield takeLatest(
    Types.FETCH_PICKUP_DONOR_ATTEMPT,
    handlefetchPickupDonorSaga
  );
}

export function* handleUpsertPickupSaga({ payload }) {
  try {
    const response = yield call(upsertPickup, payload);
    yield put(upsertPickupSuccess(response.data.upsertPickup));
    if (payload.donorId) {
      yield put(fetchDonorProfileViewAction(payload.donorId));
      return;
    } else {
      yield put(fetchPickupsViewAction());
    }
  } catch (error) {
    if (error?.graphQLErrors[0]?.extensions?.validation) {
      let validationKeys = Object.keys(
        error?.graphQLErrors[0]?.extensions?.validation
      );
      let validations = Object.fromEntries(
        validationKeys.map((key) => [
          [key],
          error?.graphQLErrors[0]?.extensions?.validation[key][0],
        ])
      );

      yield put(upsertPickupFailed(validations));
      return;
    }
    yield put(upsertPickupFailed());
  }
}

function* onUpsertPickup() {
  yield takeLatest(Types.UPSERT_PICKUP_FORM_ATTEMPT, handleUpsertPickupSaga);
}

export function* handleCancelPickupSaga({ payload }) {
  try {
    const response = yield call(cancelPickup, payload);
    yield put(cancelPickupSuccess(response.data.cancelPickup));
    swal({
      title: response.data.cancelPickup?.status,
      text: response.data.cancelPickup?.message,
      icon: response.data.cancelPickup?.status,
      timer: 2000,
      buttons: false,
    });
    if (payload.collectorId) {
      yield put(fetchCollectorProfileAction(payload.collectorId));
      return;
    }
    yield put(fetchPickupsViewAction());
  } catch (error) {
    yield put(cancelPickupFailed());
    swal({
      title: "Error",
      text: error.message ?? "Something went wrong!",
      icon: "error",
      timer: 2000,
      buttons: false,
    });
  }
}

function* onCancelPickup() {
  yield takeEvery(Types.CANCEL_PICKUP_ATTEMPT, handleCancelPickupSaga);
}

export function* handleEditPickupCollectionSaga({ payload }) {
  try {
    const response = yield call(editPickupCollection, payload);
    yield put(editCollectionPickupSuccess(response.data));
    swal({
      title: `pickup ${response.data?.upsertPickupCollection?.payment?.name}`,
      text: `pickup collection updated successfully`,
      icon: "success",
      timer: 2000,
      buttons: false,
    });
    yield put(fetchCollectorProfileAction(payload.collection?.collector_id));
  } catch (error) {
    if (error?.graphQLErrors[0]?.extensions?.validation) {
      let validationKeys = Object.keys(
        error?.graphQLErrors[0]?.extensions?.validation
      );
      let validations = Object.fromEntries(
        validationKeys.map((key) => [
          [key],
          error?.graphQLErrors[0]?.extensions?.validation[key].toString(),
        ])
      );

      yield put(editCollectionPickupFailed(validations));
      return;
    }
    yield put(editCollectionPickupFailed());
  }
}

function* onEditPickupCollection() {
  yield takeEvery(
    Types.EDIT_PICKUP_COLLECTION_ATTEMPT,
    handleEditPickupCollectionSaga
  );
}

export function* handleDeleteBalanceSaga({ payload }) {
  try {
    const response = yield call(deleteBalance, payload);
    yield put(deleteBalanceSuccess());
    yield put(fetchCollectorProfileAction(payload.collectorId));
    swal({
      title: response.data.deletePayment?.status,
      text: response.data.deletePayment?.message,
      icon: response.data.deletePayment?.status,
      timer: 2000,
      buttons: false,
    });
  } catch (error) {
    yield put(deleteBalanceFailed());
    swal({
      title: "Error",
      text: error.message ?? "Something went wrong!",
      icon: "error",
      timer: 2000,
      buttons: false,
    });
  }
}

function* onDeleteBalance() {
  yield takeEvery(Types.DELETE_BALANCE_ATTEMPT, handleDeleteBalanceSaga);
}

export function* handleFetchCollectedPickupSaga({ payload }) {
  try {
    const response = yield call(fetchCollectedPickup, payload);
    yield put(fetchCollectedPickupSuccess());
    let collectedPickup = {
      id: response.data?.payment?.id,
      collector_id: response.data?.payment?.collector?.id,
      type_id: "Pickup",
      has_cash: response.data?.payment.has_cash,
      has_material: response.data?.payment.has_material,
      material_note: response.data?.payment.material_note,
      transactions: response.data?.payment?.transactions.map(
        ({ id, amount, currency }) => ({
          id,
          amount,
          currency_id: currency.id,
        })
      ),
    };
    yield put(
      showPickupCollectionFormAction(
        response.data?.payment.name,
        collectedPickup
      )
    );
  } catch (error) {
    yield put(fetchCollectedPickupFailed());
    swal({
      title: "Error",
      text: error.message ?? "Something went wrong!",
      icon: "error",
      timer: 2000,
      buttons: false,
    });
  }
}

function* onFetchCollectedPickup() {
  yield takeLatest(
    Types.FETCH_COLLECTED_PICKUP_ATTEMPT,
    handleFetchCollectedPickupSaga
  );
}

// handle delete Transaction
export function* handleDeleteTransactionSaga({ payload }) {
  try {
    const response = yield call(deleteTransaction, payload);
    yield put(deleteTransactionSuccess());
    if (response.data.deleteTransaction?.status == "success") {
      yield put(editCollectionPickupSuccess());
      yield put(dismissAddBalanceFormAction());
      yield put(settleCollectorBalanceSuccess());
      yield put(fetchCollectorProfileAction(payload.collectorId));
    }
    swal({
      title: response.data.deleteTransaction?.status,
      text: response.data.deleteTransaction?.message,
      icon: response.data.deleteTransaction?.status,
      timer: 2000,
      buttons: false,
    });
  } catch (error) {
    yield put(deleteTransactionFailed());
    swal({
      title: "Error",
      text: error.message ?? "Something went wrong!",
      icon: "error",
      timer: 2000,
      buttons: false,
    });
  }
}

function* onDeleteTransaction() {
  yield takeEvery(
    Types.DELETE_TRANSACTION_ATTEMPT,
    handleDeleteTransactionSaga
  );
}

export default {
  onFetchAllUsers,
  onUpsertUser,
  onFetchAllDonors,
  onFetchDonors,
  onFetchCollectorProfile,
  onFetchCollectionsList,
  onFetchPickupsList,
  onFetchPickups,
  onUpsertDonor,
  onFetchDonor,
  onFetchDonorProfileView,
  onFetchDonorPickups,
  onSettleCollectorBalance,
  onfetchPickupDonor,
  onUpsertPickup,
  onCancelPickup,
  onAddCollectorBalance,
  onEditPickupCollection,
  onDeleteBalance,
  onFetchCollectedPickup,
  onDeleteTransaction,
};
