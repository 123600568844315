import gql from "graphql-tag";

export const authUserMutation = gql`
  mutation authUser($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      user {
        id
        name
        role {
          name
          id
        }
      }
    }
  }
`;

export const forgotPasswordMutation = gql`
  mutation forgot_Password($email: String!) {
    forgotPassword(input: { email: $email }) {
      message
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      message
    }
  }
`;

export const upsertUserMutation = gql`
  mutation upsertUser($input: UpsertUserInput!) {
    upsertUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const upsertDonorMutation = gql`
  mutation upsertDonor($input: UpsertDonorInput!) {
    upsertDonor(input: $input) {
      user {
        id
        name
        phone
        address {
          id
          name
          area {
            name
            city {
              name
            }
          }
        }
        addresses {
          id
          name
          area {
            name
            city {
              name
            }
          }
        }
      }
    }
  }
`;

export const upsertSettleBalanceMutation = gql`
  mutation settleBalance($input: UpsertBalanceInput!) {
    settleBalance(input: $input) {
      payment {
        id
      }
    }
  }
`;

export const upsertPickupMutation = gql`
  mutation upsertPickup($input: UpsertPickupInput!) {
    upsertPickup(input: $input) {
      payment {
        id
      }
    }
  }
`;

export const cancelPickupMutation = gql`
  mutation cancelPickup($input: CancelPickupInput!) {
    cancelPickup(input: $input) {
      status
      message
    }
  }
`;

export const addBalanceMutation = gql`
  mutation addBalance($input: UpsertBalanceInput!) {
    addBalance(input: $input) {
      payment {
        id
      }
    }
  }
`;

export const editCollectionMutation = gql`
  mutation editPickupCollection($input: UpsertPickupCollectionInput!) {
    upsertPickupCollection(input: $input) {
      payment {
        id
        name
      }
    }
  }
`;

export const deleteBalanceMutation = gql`
  mutation deleteBalance($id: ID!) {
    deletePayment(id: $id) {
      status
      message
    }
  }
`;

export const deleteTransactionMutation = gql`
  mutation deleteTransaction($id: ID!) {
    deleteTransaction(id: $id) {
      status
      message
    }
  }
`;
