import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <div className="container">
        <div className="content">
          <div data-custom-class="body">
            <p style={{ fontSize: "15px" }}>
              <strong>
                <span style={{ fontSize: "26px" }}>
                  <span data-custom-class="title">
                    <bdt className="block-component" />
                    PRIVACY NOTICE
                    <bdt className="else-block" />
                  </span>
                </span>
              </strong>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span data-custom-class="subtitle">
                    Last updated <bdt className="question">Jan 1, 2022</bdt>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <br />
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Thank you for choosing to be part of our community at{" "}
                  <bdt className="question">Merqah</bdt>
                  <bdt className="block-component" /> (“
                  <bdt className="block-component" />
                  <strong>Company</strong>
                  <bdt className="statement-end-if-in-editor" />
                  ”, “<strong>we</strong>”, “<strong>us</strong>”, or “
                  <strong>our</strong>”). We are committed to protecting your
                  personal information and your right to privacy. If you have
                  any questions or concerns about our{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      notice
                      <bdt className="else-block" />
                    </span>
                  </span>
                  , or our practices with regards to your personal information,
                  please contact us at{" "}
                  <bdt className="question">info@merqah.com</bdt>.
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  When you visit our <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="forloop-component" />
                  <bdt className="block-component" /> mobile application,
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="forloop-component" />
                  <bdt className="statement-end-if-in-editor" /> and use our
                  services, you trust us with your personal information. We take
                  your privacy very seriously. In this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>
                  </span>
                  , we seek to explain to you in the clearest way possible what
                  information we collect, how we use it and what rights you have
                  in relation to it. We hope you take some time to read through
                  it carefully, as it is important. If there are any terms in
                  this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>{" "}
                  </span>
                  that you do not agree with, please discontinue use of our{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" /> and our
                  services.
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  This{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>{" "}
                  </span>
                  applies to all information collected through our{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="forloop-component" />
                  <bdt className="block-component" /> mobile application,
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                  <bdt className="forloop-component" />
                </span>
                <span data-custom-class="body_text">
                  ("<strong>Merqah Collections</strong>"),
                  <bdt className="statement-end-if-in-editor" /> and/or any
                  related services, sales, marketing or events (we refer to them
                  collectively in this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>{" "}
                  </span>
                  as the "<strong>Services</strong>").
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <span data-custom-class="body_text">
                    Please read this{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        privacy notice
                        <bdt className="else-block" />
                      </span>{" "}
                    </span>
                    carefully as it will help you make informed decisions about
                    sharing your personal information with us.
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link" >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
              </a>{" "}
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link" >
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  2. HOW DO WE USE YOUR INFORMATION?
                </span>
              </a>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link">
                  {/* href="#infoshare"> */}
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </a>
                <bdt className="block-component">
                  <span data-custom-class="body_text" />
                </bdt>
              </span>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#cookies"> */}
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </span>
              </a>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#googlemaps"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  5. DO WE USE GOOGLE MAPS?
                </span>
              </a>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                  </span>
                </span>
                <bdt className="block-component" />
                <bdt className="block-component" />
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#inforetain"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </a>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#infosafe"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </a>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                <bdt className="block-component" />
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#infominors"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  8. DO WE COLLECT INFORMATION FROM MINORS?
                </span>
              </a>
              <bdt className="statement-end-if-in-editor" />
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#privacyrights"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  9. WHAT ARE YOUR PRIVACY RIGHTS?
                  <bdt className="block-component" />
                </span>
              </a>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#DNT"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  10. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </a>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#caresidents"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </a>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#policyupdates"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  12. DO WE MAKE UPDATES TO THIS POLICY?
                </span>
              </a>
            </p>
            <p style={{ fontSize: "15px" }}>
              <a data-custom-class="link">
                {/* href="#contact"> */}
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </span>
              </a>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="infocollect" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </span>
                </strong>
                <span style={{ fontSize: "19px" }}>
                  <span data-custom-class="heading_1">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <strong>
                <span data-custom-class="heading_2">
                  <br />
                  <span style={{ fontSize: "16px" }}>
                    Information automatically collected
                  </span>
                </span>
              </strong>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short: </span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <em>
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      Some information — such as IP address and/or browser and
                      device characteristics — is collected automatically when
                      you visit our <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      Apps
                      <bdt className="statement-end-if-in-editor" />.
                    </span>
                  </span>
                </em>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    We automatically collect certain information when you visit,
                    use or navigate the <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    Apps
                    <bdt className="statement-end-if-in-editor" />. This
                    information does not reveal your specific identity (like
                    your name or contact information) but may include device and
                    usage information, such as your IP address, browser and
                    device characteristics, operating system, language
                    preferences, referring URLs, device name, country, location,
                    information about how and when you use our{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    Apps
                    <bdt className="statement-end-if-in-editor" /> and other
                    technical information. This information is primarily needed
                    to maintain the security and operation of our{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    Apps
                    <bdt className="statement-end-if-in-editor" />, and for our
                    internal analytics and reporting purposes.
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Like many businesses, we also collect information through
                    cookies and similar technologies.{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor" />
                </span>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <strong>
                <span style={{ fontSize: "16px" }}>
                  <span data-custom-class="heading_2">
                    <strong>
                      <span data-custom-class="heading_2">
                        <br />
                        Information collected through our Apps
                      </span>
                    </strong>
                  </span>
                </span>
              </strong>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We may collect information regarding your
                      <bdt className="block-component" /> geo-location,
                      <bdt className="statement-end-if-in-editor">
                        <bdt className="block-component" />
                      </bdt>{" "}
                      mobile device,
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <em>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="statement-end-if-in-editor" />
                              </bdt>
                            </span>
                          </em>
                        </span>
                      </span>
                      <bdt className="block-component" /> push notifications,
                      <bdt className="statement-end-if-in-editor" />
                      <bdt className="forloop-component" />
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <em>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </em>
                        </span>
                      </span>
                      <bdt className="forloop-component" />{" "}
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <em>
                            <span data-custom-class="body_text">
                              when you use our apps.
                            </span>
                          </em>
                        </span>
                      </span>
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <div>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  If you use our Apps, we may also collect the following
                  information:
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <ul>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Geo-Location Information.</em> We may request access
                      or permission to and track location-based information from
                      your mobile device, either continuously or while you are
                      using our mobile application, to provide location-based
                      services. If you wish to change our access or permissions,
                      you may do so in your device's settings.
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                      >
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                      <bdt className="block-component">
                        <bdt className="block-component" />
                      </bdt>
                    </span>
                  </span>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Mobile Device Data.</em> We may automatically collect
                      device information (such as your mobile device ID, model
                      and manufacturer), operating system, version information
                      and IP address.
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor">
                              <bdt className="block-component" />
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Push Notifications.</em> We may request to send you
                      push notifications regarding your account or the mobile
                      application. If you wish to opt-out from receiving these
                      types of communications, you may turn them off in your
                      device's settings.
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                      <bdt className="forloop-component" />
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                      <bdt className="forloop-component" />
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor">
                        <bdt className="block-component" />
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="infouse" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      2. HOW DO WE USE YOUR INFORMATION?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We process your information for purposes based on
                      legitimate business interests, the fulfillment of our
                      contract with you, compliance with our legal obligations,
                      and/or your consent.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We use personal information collected via our{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    Apps
                    <bdt className="statement-end-if-in-editor" /> for a variety
                    of business purposes described below. We process your
                    personal information for these purposes in reliance on our
                    legitimate business interests, in order to enter into or
                    perform a contract with you, with your consent, and/or for
                    compliance with our legal obligations. We indicate the
                    specific processing grounds we rely on next to each purpose
                    listed below.
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We use the information we collect or receive:
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <br />
              <br />
            </p>
            <li>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      To enforce our terms, conditions and policies for Business
                      Purposes, Legal Reasons and Contractual.
                    </span>
                  </strong>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      To respond to legal requests and prevent harm.{" "}
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    If we receive a subpoena or other legal request, we may need
                    to inspect the data we hold to determine how to respond.
                  </span>
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <br />
              <br />
            </li>
            <li>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      To manage
                                                                      user
                                                                      accounts
                                                                    </strong>
                                                                    . We may use
                                                                    your
                                                                    information
                                                                    for the
                                                                    purposes of
                                                                    managing our
                                                                    account and
                                                                    keeping it
                                                                    in working
                                                                    order.
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "15px",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize:
                                                                                              "15px",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize:
                                                                                                  "15px",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize:
                                                                                                      "15px",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize:
                                                                                                          "15px",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize:
                                                                                                              "15px",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize:
                                                                                                                  "15px",
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize:
                                                                                                                      "15px",
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize:
                                                                                                                          "15px",
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                                          <bdt className="block-component" />
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <br />
                                                                  </span>
                                                                </span>{" "}
                                                              </span>{" "}
                                                            </span>{" "}
                                                          </span>{" "}
                                                        </span>{" "}
                                                      </span>{" "}
                                                    </span>{" "}
                                                  </span>{" "}
                                                </span>{" "}
                                              </span>{" "}
                                            </span>{" "}
                                          </span>{" "}
                                        </span>{" "}
                                      </span>{" "}
                                    </span>{" "}
                                  </span>{" "}
                                </span>{" "}
                              </span>{" "}
                            </span>{" "}
                          </span>{" "}
                        </span>{" "}
                      </span>{" "}
                    </span>{" "}
                  </span>{" "}
                </span>{" "}
              </span>
            </li>
            <li>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      To deliver
                                                                      services
                                                                      to the
                                                                      user.
                                                                    </strong>{" "}
                                                                    We may use
                                                                    your
                                                                    information
                                                                    to provide
                                                                    you with the
                                                                    requested
                                                                    service.
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "15px",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "15px",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize:
                                                                                              "15px",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize:
                                                                                                  "15px",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize:
                                                                                                      "15px",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize:
                                                                                                          "15px",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize:
                                                                                                              "15px",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize:
                                                                                                                  "15px",
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize:
                                                                                                                      "15px",
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize:
                                                                                                                          "15px",
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              fontSize:
                                                                                                                                "15px",
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize:
                                                                                                                                    "15px",
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span
                                                                                                                                    style={{
                                                                                                                                      fontSize:
                                                                                                                                        "15px",
                                                                                                                                    }}
                                                                                                                                  >
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        color:
                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          fontSize:
                                                                                                                                            "15px",
                                                                                                                                        }}
                                                                                                                                      >
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            color:
                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                          }}
                                                                                                                                        >
                                                                                                                                          <span
                                                                                                                                            style={{
                                                                                                                                              fontSize:
                                                                                                                                                "15px",
                                                                                                                                            }}
                                                                                                                                          >
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                color:
                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                              }}
                                                                                                                                            >
                                                                                                                                              <span
                                                                                                                                                style={{
                                                                                                                                                  fontSize:
                                                                                                                                                    "15px",
                                                                                                                                                }}
                                                                                                                                              >
                                                                                                                                                <span
                                                                                                                                                  style={{
                                                                                                                                                    color:
                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  <span
                                                                                                                                                    style={{
                                                                                                                                                      fontSize:
                                                                                                                                                        "15px",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize:
                                                                                                                                                            "15px",
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize:
                                                                                                                                                                "15px",
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize:
                                                                                                                                                                    "15px",
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      fontSize:
                                                                                                                                                                        "15px",
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span
                                                                                                                                                                      style={{
                                                                                                                                                                        color:
                                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                                      }}
                                                                                                                                                                    >
                                                                                                                                                                      <span
                                                                                                                                                                        style={{
                                                                                                                                                                          fontSize:
                                                                                                                                                                            "15px",
                                                                                                                                                                        }}
                                                                                                                                                                      >
                                                                                                                                                                        <span
                                                                                                                                                                          style={{
                                                                                                                                                                            color:
                                                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                                                          }}
                                                                                                                                                                        >
                                                                                                                                                                          <span
                                                                                                                                                                            style={{
                                                                                                                                                                              fontSize:
                                                                                                                                                                                "15px",
                                                                                                                                                                            }}
                                                                                                                                                                          >
                                                                                                                                                                            <span
                                                                                                                                                                              style={{
                                                                                                                                                                                color:
                                                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                                              }}
                                                                                                                                                                            >
                                                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                              </span>
                                                                                                                                                                            </span>
                                                                                                                                                                          </span>
                                                                                                                                                                        </span>
                                                                                                                                                                      </span>
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </span>
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <bdt className="block-component" />
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "15px",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize:
                                                                                    "15px",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "15px",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize:
                                                                                            "15px",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                              fontSize:
                                                                                                "15px",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="infoshare" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We only share information with your consent, to comply
                      with laws, to provide you with services, to protect your
                      rights, or to fulfill business obligations.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <div>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  We may process or share data based on the following legal
                  basis:
                </span>
              </span>
            </div>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Consent:</strong> We may process your data if you
                    have given us specific consent to use your personal
                    information in a specific purpose.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Legitimate Interests:</strong> We may process your
                    data when it is reasonably necessary to achieve our
                    legitimate business interests.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Performance of a Contract: </strong>Where we have
                    entered into a contract with you, we may process your
                    personal information to fulfill the terms of our contract.
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <strong>Legal Obligations:</strong> We may disclose your
                    information where we are legally required to do so in order
                    to comply with applicable law, governmental requests, a
                    judicial proceeding, court order, or legal process, such as
                    in response to a court order or a subpoena (including in
                    response to public authorities to meet national security or
                    law enforcement requirements).
                  </span>
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <strong>Vital Interests:</strong> We may disclose your
                    information where we believe it is necessary to investigate,
                    prevent, or take action regarding potential violations of
                    our policies, suspected fraud, situations involving
                    potential threats to the safety of any person and illegal
                    activities, or as evidence in litigation in which we are
                    involved.
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    More specifically, we may need to process your data or share
                    your personal information in the following situations:
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </p>
            <ul>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Vendors, Consultants and Other Third-Party Service
                        Providers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      {" "}
                      We may share your data with third party vendors, service
                      providers, contractors or agents who perform services for
                      us or on our behalf and require access to such information
                      to do that work. Examples include: payment processing,
                      data analysis, email delivery, hosting services, customer
                      service and marketing efforts. We may allow selected third
                      parties to use tracking technology on the{" "}
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      Apps
                      <bdt className="statement-end-if-in-editor" />, which will
                      enable them to collect data about how you interact with
                      the <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      Apps
                      <bdt className="statement-end-if-in-editor" /> over time.
                      This information may be used to, among other things,
                      analyze and track data, determine the popularity of
                      certain content and better understand online activity.
                      Unless described in this Policy, we do not share, sell,
                      rent or trade any of your information with third parties
                      for their promotional purposes.{" "}
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Business Transfers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      {" "}
                      We may share or transfer your information in connection
                      with, or during negotiations of, any merger, sale of
                      company assets, financing, or acquisition of all or a
                      portion of our business to another company.
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <span data-custom-class="body_text">
                        Third-Party Advertisers.
                      </span>
                    </strong>
                    <span data-custom-class="body_text">
                      {" "}
                      We may use third-party advertising companies to serve ads
                      when you visit the <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      Apps
                      <bdt className="statement-end-if-in-editor" />. These
                      companies may use information about your visits to our
                      Website(s) and other websites that are contained in web
                      cookies and other tracking technologies in order to
                      provide advertisements about goods and services of
                      interest to you. <bdt className="block-component" />
                      <bdt className="statement-end-if-in-editor" />
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <bdt className="block-component" />
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <span data-custom-class="body_text" />
                  </bdt>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span data-custom-class="body_text" />
              </bdt>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component">
                          <span data-custom-class="heading_1" />
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <p style={{ fontSize: "15px" }}>
              <br />
            </p>
            <div>
              <span id="cookies" style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We may use cookies and other tracking technologies to
                      collect and store your information.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Policy
                  <bdt className="block-component" />.
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="googlemaps" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      5. DO WE USE GOOGLE MAPS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      Yes, we use Google Maps for the purpose of providing
                      better service.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    This website, mobile application, or Facebook application
                    uses Google Maps APIs. You may find the Google Maps APIs
                    Terms of Service{" "}
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(48, 48, 241)" }}>
                    <a
                      data-custom-class="link"
                      href="https://developers.google.com/maps/terms"
                      target="_blank"
                    >
                      here
                    </a>
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    . To better understand Google’s Privacy Policy, please refer
                    to this{" "}
                  </span>
                  <span style={{ color: "rgb(48, 48, 241)" }}>
                    <a
                      data-custom-class="link"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                    >
                      link
                    </a>
                  </span>
                </span>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">.</span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    By using our Maps API Implementation, you agree to be bound
                    by Google’s Terms of Service.{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    You agree to allow us to obtain or cache your location. You
                    may revoke your consent at anytime.{" "}
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor" />
                              <bdt className="block-component" />
                            </span>
                            <bdt className="block-component">
                              <span data-custom-class="body_text" />
                            </bdt>
                            <bdt className="block-component"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="inforetain" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We keep your information for as long as necessary to
                      fulfill the purposes outlined in this{" "}
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                          privacy notice
                          <bdt className="else-block" />
                        </span>{" "}
                      </span>
                      unless otherwise required by law.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        privacy notice
                        <bdt className="else-block" />
                      </span>
                    </span>
                    , unless a longer retention period is required or permitted
                    by law (such as tax, accounting or other legal
                    requirements). No purpose in this policy will require us
                    keeping your personal information for longer than{" "}
                    <bdt className="block-component" />
                    <bdt className="question">90 days</bdt>
                    <bdt className="statement-end-if-in-editor" />.
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  it, or, if this is not possible (for example, because your
                  personal information has been stored in backup archives), then
                  we will securely store your personal information and isolate
                  it from any further processing until deletion is possible.
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
              </span>
            </p>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <p id="infosafe" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We aim to protect your personal information through a
                      system of organizational and technical security measures.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  We have implemented appropriate technical and organizational
                  security measures designed to protect the security of any
                  personal information we process. However, please also remember
                  that we cannot guarantee that the internet itself is 100%
                  secure. Although we will do our best to protect your personal
                  information, transmission of personal information to and from
                  our <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" /> is at your own
                  risk. You should only access the services within a secure
                  environment.
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="infominors" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      8. DO WE COLLECT INFORMATION FROM MINORS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      We do not knowingly collect data from or market to
                      children under 18 years of age.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  We do not knowingly solicit data from or market to children
                  under 18 years of age. By using the{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />, you represent
                  that you are at least 18 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent’s
                  use of the <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />. If we learn
                  that personal information from users less than 18 years of age
                  has been collected, we will deactivate the account and take
                  reasonable measures to promptly delete such data from our
                  records. If you become aware of any data we have collected
                  from children under age 18, please contact us at{" "}
                  <bdt className="block-component" />
                  <bdt className="question">info@merqah.com</bdt>
                  <bdt className="else-block" />.
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="privacyrights" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      9. WHAT ARE YOUR PRIVACY RIGHTS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <em>
                      <bdt className="block-component" />
                      You may review, change, or terminate your account at any
                      time.
                    </em>
                  </span>
                  <bdt className="block-component">
                    <span data-custom-class="body_text" />
                  </bdt>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    If you are resident in the European Economic Area and you
                    believe we are unlawfully processing your personal
                    information, you also have the right to complain to your
                    local data protection supervisory authority. You can find
                    their contact details here:
                  </span>
                </span>{" "}
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(48, 48, 241)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <a
                      data-custom-class="link"
                      href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                      target="_blank"
                    >
                      http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                    </a>
                    .
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <span data-custom-class="body_text" />
                  </bdt>
                  <bdt className="block-component" />
                </span>
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component">
                  <bdt className="block-component" />
                </bdt>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="DNT" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      10. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track (“DNT”) feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. No uniform technology
                    standard for recognizing and implementing DNT signals has
                    been finalized. As such, we do not currently respond to DNT
                    browser signals or any other mechanism that automatically
                    communicates your choice not to be tracked online. If a
                    standard for online tracking is adopted that we must follow
                    in the future, we will inform you about that practice in a
                    revised version of this{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        privacy notice
                        <bdt className="else-block" />
                      </span>
                    </span>
                    .
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <br />
            </p>
            <p id="caresidents" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      Yes, if you are a resident of California, you are granted
                      specific rights regarding access to your personal
                      information.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    California Civil Code Section 1798.83, also known as the
                    “Shine The Light” law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                  </span>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  If you are under 18 years of age, reside in California, and
                  have a registered account with the{" "}
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />, you have the
                  right to request removal of unwanted data that you publicly
                  post on the <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />. To request
                  removal of such data, please contact us using the contact
                  information provided below, and include the email address
                  associated with your account and a statement that you reside
                  in California. We will make sure the data is not publicly
                  displayed on the <bdt className="block-component" />
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                  Apps
                  <bdt className="statement-end-if-in-editor" />, but please be
                  aware that the data may not be completely or comprehensively
                  removed from our systems.
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p
              id="policyupdates"
              style={{ fontSize: "15px", lineHeight: "1.5" }}
            >
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      12. DO WE MAKE UPDATES TO THIS POLICY?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <strong>
                  <em>
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">In Short:</span>
                    </span>{" "}
                  </em>{" "}
                </strong>
                <span style={{ fontSize: "15px" }}>
                  <em>
                    <span data-custom-class="body_text">
                      Yes, we will update this policy as necessary to stay
                      compliant with relevant laws.
                    </span>
                  </em>
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  We may update this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>{" "}
                  </span>
                  from time to time. The updated version will be indicated by an
                  updated “Revised” date and the updated version will be
                  effective as soon as it is accessible. If we make material
                  changes to this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>
                  </span>
                  , we may notify you either by prominently posting a notice of
                  such changes or by directly sending you a notification. We
                  encourage you to review this{" "}
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      privacy notice
                      <bdt className="else-block" />
                    </span>{" "}
                  </span>
                  frequently to be informed of how we are protecting your
                  information.
                </span>
              </span>
            </p>
            <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <br />
              </span>
            </p>
            <p id="contact" style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span style={{ fontSize: "19px" }}>
                    <span data-custom-class="heading_1">
                      13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </span>
                  </span>
                </strong>
              </span>
            </p>
            <p style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  If you have questions or comments about this policy, you may{" "}
                  <bdt className="block-component" />
                  email us at{" "}
                  <bdt className="question">info@merqh.com</bdt>
                  <bdt className="statement-end-if-in-editor" /> or by post to:
                </span>
              </span>
            </p>
            <div>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="question">
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="body_text">merqah</span>
                  </span>{" "}
                </bdt>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="question">__________</bdt>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="question">__________</bdt>
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="question">Egypt</bdt>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span data-custom-class="body_text">
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <bdt className="else-block" />
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div>
              <bdt className="block-component" />
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n              ul {\n                list-style-type: square;\n              }\n      \n              ul>li>ul {\n                list-style-type: circle;\n              }\n      \n              ul>li>ul>li>ul {\n                list-style-type: square;\n              }\n      \n              ol li {\n                font-family: Arial;\n              }\n            ",
              }}
            />
          </div>
          <div style={{ padding: "16px 0 20px", background: "transparent" }}>
            <h1
              style={{
                fontFamily: "Arial",
                fontSize: "19px",
                color: "#000000",
                textTransform: "uppercase",
              }}
            >
              How can you review, update, or delete the data we collect from
              you?
            </h1>
            <div
              style={{
                color: "#595959",
                fontSize: "14px",
                fontFamily: "Arial",
                marginTop: "18px",
                lineHeight: "1.2",
              }}
            >
              Based on the laws of some countries, you may have the right to
              request access to the personal information we collect from you,
              change that information, or delete it in some circumstances. To
              request to review, update, or delete your personal information,
              please submit a request form by clicking{" "}
              <a
                style={{ color: "rgb(48, 48, 241) !important" }}
                href="https://app.termly.io/notify/f2117719-e930-49aa-ad2c-2742c52bff4e"
                target="_blank"
              >
                here
              </a>
              . We will respond to your request within 30 days.
            </div>
          </div>
          <div
            style={{
              color: "#595959",
              fontSize: "14px",
              fontFamily: "Arial",
              paddingTop: "16px",
              display: "none",
            }}
          >
            This privacy policy was created using{" "}
            <a
              style={{ color: "rgb(48, 48, 241) !important" }}
              href="https://termly.io/products/privacy-policy-generator/?ftseo"
            >
              Termly’s Privacy Policy Generator
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
