import { put, call, takeLatest, takeEvery, select } from "redux-saga/effects";
import Types from "../Actions/types";
import swal from "sweetalert";
import {
  forgotPasswordRequest,
  updateForgottenPassword,
} from "../../Services/Client";
import {
  forgotPasswordFinish,
  resetPasswordFailed,
  resetPasswordSuccess,
} from "../Actions";
import history from "../../Helpers/Navigations/history";

// (Start) Forgot Password
export function* handleForgotPasswordSaga({ payload }) {
  try {
    const response = yield call(forgotPasswordRequest, payload);
    if (!response.errors) {
      swal({
        title: "Success",
        text: response.data.forgotPassword.message,
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      yield put(forgotPasswordFinish());
      history.push("login");
    } else {
      swal({
        title: "error",
        text: response.errors[0]?.extensions?.reason,
        icon: "error",
        timer: 2000,
        buttons: false,
      });
      yield put(forgotPasswordFinish());
    }
  } catch (error) {
    swal({
      title: "error",
      text: error.message ? error.message : "Something went wrong!",
      icon: "error",
      timer: 2000,
      buttons: false,
    });
    yield put(forgotPasswordFinish());
  }
}

function* onForgotPasswordSaga() {
  yield takeEvery(Types.FORGOT_PASSWORD_ATTEMPT, handleForgotPasswordSaga);
}
// (End) Forgot Password

// (Start) Reset Password
export function* handleResetPasswordSaga({ payload }) {
  try {
    const response = yield call(updateForgottenPassword, payload);
    console.log("res", response);
    if (!response.errors) {
      yield put(resetPasswordSuccess());

      swal({
        title: "Success",
        text: response.data.updateForgottenPassword.message,
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      history.push("/login");
    } else {
      yield put(resetPasswordFailed());
      swal({
        title: "error",
        text: response.errors[0]?.extensions?.reason,
        icon: "error",
        timer: 2000,
        buttons: false,
      });
    }
  } catch (error) {
    console.log(
      "err",
      { error },
      Boolean(error.graphQLErrors[0].extensions.errors)
    );
    yield put(resetPasswordFailed());

    if (error.graphQLErrors.length) {
      if (Boolean(error.graphQLErrors[0].extensions?.validation)) {
        swal({
          title: "error",
          text:
            error.graphQLErrors[0].extensions?.validation["input.password"][0],
          icon: "error",
          timer: 2000,
          buttons: false,
        });
        return;
      }
      if (Boolean(error.graphQLErrors[0].extensions.errors)) {
        swal({
          title: "error",
          text: error.graphQLErrors[0].extensions.errors.token,
          icon: "error",
          timer: 2000,
          buttons: false,
        });
        return;
      }
      swal({
        title: "error",
        text: (error.message = null ?? "Something went wrong!"),
        icon: "error",
        timer: 2000,
        buttons: false,
      });
      return;
    }
    swal({
      title: "error",
      text: (error.message = null ?? "Something went wrong!"),
      icon: "error",
      timer: 2000,
      buttons: false,
    });
  }
}

function* onResetPasswordSaga() {
  yield takeEvery(Types.RESET_PASSWORD_ATTEMPT, handleResetPasswordSaga);
}
// (End) Reset Password

export default { onForgotPasswordSaga, onResetPasswordSaga };
