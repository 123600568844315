import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import {
  allUsersQuery,
  allDonorsQuery,
  donorsQuery,
  collectorProfileQuery,
  collectoionsListQuery,
  pickupsListQuery,
  pickupsQuery,
  donorQuery,
  donorPickupsListQuery,
  donorPickupsProfileQuery,
  collectedPickupQuery
} from "../Graphql/query";
import {
  upsertUserMutation,
  upsertDonorMutation,
  upsertSettleBalanceMutation,
  authUserMutation,
  upsertPickupMutation,
  cancelPickupMutation,
  forgotPasswordMutation,
  resetPasswordMutation,
  addBalanceMutation,
  editCollectionMutation,
  deleteBalanceMutation,
  deleteTransactionMutation
} from "../Graphql/mutation";
import Store from "../Store";
import { logoutAction } from "../Store/Actions";
import swal from "sweetalert";

import { Config } from "../Config";

// Instantiate required constructor fields
const cache = new InMemoryCache({ addTypename: false });
const link = createUploadLink({
  uri: `${Config.API_URL}/graphql`,
  // credentials: "include",
});
const authMiddleware = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  const { token } = Store.getState().auth;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const errorLink = new onError(({ graphQLErrors, networkError }) => {
  // console.log(graphQLErrors);
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.category) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case "authentication":
          Store.dispatch(logoutAction());
          swal({
            title: "Error",
            text: graphQLErrors[0].message ?? "Something went wrong!",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
          return;
        case "authorization":
          swal({
            title: "Error",
            text: graphQLErrors[0].message ?? "Something went wrong!",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
          return;
      }
    }
  }
});

export const client = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  link: from([authMiddleware, errorLink, link]),
  // Provide some optional constructor fields
  name: "merqah-web-client",
  connectToDevTools: false,
  version: "1.0",
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "none",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "none",
    },
  },
});

export const authUser = ({ user }) => {
  return client.mutate({
    mutation: authUserMutation,
    variables: {
      ...user,
    },
  });
};

// (Start) Forgot Password
export const forgotPasswordRequest = (email) =>
  client.mutate({
    mutation: forgotPasswordMutation,
    variables: {
      email,
    },
  });
// (End) Forgot Password

// (Start) Reset Password
export const updateForgottenPassword = ({
  email,
  token,
  password,
  password_confirmation,
}) =>
  client.mutate({
    mutation: resetPasswordMutation,
    variables: {
      email,
      token,
      password,
      password_confirmation,
    },
    errorPolicy: "none",
  });
// (End) Reset Password

export const fetchAllUsers = ({ allUsersList }) => {
  let input = {
    ids: allUsersList.ids,
    phone: allUsersList.phone,
    email: allUsersList.email,
    status_id: allUsersList.status_id,
    role_id: allUsersList.role_id,
  };
  return client.query({
    query: allUsersQuery,
    variables: {
      rows: allUsersList.pagination.perPage,
      page: allUsersList.pagination.currentPage,
      input,
      role_id: allUsersList.role_id,
      // field: allUsersList.sorting.key,
      // order: allUsersList.sorting.dir.toUpperCase(),
      // filter: allUsersList.filter,
    },
  });
};

export const upsertUser = ({ ...user }) => {
  return client.mutate({
    mutation: upsertUserMutation,
    variables: {
      input: user,
    },
  });
};

// export const fetchDonors = ({ allDonorsList }) => {
//   let input = {
//     name: allDonorsList.name,
//     phone: allDonorsList.phone,
//     city_id: allDonorsList.city_id,
//     area_id: allDonorsList.area_id,
//   };
//   return client.query({
//     query: donorsQuery,
//     variables: {
//       rows: allDonorsList.pagination.perPage,
//       page: allDonorsList.pagination.currentPage,
//       input,
//       // field: allUsersList.sorting.key,
//       // order: allUsersList.sorting.dir.toUpperCase(),
//       // filter: allUsersList.filter,
//     },
//   });
// };

export const fetchAllDonors = ({ allDonorsList }) => {
  let input = {
    name: allDonorsList.name,
    phone: allDonorsList.phone,
    city_id: allDonorsList.city_id,
    area_id: allDonorsList.area_id,
  };
  return client.query({
    query: allDonorsQuery,
    variables: {
      rows: allDonorsList.pagination.perPage,
      page: allDonorsList.pagination.currentPage,
      input,
      // field: allUsersList.sorting.key,
      // order: allUsersList.sorting.dir.toUpperCase(),
      // filter: allUsersList.filter,
    },
  });
};

export const fetchCollectorProfile = ({ collectionsList, id }) => {
  // console.log(collectionsList, id);
  let input = {
      name: collectionsList.name,
      donor_phone: collectionsList.phone,
      city_id: collectionsList.city_id,
      area_id: collectionsList.area_id,
      collector_id: id,
      status_id: "Collected",
    },
    column = collectionsList.sorting.column,
    order = collectionsList.sorting.order.toUpperCase();
  return client.query({
    query: collectorProfileQuery,
    variables: {
      rows: collectionsList.pagination.perPage,
      page: collectionsList.pagination.currentPage,
      id,
      input,
      order,
      column,
    },
  });
};

export const fetchCollectionsList = ({ collectionsList, id }) => {
  let input = {
      name: collectionsList.name,
      donor_phone: collectionsList.phone,
      city_id: collectionsList.city_id,
      area_id: collectionsList.area_id,
      collector_id: id,
      status_id: "Collected",
    },
    column = collectionsList.sorting.column,
    order = collectionsList.sorting.order.toUpperCase();
  return client.query({
    query: collectoionsListQuery,
    variables: {
      rows: collectionsList.pagination.perPage,
      page: collectionsList.pagination.currentPage,
      input,
      order,
      column,
    },
  });
};

export const fetchPickups = (allpickupsList) => {
  let input = {
      name: allpickupsList.name,
      donor_phone: allpickupsList.donor_phone,
      city_id: allpickupsList.city_id,
      area_id: allpickupsList.area_id,
      donor_id: allpickupsList.donor_id,
      collector_id: allpickupsList.collector_id,
      status_id: allpickupsList.status_id,
      time_range: allpickupsList.time_range,
      collection_date_from: allpickupsList.collection_date_from,
      collection_date_to: allpickupsList.collection_date_to,
      type_id: "Pickup",
    },
    column = allpickupsList.sorting.column,
    order = allpickupsList.sorting.order.toUpperCase();

  return client.query({
    query: pickupsQuery,
    variables: {
      rows: allpickupsList.pagination.perPage,
      page: allpickupsList.pagination.currentPage,
      input,
      column,
      order,
    },
  });
};
export const fetchPickupsList = ({ allpickupsList }) => {
  let input = {
      name: allpickupsList.name,
      donor_phone: allpickupsList.donor_phone,
      city_id: allpickupsList.city_id,
      area_id: allpickupsList.area_id,
      donor_id: allpickupsList.donor_id,
      collector_id: allpickupsList.collector_id,
      status_id: allpickupsList.status_id,
      time_range: allpickupsList.time_range,
      collection_date_from: allpickupsList.collection_date_from,
      collection_date_to: allpickupsList.collection_date_to,
      type_id: "Pickup",
    },
    column = allpickupsList.sorting.column,
    order = allpickupsList.sorting.order.toUpperCase();
  return client.query({
    query: pickupsListQuery,
    variables: {
      rows: allpickupsList.pagination.perPage,
      page: allpickupsList.pagination.currentPage,
      input,
      column,
      order,
    },
  });
};

// upsert donor
export const upsertDonor = ({ ...user }) => {
  return client.mutate({
    mutation: upsertDonorMutation,
    variables: {
      input: user,
    },
  });
};

export const fetchDonor = ({ id }) => {
  return client.query({
    query: donorQuery,
    variables: {
      id,
    },
  });
};

// fetch donor Profile
export const fetchDonorProfileView = ({ donorPickupsList, id }) => {
  let input = {
    name: donorPickupsList.name,
    city_id: donorPickupsList.city_id,
    area_id: donorPickupsList.area_id,
    donor_id: id,
    collector_id: donorPickupsList.collector_id,
    status_id: donorPickupsList.status_id,
    type_id: "Pickup",
  };
  return client.query({
    query: donorPickupsProfileQuery,
    variables: {
      rows: donorPickupsList.pagination.perPage,
      page: donorPickupsList.pagination.currentPage,
      input,
      id,
    },
  });
};

export const fetchDonorPickups = ({ donorPickupsList, id }) => {
  let input = {
    name: donorPickupsList.name,
    city_id: donorPickupsList.city_id,
    area_id: donorPickupsList.area_id,
    donor_id: id,
    collector_id: donorPickupsList.collector_id,
    status_id: donorPickupsList.status_id,
    type_id: "Pickup",
  };
  return client.query({
    query: donorPickupsListQuery,
    variables: {
      rows: donorPickupsList.pagination.perPage,
      page: donorPickupsList.pagination.currentPage,
      input,
    },
  });
};

// upsert Settle Balance
export const SettleCollectorBalance = ({ balance }) => {
  return client.mutate({
    mutation: upsertSettleBalanceMutation,
    variables: {
      input: balance,
    },
  });
};
//pick up
export const upsertPickup = ({ pickup }) => {
  // let pickupInput = { pickupDonor, ...pickupDonor };
  return client.mutate({
    mutation: upsertPickupMutation,
    variables: {
      input: pickup,
    },
  });
};

export const cancelPickup = ({ cancel }) => {
  return client.mutate({
    mutation: cancelPickupMutation,
    variables: {
      input: cancel,
    },
  });
};

export const fetchPickupDonor = ({ phone }) => {
  let input = {
    phone,
  };
  return client.query({
    query: donorsQuery,
    variables: {
      rows: 10,
      page: 1,
      input,
    },
  });
};

export const addCollectorBalance = ({ balance }) => {
  return client.mutate({
    mutation: addBalanceMutation,
    variables: {
      input: balance,
    },
  });
};

export const editPickupCollection = ({ collection }) => {
  return client.mutate({
    mutation: editCollectionMutation,
    variables: {
      input: collection,
    },
  });
};

export const deleteBalance = ({ id }) => {
  return client.mutate({
    mutation: deleteBalanceMutation,
    variables: {
      id,
    },
  });
};

export const deleteTransaction = ({ id }) => {
  return client.mutate({
    mutation: deleteTransactionMutation,
    variables: {
      id,
    },
  });
};

export const fetchCollectedPickup = ({ id }) => {
  return client.query({
    query: collectedPickupQuery,
    variables: {
      id,
    },
  });
};
