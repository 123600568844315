import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import {
  BSelect,
  DateTimePickerForm,
  InputForm,
  RadioboxForm,
  CheckboxBooleanForm
} from "../../Builder/Form";
import {
  fetchPickupDonorAction,
  dismissPickupFormAction,
  upsertPickupAction,
  onInputChangeAction,
  onInputResetAction,
} from "../../Store/Actions";
import AddDonor from "./AddDonor";
import AddPickupaddresses from "./AddPickupaddresses";
import AddIcon from "@material-ui/icons/Add";
import AddPickupAddressInput from "./AddPickupAddressInput";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
// import AddPickupAddressInput from "./AddPickupAddressInput";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router-dom";

const PickupForm = ({ pickupFormModal, ...props }) => {
  const formName = "pickupForm";
  const formNameValidation = "pickupFormValidation";
  const formServerValidation = "pickupFormServerValidation";

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { donorId } = useParams();

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, [pickupFormModal.isOpen]);

  const handleSubmitBtn = () => {
    const { donor, ...pickupData } = props.pickup;
    setIsSubmitting(true);
    if (Boolean(+props.isFormValid.length)) {
      console.log(props.isFormValid);
      return;
    }
    let inputPickup = { ...pickupData };
    props.upsertPickupAction(inputPickup, donorId);
  };
  const handleFilter = () => {
    props.fetchPickupDonorAction(props.data["pickupDonor"].phone);
  };
  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data["pickupDonor"].phone,
  ]);

  const handleNoteChange = (event) => {
    event.persist();
    props.onInputChangeAction(formName, event);
  };

  const handleResetContactDate = () => {
    props.onInputResetAction(formName, "contact_date");
  };
  const handleResetCollectDate = () => {
    props.onInputResetAction(formName, "collection_date");
  };

  return (
    <MainModal
      isOpen={pickupFormModal.isOpen}
      modalTitle={`${pickupFormModal.type} Pickup`}
      btnOnClick={handleSubmitBtn}
      btnOnCancelClick={() => props.dismissPickupFormAction()}
      btnSubmitLoading={pickupFormModal.isLoading}
    >
      <div>
        {!Boolean(+props.pickup?.donor_id) ? (
          <InputForm
            name="phone"
            formName="pickupDonor"
            formNameValidation={"pickupDonorValid"}
            formSubmitting={isSubmitting}
            label="Donor Phone Number"
            labelStyle="modal_label_style"
            containerStyle="mb-2"
            inputContainerStyle=""
            validateBy={
              !Boolean(+props.pickup?.donor_id) ? "textRequired" : null
            }
            autocomplete="off"
          />
        ) : null}

        <AddDonor
          donor_name={props.pickup?.donor?.name}
          donor_id={props.pickup?.donor_id}
          edit={pickupFormModal.type}
        />

        <RadioboxForm
          name="pickup_type_id"
          validationName={`input.pickup_type_id`}
          formName={formName}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          formSubmitting={isSubmitting}
          options={[
            { label: "Pickup", value: "1" },
            { label: "Drop-off", value: "2" },
          ]}
          validateBy="textRequired"
          containerStyle="d-flex"
          optionsContainerStyle="d-flex ml-3"
          label="Type"
          labelStyle="pt-1"
          // optionItemStyle="d-flex align-items-center"
          optionLabelStyle="mb-1 mr-3 font-weight-normal"
          optionInputStyle="mr-2 text-nowrap"
          optionLabelActiveStyle="font-weight-bolder"
        // type="checkbox"
        />

        <DateTimePickerForm
          name="contact_date"
          validationName={`input.contact_date`}
          formName={formName}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          formSubmitting={isSubmitting}
          label="contact at"
          labelStyle="modal_label_style"
          placeholderText="Time Range / From"
          containerStyle="py-0"
          datePickerContainer="w-100"
          inputStyle="date-picker-input-default"
          validateBy="textRequired"
          hasIcon={false}
          isClearable
          // dateTime
          // withPortal={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          //   navigator.userAgent
          // )}
          // withPortal={true}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
          minDate={new Date(new Date().getTime() + 3 * 60 * 60 * 1000)}
        />

        <DateTimePickerForm
          name="collection_date"
          validationName={`input.collection_date`}
          formName={formName}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          formSubmitting={isSubmitting}
          label="collect at"
          labelStyle="modal_label_style"
          placeholderText="Collection Date"
          containerStyle="my-2"
          datePickerContainer="w-100"
          inputStyle="date-picker-input-default"
          // withPortal={true}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
          // validateBy="textRequired"
          hasIcon={false}
          isClearable
          dateTime
          minDate={new Date(new Date().getTime() + 1 * 60 * 60 * 1000)}
        // showTimeInput
        // showTimeSelect={false}
        />

        <BSelect
          formName={formName}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          formSubmitting={isSubmitting}
          name="collector_id"
          validationName={`input.collector_id`}
          optionLabel="name"
          optionValue="id"
          options={props.collectorOptions ?? []}
          keepDefaultStyle
          inputContainerStyle="select-def-input-container"
          containerStyle="my-2"
          label="collector"
          labelStyle="modal_label_style"
          validateBy="textRequired"
        />

        <hr />

        {/* <AddPickupaddresses /> */}
        <div className="add_pickup_addresses_modal_wrapper_style">
          <div className="d-flex mb-3">
            <label>pickup addresses</label>
            {/* <button className="btn sucsses_btn_style ml-3" type="button">
              <AddIcon />
            </button> */}
          </div>

          {props.pickup?.donor?.addresses?.length > 0 ? (
            <RadioboxForm
              name="address_id"
              validationName={`input.address_id`}
              formName={formName}
              formNameValidation={formNameValidation}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              options={props.pickup?.donor?.addresses.map((address) => ({
                value: address.id,
                label: `${address.name} - ${address?.area?.name}, ${address?.area?.city?.name}`,
              }))}
              validateBy="textRequired"
              containerStyle=""
              labelStyle="d-flex mr-5"
              optionsContainerStyle="flex-column"
              optionItemStyle="d-flex align-items-center"
              optionLabelStyle="mb-3 mr-3"
              optionInputStyle="mr-2"
            // type="checkbox"
            />
          ) : null}

          {/* <AddPickupAddressInput /> */}
        </div>

        <hr />

          <div className="col-md-6">

            <CheckboxBooleanForm
              name="has_cash"
              validationName={`input.has_cash`}
              formName={formName}
              formNameValidation={formNameValidation}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              options={["Cash"]}
              type="checkbox"
            />
          </div>
          <div className="col-md-6">

            <CheckboxBooleanForm
              name="has_material"
              validationName={`input.has_material`}
              formName={formName}
              formNameValidation={formNameValidation}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              options={["Material"]}
              type="checkbox"
            />

          </div>


        <div className="d-flex flex-column">
          <label className="modal_label_style">Notes</label>
          <textarea
            rows="4"
            name="main_note"
            validationName={`input.main_note`}
            onChange={handleNoteChange}
            value={props.pickup.main_note}
          />
        </div>

        {/* (Start) Error Message */}
        {pickupFormModal.errorMessage && isSubmitting && (
          <div className="warnig-msg-style">{pickupFormModal.errorMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    pickup: state.super.pickupForm,
    pickupFormModal: state.super.pickupFormModal,
    isFormValid: state.super.pickupFormValidation,
    data: state.super,
    collectorOptions: state.super?.pickupsFiler?.collectorOptions,
  };
};

export default connect(mapStateToProps, {
  fetchPickupDonorAction,
  dismissPickupFormAction,
  upsertPickupAction,
  onInputChangeAction,
  onInputResetAction,
})(PickupForm);
