import React from "react";
import { connect } from "react-redux";
import HasPrivileges from "../../Helpers/HelperFns/Roles/HasPrivileges";
import { Privileges } from "../../Helpers/HelperFns/Roles/Privileges";
import { showUserFormAction } from "../../Store/Actions";

const CollectorInfoCard = ({ name, email, phone, id, role, ...props }) => {
  const handleEditCollector = () => {
    let collector = {
      name,
      email,
      phone,
      id,
      role_id: role.id,
    };
    props.showUserFormAction("Edit", collector);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="mb-0">{name}</h1>
        </div>

        <HasPrivileges can={[Privileges.CAN_EDIT_COLLECTOR]}>
          <div>
            <button
              className="btn edit_btn_style"
              type="button"
              onClick={handleEditCollector}
            >
              Edit
            </button>
          </div>
        </HasPrivileges>

      </div>

      <div>
        <p>{phone}</p>
      </div>

      <div>
        <p>{email}</p>
      </div>

      <hr />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.userProfile,

  };
};

export default connect(mapStateToProps, { showUserFormAction })(CollectorInfoCard);
