import React from "react";
import { Table } from "reactstrap";

const CurrentBalance = (props) => {
  return (
    <>
      <Table className="mb-2" bordered responsive>
        <thead>
          <tr>
            {props?.current_balance_data?.map((currency) => (
              <th key={currency?.currency_name}>{currency?.currency_name}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            {props?.current_balance_data?.map((currency) => (
              <td key={currency?.amount}>{currency?.amount}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default CurrentBalance;
