import React from "react";
import { Table } from "reactstrap";

const TotalDonations = (props) => {
  return (
    <>
      <Table className="mb-2" bordered responsive>
        <thead>
          <tr>
            {props?.total_donations_data?.map((total) => (
              <th key={total?.currency_name}>{total?.currency_name}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            {props?.total_donations_data?.map((total) => (
              <td key={total.amount}>{total?.amount}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default TotalDonations;
