import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm } from "../../Builder/Form";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import {
  dismissAddBalanceFormAction,
  onBalanceInputChange,
  addBalanceInput,
  removeBalanceInput,
  addCollectorBalanceAction,
  deleteTransactionAction,
} from "../../Store/Actions";

import Const from "../../Constants";

import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader";

const AddBalanceForm = ({ addBalanceFormModal, ...props }) => {
  const formName = "addBalanceForm";
  const formNameValidation = "addBalanceFormValidation";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formServerValidation = "addBalanceFormServerValidation";

  const { collectorId } = useParams();

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, [addBalanceFormModal.isOpen]);

  const handleBalanceInputChange = (index, e) => {
    props.onBalanceInputChange(formName, index, e.target.name, e.target.value);
  };

  const handleBalanceSelectChange = (index, val, e) => {
    let value = val ? val?.id : "";
    props.onBalanceInputChange(formName, index, e.name, value);
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.isFormValid.length) {
      return;
    } else {
      props.addCollectorBalanceAction({
        ...props.addBalance,
        transactions: props.addBalance.transactions.map(
          ({ amount, ...transaction }) => ({
            ...transaction,
            amount: Number(amount),
          })
        ),
      });
    }
  };
  return (
    <MainModal
      isOpen={addBalanceFormModal.isOpen}
      modalTitle={`add Balance`}
      btnOnClick={props.isDeleteTransaction ? null : handleSubmitBtn}
      btnOnCancelClick={
        props.isDeleteTransaction
          ? null
          : () => props.dismissAddBalanceFormAction()
      }
      btnSubmitLoading={addBalanceFormModal.isLoading}
    >
      {props.isDeleteTransaction ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="settle_balance_wrapper_style">
            {props.addBalance?.transactions?.map((balance, i) => (
              <div key={`add_balance_${i}`} className="row mb-2">
                <div className="col-12 col-xl-6">
                  <div className="d-flex">
                    <div className="w-100 mr-3">
                      <InputForm
                        name="amount"
                        validationName={`input.transactions.${i}.amount`}
                        formName={formName}
                        formNameValidation={formNameValidation}
                        formServerValidation={formServerValidation}
                        formSubmitting={isSubmitting}
                        label="amount added"
                        labelStyle="modal_label_style"
                        containerStyle="mb-2"
                        inputContainerStyle=""
                        validateBy="textRequired"
                        value={balance.amount}
                        onChange={(e) => handleBalanceInputChange(i, e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-6 d-flex">
                  <div className="w-100">
                    <BSelect
                      formName={formName}
                      formNameValidation={formNameValidation}
                      formSubmitting={isSubmitting}
                      name="currency_id"
                      formServerValidation={formServerValidation}
                      validationName={`input.transactions.${i}.currency_id`}
                      optionLabel="name"
                      optionValue="id"
                      options={Const.CurrencyOptions}
                      keepDefaultStyle
                      inputContainerStyle="select-def-input-container"
                      containerStyle="mb-2"
                      label="currency"
                      labelStyle="modal_label_style"
                      validateBy="textRequired"
                      value={balance.currency_id}
                      onChange={(val, e) =>
                        handleBalanceSelectChange(i, val, e)
                      }
                      isMulti={false}
                    />
                  </div>

                  {addBalanceFormModal.isEdit ? (
                    props.addBalance?.transactions?.length > 1 ? (
                      <div key={i} className="btns_wrapper_style">
                        <button
                          disabled={addBalanceFormModal.isLoading}
                          className="btn delete_btn_style"
                          type="button"
                          onClick={() =>
                            props.deleteTransactionAction(
                              balance.id,
                              collectorId
                            )
                          }
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    ) : null
                  ) : (
                    <div className="btns_wrapper_style">
                      {i == 0 ? (
                        <button
                          className="btn sucsses_btn_style"
                          type="button"
                          onClick={() => props.addBalanceInput()}
                        >
                          <AddIcon />
                        </button>
                      ) : (
                        <button
                          className="btn delete_btn_style"
                          type="button"
                          onClick={() => props.removeBalanceInput(i)}
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}

            {/* (Start) Error Message */}
            {props.serverValid && isSubmitting && (
              <div className="warnig-msg-style">
                {props.serverValid["input.transactions"] ?? null}
              </div>
            )}
            {/* (End) Error Message */}
          </div>
        </>
      )}
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    addBalance: state.super.addBalanceForm,
    addBalanceFormModal: state.super.addBalanceFormModal,
    isFormValid: state.super.addBalanceFormValidation,
    serverValid: state.super.addBalanceFormServerValidation,
    isDeleteTransaction: state.super.deleteTransactionLoading,
  };
};

export default connect(mapStateToProps, {
  dismissAddBalanceFormAction,
  onBalanceInputChange,
  addBalanceInput,
  removeBalanceInput,
  addCollectorBalanceAction,
  deleteTransactionAction,
})(AddBalanceForm);
