import React from "react";
import { connect } from "react-redux";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CurrentBalance from "../../Components/CurrentBalance";
import CollectorInfoCard from "../../Components/CollectorInfoCard";
import {
  showSettleBalanceFormAction,
  showAddBalanceFormAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HelperFns/Roles/HasPrivileges";
import { Privileges } from "../../Helpers/HelperFns/Roles/Privileges";

const CollectorProfile = (props) => {
  // if (props.collectorProfile.isLoading) {
  //   return (
  //     <div className="loader_wrapper_style">
  //       <Loader />
  //     </div>
  //   );
  // }
  const handleSettleBalance = () => {
    let balanceForm = {
      id: "",
      collector_id: props.collectorProfile.id,
      type_id: "Settle_Balance",
      transactions: props.collectorProfile.balances.map(({ currency_id }) => ({
        id: "",
        amount: "",
        currency_id,
      })),
    };
    props.showSettleBalanceFormAction("settle", balanceForm);
  };
  return (
    <div className="collector_profile_wrapper_style my-2">
      <div className="card_wrapper_style pt-4 px-3 px-md-4">
        <CollectorInfoCard {...props.collectorProfile} />

        <div className="d-flex justify-content-between flex-column flex-md-row">
          <HasPrivileges can={[Privileges.CAN_VIEW_COLLECTORS_BALANCE]}>

            <div>
              <h2 className="mb-2">current balance</h2>
              <CurrentBalance
                current_balance_data={props.collectorProfile?.balances}
              />
            </div>
          </HasPrivileges>

          <div>

            <HasPrivileges can={[Privileges.CAN_ADD_BALANCE]}>

              <button
                className="btn mb-1 mb-md-0 mt-md-1 mr-2 add_balance_btn_style"
                type="button"
                onClick={() =>
                  props.showAddBalanceFormAction(props.collectorProfile.id)
                }
              >
                add balance
              </button>
            </HasPrivileges>

            <HasPrivileges can={[Privileges.CAN_SETTLE_BALANCE]}>
              <button
                className="btn mb-1 mb-md-0 mt-md-1 settle_balance_btn_style"
                type="button"
                onClick={handleSettleBalance}
              >
                settle balance
              </button>
            </HasPrivileges>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collectorProfile: state.super.selectedCollectorProfile,
    auth: state.auth.userProfile
  };
};

export default connect(mapStateToProps, {
  showSettleBalanceFormAction,
  showAddBalanceFormAction,
})(CollectorProfile);
