import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { InputForm } from "../../Builder/Form";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

const AddPickupAddressInput = (props) => {
  const formName = "pickupAddressForm";
  const formNameValidation = "pickupAddressValidation";
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, []);

  return (
    <div className="d-flex pickupaddress_input_wrapper_style">
      <InputForm
        name="new_pickup_address"
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={isSubmitting}
        labelStyle="modal_label_style"
        containerStyle="mb-2"
        inputContainerStyle=""
        validateBy="textRequired"
      />

      <div className="d-flex ml-5">
        <div>
          <button className="btn sucsses_btn_style mr-2" type="button">
            <CheckIcon />
          </button>
        </div>

        <div>
          <button className="btn delete_btn_style" type="button">
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pickupAddressForm: state.super.pickupAddressForm,
    isFormValid: state.super.pickupAddressForm,
  };
};

export default connect(mapStateToProps)(AddPickupAddressInput);
