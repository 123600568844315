import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm, DateTimePickerForm } from "../../Builder/Form";
import { dismissUserFormAction, upsertUserAction } from "../../Store/Actions";
import { useLocation } from "react-router-dom";

import Const from "../../Constants";

const UserForm = ({ userFormModal, ...props }) => {
  const formName = "userForm";
  const formNameValidation = "userFormValidation";
  const { pathname } = useLocation();
  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.isFormValid.length) {
      return;
    } else {
      props.upsertUserAction(props.user, pathname);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, [userFormModal.isOpen]);
  return (
    <MainModal
      isOpen={userFormModal.isOpen}
      modalTitle={`${userFormModal.type} User`}
      btnOnClick={handleSubmitBtn}
      btnOnCancelClick={() => props.dismissUserFormAction()}
      btnSubmitLoading={userFormModal.isLoading}
    >
      <div>
        <InputForm
          name="name"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          placeholder="Name"
          label="Name"
          labelStyle="modal_label_style"
          containerStyle="mb-2"
          inputContainerStyle=""
          validateBy="textRequired"
        />
        <InputForm
          name="email"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          placeholder="Email"
          label="Email"
          labelStyle="modal_label_style"
          containerStyle="mb-2"
          inputContainerStyle=""
          validateBy="textRequired"
        />
        <BSelect
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          name="role_id"
          optionLabel="label"
          optionValue="value"
          options={Const.UserRolesOptions}
          placeholder="Select Role"
          keepDefaultStyle
          inputContainerStyle="select-def-input-container"
          containerStyle="mb-2"
          label="Role"
          labelStyle="modal_label_style"
          validateBy="textRequired"
        />

        <InputForm
          name="phone"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          placeholder="Phone"
          label="Phone"
          labelStyle="modal_label_style"
          containerStyle="mb-2"
          inputContainerStyle=""
          validateBy="textRequired"
        />
        {/* (Start) Error Message */}
        {userFormModal.errorMessage && isSubmitting && (
          <div className="warnig-msg-style">{userFormModal.errorMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.super.userForm,
    userFormModal: state.super.userFormModal,
    isFormValid: state.super.userFormValidation,
  };
};

export default connect(mapStateToProps, {
  dismissUserFormAction,
  upsertUserAction,
})(UserForm);
