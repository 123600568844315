import React, { useEffect, useState } from "react";
import {
  faPencilAlt,
  faTrash,
  faPowerOff,
  faPlay,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

import { Link, useRouteMatch, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Store from "../../Store";

import CollectionSearchCard from "../../Components/CollectionSearchCard";
import Pagination from "../../Components/Pagination";
import {
  fetchCollectionsListAction,
  setDataTableSortingAction,
  onInputResetAction,
  showSettleBalanceFormAction,
  showEditAddBalanceFormAction,
  showPickupCollectionFormAction,
  cancelPickupAction,
  deleteBalanceAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import moment from "moment";
import Loader from "../../Components/Loader";
import PickupModal from "../../Components/PickupModal";
import Tooltip from "@material-ui/core/Tooltip";

import { default as sweet } from "sweetalert";
import swal from "sweetalert2";
import HasPrivileges, { useCheckPrivileges } from "../../Helpers/HelperFns/Roles/HasPrivileges";
import { Privileges } from "../../Helpers/HelperFns/Roles/Privileges";

const CollectionsList = (props) => {
  const columns = [
    {
      name: "collection ID",
      wrap: true,
      selector: "name",
      sortable: true,
      grow: 0.75,
      cell: (row) =>
        <HasPrivileges can={[Privileges.CAN_VIEW_TRANSACTION_AUDITS]} altChildren={<span>{row?.name}</span>}>
          <span role="button" onClick={() => showPickupModal(row)}>
            {row?.name}
          </span>
        </HasPrivileges>
    },
    {
      name: "donor",
      wrap: true,
      selector: "donor",
      sortable: false,
      grow: 1,
      cell: (row) => `${row?.donor?.name ?? ""}`,
    },
    {
      name: "area",
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => `${row?.address?.area?.name ?? ""} `,
    },
    {
      name: "city",
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => `${row?.address?.area?.city?.name ?? ""} `,
    },
    {
      name: "collected date",
      wrap: true,
      selector: "collection_date",
      sortable: true,
      grow: 1,
      cell: (row) =>
        Boolean(row?.collection_date)
          ? moment(row?.collection_date).format("DD-MM-yyyy hh:mm a")
          : "",
    },

    {
      name: "amount",
      wrap: true,
      selector: "amount",
      sortable: false,
      grow: 0.7,
      cell: (row) => (
        <div className="row align-items-center flex-grow-1 justify-content-between">
          <div className={row?.has_cash ? " " : "d-none"}>
            {row?.transactions.map((transaction) => (
              <div
                key={transaction?.amount}
                className={
                  (row?.type?.id == "1" && row?.pickupType?.id == "1") ||
                    row?.type?.id == "3"
                    ? "deposit_amount_status_style"
                    : "withdraw_amount_status_style"
                }
              >
                {Math.abs(transaction?.amount)} {transaction?.currency?.name}
              </div>
            ))}
          </div>

          <div className={row?.has_material ? " " : "d-none"}>
            <Tooltip
              classes={{ popper: "custom_tooltip_wrapper_style" }}
              title={row?.material_note}
              placement="left"
              arrow
            >
              <span className="btn">
                <FontAwesomeIcon
                  icon={faClipboard}
                  color="#d2b486"
                ></FontAwesomeIcon>
              </span>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      name: "balance",
      wrap: true,
      selector: "balance",
      sortable: false,
      grow: 0.7,
      omit: !useCheckPrivileges({ can: [Privileges.CAN_VIEW_TRANSACTION_BALANCE] }),
      cell: (row) => (
        <div>
          <HasPrivileges can={[Privileges.CAN_VIEW_TRANSACTION_BALANCE]}>

            {row?.transactions.map((transaction) => (
              <div key={transaction?.balance}>
                {transaction?.balance} {transaction?.currency?.name}
              </div>
            ))}
          </HasPrivileges>
        </div>
      ),
    },
    {
      name: "transaction date",
      wrap: true,
      selector: "created_at",
      sortable: false,
      grow: 1,
      cell: (row) =>
        Boolean(row?.created_at)
          ? moment(row?.created_at).format("DD-MM-yyyy hh:mm a")
          : "",
    },
    {
      name: "Actions",
      wrap: true,
      selector: "action",
      omit: !useCheckPrivileges({ can: [Privileges.CAN_EDIT_TRANSATION, Privileges.CAN_DELETE_TRANSATION] }),
      // right: true,
      grow: 0.5,
      cell: (row) => (

        <div className="general-data-table-action">
          <HasPrivileges can={[Privileges.CAN_EDIT_TRANSATION]} >
            <span className="btn" onClick={() => handleEditCollection(row)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </span>
          </HasPrivileges>
          <HasPrivileges can={[Privileges.CAN_DELETE_TRANSATION]} >
            <span className="btn" onClick={() => handleRemoveCollection(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </span>
          </HasPrivileges>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];
  const { collectorId } = useParams();

  // handle edit collection
  const handleEditCollection = (collection) => {
    switch (collection.name) {
      case "Settle Balance":
        let settleBalanceForm = {
          id: collection?.id,
          collector_id: collectorId,
          type_id: "Settle_Balance",
          transactions: collection?.transactions.map(
            ({ id, amount, currency }) => ({
              id,
              amount: Math.abs(amount),
              currency_id: currency.id,
            })
          ),
        };
        props.showSettleBalanceFormAction("edit settle", settleBalanceForm);

        return;
      case "Add Balance":
        let addBalanceForm = {
          id: collection?.id,
          collector_id: collectorId,
          type_id: "Add_Balance",
          transactions: collection?.transactions.map(
            ({ id, amount, currency }) => ({
              id,
              amount,
              currency_id: currency.id,
            })
          ),
        };
        props.showEditAddBalanceFormAction(addBalanceForm);

        return;

      default:
        let pickupCollection = {
          id: collection?.id,
          collector_id: collectorId,
          type_id: "Pickup",
          has_cash: collection.has_cash,
          has_material: collection.has_material,
          material_note: collection.material_note,
          transactions: collection?.transactions.map(
            ({ id, amount, currency }) => ({
              id,
              amount,
              currency_id: currency.id,
            })
          ),
        };
        props.showPickupCollectionFormAction(collection.name, pickupCollection);
        return;
    }
  };
  // handle remove collection
  const handleRemoveCollection = (collection) => {
    switch (collection.name) {
      case "Settle Balance":
      case "Add Balance":
        swal
          .fire({
            title: `Delete ${collection.name} \n Are you sure ? `,
            text: "You won't be able to revert this !",
            icon: "warning",
            showCancelButton: true,
            dangerMode: true,
            buttons: true,
            reverseButtons: true,
            confirmButtonText: "Yes, cancel it",
            cancelButtonText: "No, keep it",
          })

          .then((res) => {
            if (res.isConfirmed) {
              props.deleteBalanceAction(collection.id, collectorId);
            }
          });
        return;

      default:
        handleCancelPickup(collection.id, collection.name);
    }
  };

  const handleCancelPickup = (id, name) => {
    swal
      .fire({
        title: `cancel ${name} \n Are you sure ? `,
        text: "You won't be able to revert this !",
        icon: "warning",
        input: "textarea",
        inputPlaceholder: "Cancelation note",
        showCancelButton: true,
        dangerMode: true,
        buttons: true,
        reverseButtons: true,
        confirmButtonText: "Yes, cancel it",
        cancelButtonText: "No, keep it",
        preConfirm: function (value) {
          if (value.length) {
            return value;
          }
          sweet({
            title: "cancelation note is required",
            text: " ",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
          return false;
        },
      })
      .then((res) => {
        if (res.isConfirmed) {
          props.cancelPickupAction(
            { id, cancellation_note: res.value },
            collectorId
          );
        }
      });
  };

  // Toggle function for pickup Modal
  const [pickupModal, setPickupModal] = useState({
    isOpen: false,
    pickupLogs: [],
    name: "",
  });
  const dismissPickupModal = () => {
    if (pickupModal.isOpen) {
      setPickupModal({
        isOpen: false,
        pickupLogs: [],
        name: "",
      });
    }
  };

  const showPickupModal = (pickup) => {
    setPickupModal({
      isOpen: true,
      pickupLogs: pickup.logs,
      name: pickup.name,
    });
  };
  const dataTableRef = "collectionsList";
  useEffect(() => {
    return () => {
      props.onInputResetAction(dataTableRef, "name");
      props.onInputResetAction(dataTableRef, "phone");
      props.onInputResetAction(dataTableRef, "city_id");
      props.onInputResetAction(dataTableRef, "area_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchCollectionsListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
      id: collectorId,
    });
  };
  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].name,
    props.data[dataTableRef].phone,
    props.data[dataTableRef].city_id,
    props.data[dataTableRef].area_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchCollectionsListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
      id: collectorId,
    });
  };

  const handleSorting = (field, order) => {
    props.fetchCollectionsListAction({
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
        sorting: {
          order,
          column: field.selector,
        },
      },
      ref: dataTableRef,
      id: collectorId,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, order);
  };
  return (
    <div className="collector_profile_wrapper_style">
      <PickupModal
        ModalIsOpen={pickupModal.isOpen}
        toggleModal={dismissPickupModal}
        pickup_name={pickupModal.name}
        pickup_timeline_data={pickupModal.pickupLogs}
      />
      <CollectionSearchCard formName={dataTableRef} />
      <div className="mt-3">
        <DataTable
          className="general-data-list collector_datatable_wrapper_style"
          columns={columns}
          data={props.data[dataTableRef]?.data}
          selectableRows
          selectableRowsHighlight
          selectableRowsNoSelectAll
          noHeader
          persistTableHead
          sortServer
          onSort={handleSorting}
          defaultSortAsc={props.data[dataTableRef].sorting.order == "asc"}
          defaultSortField={props.data[dataTableRef].sorting.column}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={
            props.data[dataTableRef].isLoading ||
            props.isDeleteLoading ||
            props.collectorProfile.isLoading
          }
          progressComponent={<Loader />}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    user: state.auth.userProfile,
    isDeleteLoading: state.super.isDeleteBalaceLoading,
    collectorProfile: state.super.selectedCollectorProfile,
  };
};

export default connect(mapStateToProps, {
  fetchCollectionsListAction,
  setDataTableSortingAction,
  onInputResetAction,
  showSettleBalanceFormAction,
  showEditAddBalanceFormAction,
  showPickupCollectionFormAction,
  cancelPickupAction,
  deleteBalanceAction,
})(CollectionsList);
