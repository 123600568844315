export const Config = {
  // Production Env

  // API_URL: "https://merqah-donations-testing.qurtobasolutions.com",
  // Client_Secret:"",
  // Client_Id:2,

  // Testing Env

  API_URL: process.env.REACT_APP_API_URL,

  // Client_Secret:"",
  // Client_Id:,

  //Local Env

  // API_URL: "http://127.0.0.1:8000",
  // Client_Secret: "pFk1D9sfYawCJJuZEsvvEzDO2WhU7GfPnDZUmG18",
  // Client_Id: 4,
};
