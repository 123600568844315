import gql from "graphql-tag";
import {
  paginationFragment,
  userFragment,
  donorFragment,
  collectionFragment,
  collectorFragment,
  pickupFragment,
  donorProfileFragment,
  donorPickupFragment,
  pickupDonorFragment,
} from "../fragments";

export const allUsersQuery = gql`
  query allUsers(
    $rows: Int
    $page: Int
    $input: UserFilterInput
    $role_id: RolesOptions
  ) {
    users(first: $rows, page: $page, input: $input) {
      data {
        ...userData
      }
      paginatorInfo {
        ...pagination
      }
    }
    usersFilter: users(input: { role_id: $role_id }) {
      data {
        id
        name
      }
    }
  }
  ${paginationFragment}
  ${userFragment}
`;

export const donorsQuery = gql`
  query allDonors($rows: Int, $page: Int, $input: UserFilterInput) {
    donors(first: $rows, page: $page, input: $input) {
      data {
        ...pickupDonorData
      }
      paginatorInfo {
        ...pagination
      }
    }
  }
  ${paginationFragment}
  ${pickupDonorFragment}
`;
export const allDonorsQuery = gql`
  query allDonors($rows: Int, $page: Int, $input: UserFilterInput) {
    donors(first: $rows, page: $page, input: $input) {
      data {
        ...donorData
      }
      paginatorInfo {
        ...pagination
      }
    }
  }
  ${paginationFragment}
  ${donorFragment}
`;

export const collectorProfileQuery = gql`
  query collectorProfile(
    $rows: Int
    $page: Int
    $id: ID
    $input: PaymentsFilterInput
    $column: String!
    $order: SortOrder!
  ) {
    user(id: $id) {
      ...collectorData
    }
    collections: payments(
      first: $rows
      page: $page
      input: $input
      orderBy: { column: $column, order: $order }
    ) {
      data {
        ...collectionData
        logs {
          created_at
          id
          operation
          description
        }
      }
      paginatorInfo {
        ...pagination
      }
    }
  }
  ${paginationFragment}
  ${collectionFragment}
  ${collectorFragment}
`;

export const collectoionsListQuery = gql`
  query collectionsList(
    $rows: Int
    $page: Int
    $input: PaymentsFilterInput
    $column: String!
    $order: SortOrder!
  ) {
    collections: payments(
      first: $rows
      page: $page
      input: $input
      orderBy: { column: $column, order: $order }
    ) {
      data {
        ...collectionData
        logs {
          created_at
          id
          operation
          description
        }
      }
      paginatorInfo {
        ...pagination
      }
    }
  }
  ${paginationFragment}
  ${collectionFragment}
`;

export const pickupsQuery = gql`
  query pickups(
    $rows: Int
    $page: Int
    $input: PaymentsFilterInput
    $column: String!
    $order: SortOrder!
  ) {
    pickups: payments(
      first: $rows
      page: $page
      input: $input
      orderBy: { column: $column, order: $order }
    ) {
      data {
        ...pickupData
        logs {
          created_at
          id
          operation
          description
        }
      }
      paginatorInfo {
        ...pagination
      }
    }

    donorsFilter: donors {
      data {
        id
        name
      }
    }
    collectorsFilter: users(input: { role_id: Collector }) {
      data {
        id
        name
      }
    }
  }
  ${paginationFragment}
  ${pickupFragment}
`;
export const pickupsListQuery = gql`
  query pickupsList(
    $rows: Int
    $page: Int
    $input: PaymentsFilterInput
    $column: String!
    $order: SortOrder!
  ) {
    pickups: payments(
      first: $rows
      page: $page
      input: $input
      orderBy: { column: $column, order: $order }
    ) {
      data {
        ...pickupData
        logs {
          created_at
          id
          operation
          description
        }
      }
      paginatorInfo {
        ...pagination
      }
    }

    donorsFilter: donors {
      data {
        id
        name
      }
    }
    collectorsFilter: users(input: { role_id: Collector }) {
      data {
        id
        name
      }
    }
  }
  ${paginationFragment}
  ${pickupFragment}
`;

export const donorQuery = gql`
  query donor($id: ID) {
    donor(id: $id) {
      ...donorProfile
      addresses {
        id
        name
        map_link
        area {
          id
          name
          city {
            id
            name
          }
        }
      }
    }
  }
  ${donorProfileFragment}
`;
export const donorPickupsProfileQuery = gql`
  query pickupsList(
    $rows: Int
    $page: Int
    $input: PaymentsFilterInput
    $id: ID
  ) {
    donorPickups: payments(first: $rows, page: $page, input: $input) {
      data {
        ...donorPickupData
        logs {
          created_at
          id
          operation
          description
        }
      }
      paginatorInfo {
        ...pagination
      }
    }
    donor(id: $id) {
      ...donorProfile
      addresses {
        id
        name
        map_link
        area {
          id
          name
          city {
            id
            name
          }
        }
      }
    }
    collectorsFilter: users(input: { role_id: Collector }) {
      data {
        id
        name
      }
    }
  }

  ${donorProfileFragment}

  ${paginationFragment}
  ${donorPickupFragment}
`;
export const donorPickupsListQuery = gql`
  query pickupsList($rows: Int, $page: Int, $input: PaymentsFilterInput) {
    donorPickups: payments(first: $rows, page: $page, input: $input) {
      data {
        ...donorPickupData
        logs {
          created_at
          id
          operation
          description
        }
      }
      paginatorInfo {
        ...pagination
      }
    }
  }

  ${paginationFragment}
  ${donorPickupFragment}
`;

export const collectedPickupQuery = gql`
  query collectedPickup($id: ID) {
    payment(id: $id) {
      id
      name
      collector {
        id
      }
      has_material
      has_cash
      material_note
      transactions {
        id
        amount
        currency {
          id
        }
      }
    }
  }
`;
