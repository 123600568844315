import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm, CheckboxBooleanForm } from "../../Builder/Form";

import {
  dismissPickupCollectionFormAction,
  onInputChangeAction,
  onBalanceInputChange,
  editCollectionPickupAction,
  onInputResetAction,
  deleteTransactionAction,
} from "../../Store/Actions";
import CloseIcon from "@material-ui/icons/Close";

import Const from "../../Constants";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Loader from "../../Components/Loader";

import { useParams } from "react-router-dom";

const EditPickupCollection = ({ modalData, ...props }) => {
  const formName = "editPickupCollection";
  const formNameValidation = "editPickupCollectionValidation";
  const formServerValidation = "editPickupCollectionServerValidation";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [canEditCash, setCanEditCash] = useState(true);

  const { collectorId } = useParams();

  useEffect(() => {
    setCanEditCash(Boolean(props.pickupCollection?.has_cash));
    return () => {
      setIsSubmitting(false);
      setCanEditCash(true);
    };
  }, [modalData.isOpen]);

  const handleUpdateMaterialNote = () => {
    if (!Boolean(props.pickupCollection?.has_material)) {
      props.onInputResetAction(formName, "material_note");
    }
  };

  const onHasMaterialUpdtae = useDidUpdateEffect(handleUpdateMaterialNote, [
    props.pickupCollection?.has_material,
  ]);

  const handleAmountInputChange = (index, e) => {
    props.onBalanceInputChange(formName, index, e.target.name, e.target.value);
  };

  const handleAmountSelectChange = (index, val, e) => {
    let value = val ? val?.id : "";
    props.onBalanceInputChange(formName, index, e.name, value);
  };
  const handleNoteChange = (event) => {
    props.onInputChangeAction(formName, event);
  };
  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.isFormValid.length) {
      return;
    } else {
      props.editCollectionPickupAction({
        ...props.pickupCollection,
        transactions: props.pickupCollection.transactions.map(
          ({ amount, ...transaction }) => ({
            ...transaction,
            amount: Number(amount),
          })
        ),
      });
    }
  };
  return (
    <MainModal
      isOpen={modalData.isOpen}
      modalTitle={`edit pickup/drop-off - ${modalData?.name} `}
      btnOnClick={props.isDeleteTransaction ? null : handleSubmitBtn}
      btnOnCancelClick={
        props.isDeleteTransaction
          ? null
          : () => props.dismissPickupCollectionFormAction()
      }
      btnSubmitLoading={modalData.isLoading}
    >
      {props.isDeleteTransaction ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="edit-collection">
            <div className={!canEditCash ? "d-none" : " "}>
              <CheckboxBooleanForm
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={isSubmitting}
                formServerValidation={formServerValidation}
                name="has_cash"
                validationName={`input.has_cash`}
                options={["cash"]}
                type="checkbox"
              />
              {props.pickupCollection?.transactions?.map((balance, i) => (
                <div key={`add_balance_${i}`} className="row mb-2">
                  <div className="col-12 col-xl-6">
                    <div className="d-flex">
                      <div className="mr-3 w-100">
                        <BSelect
                          formName={formName}
                          formNameValidation={formNameValidation}
                          formSubmitting={isSubmitting}
                          name="currency_id"
                          dependancyType="equal"
                          dependancyValue={[1, "1"]}
                          dependOn="has_cash"
                          formServerValidation={formServerValidation}
                          validationName={`input.transactions.${i}.currency_id`}
                          optionLabel="name"
                          optionValue="id"
                          options={Const.CurrencyOptions}
                          keepDefaultStyle
                          inputContainerStyle="select-def-input-container"
                          containerStyle="mb-2"
                          label="currency"
                          labelStyle="modal_label_style"
                          validateBy="textRequired"
                          value={balance.currency_id}
                          onChange={(val, e) =>
                            handleAmountSelectChange(i, val, e)
                          }
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 d-flex">
                    <div className="w-100">
                      <InputForm
                        name="amount"
                        validationName={`input.transactions.${i}.amount`}
                        formName={formName}
                        formNameValidation={formNameValidation}
                        formServerValidation={formServerValidation}
                        formSubmitting={isSubmitting}
                        dependancyType="equal"
                        dependancyValue={[1, "1"]}
                        dependOn="has_cash"
                        label="amount"
                        containerStyle="mb-2"
                        inputContainerStyle="select-def-input-container"
                        validateBy="textRequired"
                        value={balance.amount}
                        onChange={(e) => handleAmountInputChange(i, e)}
                      />
                    </div>
                    {props.pickupCollection?.transactions.length > 1 ? (
                      <div key={i} className="btns_wrapper_style">
                        <button
                          disabled={modalData.isLoading}
                          className="btn delete_btn_style"
                          type="button"
                          onClick={() =>
                            props.deleteTransactionAction(
                              balance.id,
                              collectorId
                            )
                          }
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>

            <div>
              <CheckboxBooleanForm
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={isSubmitting}
                formServerValidation={formServerValidation}
                name="has_material"
                validationName={`input.has_material`}
                options={["material"]}
                type="checkbox"
              />
              {Boolean(+props.pickupCollection.has_material) ? (
                <textarea
                  rows="4"
                  name="material_note"
                  validationName={`input.material_note`}
                  placeholder="collection note"
                  formServerValidation={formServerValidation}
                  onChange={handleNoteChange}
                  value={props.pickupCollection?.material_note}
                  disabled={!Boolean(+props.pickupCollection.has_material)}
                />
              ) : null}
            </div>

            {/* (Start) Error Message */}
            {props.serverValid && isSubmitting && (
              <div className="warnig-msg-style">
                {props.serverValid["input.transactions"] ?? null}
                {props.serverValid["input.material_note"] ?? null}
              </div>
            )}
            {/* (End) Error Message */}
          </div>
        </>
      )}
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    pickupCollection: state.super.editPickupCollection,
    modalData: state.super.editPickupCollectionModal,
    isFormValid: state.super.editPickupCollectionValidation,
    serverValid: state.super.editPickupCollectionServerValidation,
    isDeleteTransaction: state.super.deleteTransactionLoading,
  };
};

export default connect(mapStateToProps, {
  dismissPickupCollectionFormAction,
  onInputResetAction,
  onInputChangeAction,
  onBalanceInputChange,
  editCollectionPickupAction,
  deleteTransactionAction,
})(EditPickupCollection);
