import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onInputResetAction } from "../../Store/Actions";

export const withDependency = (Component) => (props) => {
  const dispatch = useDispatch();
  const data = props.reducer ?? "super";
  const store = useSelector((state) => state);

  useEffect(() => {
    if (props.dependancyType) {
      switch (props.dependancyType) {
        case "greater":
          if (
            !props.dependancyValue.filter(
              (el) => store[data][props.formName][props.dependOn] > el
            ).length
          ) {
            handleResetInput();
          }
          break;
        case "less":
          if (
            !props.dependancyValue.filter(
              (el) => store[data][props.formName][props.dependOn] < el
            ).length
          ) {
            handleResetInput();
          }
          break;
        case "equal":
          if (
            !props.dependancyValue.includes(
              store[data][props.formName][props.dependOn]
            )
          ) {
            handleResetInput();
          }
          break;

        default:
          return;
      }
    }
  }, [
    store[data][props.formName]
      ? store[data][props.formName][props.dependOn]
      : null,
  ]);

  const handleResetInput = () => {
    if (!props.readOnly) {
      dispatch(onInputResetAction(props.formName, props.name));
      // props.onInputResetAction(props.formName, props.name);
    }
  };

  switch (props.dependancyType) {
    case "greater":
      return (
        Boolean(
          props.dependancyValue.filter(
            (el) => store[data][props.formName][props.dependOn] > el
          ).length
        ) && <Component {...props} />
      );

    case "less":
      return (
        Boolean(
          props.dependancyValue.filter(
            (el) => store[data][props.formName][props.dependOn] < el
          ).length
        ) && <Component {...props} />
      );

    case "equal":
      return (
        props.dependancyValue.includes(
          store[data][props.formName][props.dependOn]
        ) && <Component {...props} />
      );

    default:
      return <Component {...props} />;
  }
};
