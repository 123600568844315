import Types from "../../Store/Actions/types";

const withBuilder = (initState, reducer) => (state, action) => {
  switch (action.type) {
    //handle Input Change

    case Types.ON_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]:
              action.payload.event.target.value,
          },
        };
      }
      return state;

    case Types.ON_APPEND_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: state[action.payload.formName][
              action.payload.event.target.name
            ].map((el, i) =>
              i == action.payload.value ? action.payload.event.target.value : el
            ),
          },
        };
      }
      return state;
    case Types.ON_SELECT_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.name]: action.payload.value,
          },
        };
      }
      return state;

    case Types.ON_INPUT_INCREMENT:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              Number(state[action.payload.formName][action.payload.name]) + 1,
          },
        };
      }
      return state;
    case Types.ON_INPUT_DECREMENT:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              Number(state[action.payload.formName][action.payload.name]) - 1,
          },
        };
      }
      return state;
    case Types.ON_RADIO_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.value,
          },
        };
      }
      return state;
    case Types.ON_CHECKBOX_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.event.target
              .checked
              ? [
                  ...state[action.payload.formName][
                    action.payload.event.target.name
                  ],
                  action.payload.value,
                ]
              : state[action.payload.formName][
                  action.payload.event.target.name
                ].filter((val) => val !== action.payload.value),
          },
        };
      }
      return state;

    case Types.ON_CHECKBOX_BOOLEAN_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.event.target
              .checked
              ? 1
              : 0,
          },
        };
      }
      return state;

    case Types.ON_INPUT_RESET:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              initState[action.payload.formName][action.payload.name],
          },
        };
      }
      return state;

    case Types.SET_INPUT_VALID:
      if (state[action.payload.formNameValidation]) {
        return {
          ...state,
          [action.payload.formNameValidation]: state[
            action.payload.formNameValidation
          ].filter((input) => input !== action.payload.name),
        };
      }
      return state;

    case Types.SET_INPUT_INVALID:
      if (state[action.payload.formNameValidation]) {
        if (
          !state[action.payload.formNameValidation].includes(
            action.payload.name
          )
        ) {
          return {
            ...state,
            [action.payload.formNameValidation]: [
              ...state[action.payload.formNameValidation],
              action.payload.name,
            ],
          };
        }
      }
      return state;

    // handle Data table #sorting #filter #Paginate
    case Types.SET_DATATABLE_SORTING:
      if (state[action.payload.ref]) {
        return {
          ...state,
          [action.payload.ref]: {
            ...state[action.payload.ref],
            sorting: {
              column: action.payload.key,
              order: action.payload.dir,
            },
          },
        };
      }
      return state;

    case Types.SET_DATATABLE_FILTER:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          filter: action.payload.filter,
        },
      };

    case Types.SET_DATATABLE_PAGINATE:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          pagination: {
            ...state[action.payload.ref].pagination,
            ...action.payload.pagination,
          },
        },
      };

    default:
      return reducer(state, action);
  }
};

export default withBuilder;
