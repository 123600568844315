import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm, DateTimePickerForm } from "../../Builder/Form";
import {
  dismissDonorFormAction,
  upsertDonorAction,
  addDonorAddressAction,
  removeDonorAddressAction,
  onAddreesInputChange,
  onInputChangeAction,
} from "../../Store/Actions";
import { useLocation, useParams } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Const from "../../Constants";

const DonorForm = ({ donorFormModal, ...props }) => {
  const formName = "donorForm";
  const formNameValidation = "donorFormValidation";
  const formServerValidation = "donorFormServerValidation";

  const { donorId } = useParams();

  const handleSubmitBtn = () => {
    setFormSubmitting(true);
    if (props.isFormValid.length) {
      return;
    } else {
      props.upsertDonorAction({
        user: props.donorForm,
        donorId,
      });
    }
  };

  const [formSubmitting, setFormSubmitting] = useState(false);
  const formProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  useEffect(() => {
    return () => {
      setFormSubmitting(false);
    };
  }, [donorFormModal.isOpen]);

  const handleAddDonorAddress = () => {
    props.addDonorAddressAction();
  };
  const handleRemoveDonorAddress = (index) => {
    props.removeDonorAddressAction(index);
  };

  const handleAddressInputChange = (index, e) => {
    if (e.target.name == "default_address") {
      let value = e.target.checked ? 1 : 0;
      props.onAddreesInputChange(formName, index, e.target.name, value);
      return;
    }
    props.onAddreesInputChange(formName, index, e.target.name, e.target.value);
  };
  const handleAddressSelectChange = (index, val, e) => {
    let value = val ? val?.id : "";
    props.onAddreesInputChange(formName, index, e.name, value);
    if (e.name == "city_id") {
      props.onAddreesInputChange(formName, index, "area_id", "");
    }
  };

  const handleNoteChange = (event) => {
    props.onInputChangeAction(formName, event);
  };
  return (
    <MainModal
      isOpen={donorFormModal.isOpen}
      modalTitle={`${donorFormModal.type} Donor`}
      btnOnClick={handleSubmitBtn}
      btnOnCancelClick={() => props.dismissDonorFormAction()}
      btnSubmitLoading={donorFormModal.isLoading}
    >
      <div>
        <InputForm
          name="name"
          {...formProps}
          validationName={`input.name`}
          placeholder="Name"
          label="Name"
          labelStyle="modal_label_style"
          containerStyle="mb-2"
          inputContainerStyle=""
          validateBy="textRequired"
        />
        <div className="row">
          <div className="col-md-6">
            <InputForm
              name="phone"
              {...formProps}
              validationName={`input.phone`}
              placeholder="Phone"
              label="Phone"
              labelStyle="modal_label_style"
              containerStyle="mb-2"
              inputContainerStyle=""
              validateBy="textRequired"
            />
          </div>
          <div className="col-md-6">
            <InputForm
              name="alt_phone"
              {...formProps}
              validationName={`input.alt_phone`}
              placeholder="Alt Phone"
              label="Alt Phone"
              labelStyle="modal_label_style"
              containerStyle="mb-2"
              inputContainerStyle=""
              // validateBy="textRequired"
            />
          </div>
        </div>
        <InputForm
          name="email"
          {...formProps}
          validationName={`input.email`}
          placeholder="Email"
          label="Email"
          labelStyle="modal_label_style"
          containerStyle="mb-2"
          inputContainerStyle=""
          // validateBy="textRequired"
        />

        <div className="d-flex flex-column">
          <label className="modal_label_style">Notes</label>
          <textarea
            rows="4"
            name="note"
            validationName={`input.note`}
            onChange={handleNoteChange}
            value={props.donorForm.note}
          />
        </div>
        <hr />

        <div className="d-flex mb-1 donor_pickup_adresses_wrapper_style">
          <label>pickup addresses</label>
          <button
            className="btn sucsses_btn_style plus_icon_btn_style ml-3 mt-1"
            type="button"
            onClick={handleAddDonorAddress}
          >
            <AddIcon />
          </button>
        </div>

        {props.donorForm.addresses.map((address, i) => (
          <div className="addresses_wrapper_style" key={i}>
            <div className="d-flex justify-content-between align-items-baseline">
              <label className="modal_label_style mb-0">{`Address ${
                i + 1
              }`}</label>

              {Boolean(+i) && (
                <div>
                  <button
                    className="btn delete_btn_style mb-1"
                    type="button"
                    onClick={() => handleRemoveDonorAddress(i)}
                  >
                    <CloseIcon />
                  </button>
                </div>
              )}
            </div>

            <div className="row">
              <div className="w-100">
                <FormControlLabel
                  name={"default_address"}
                  // value={1}
                  onChange={(e) => handleAddressInputChange(i, e)}
                  checked={Boolean(+address.default_address)}
                  control={<Radio color="primary" />}
                  label={
                    <InputForm
                      name="name"
                      validationName={`input.addresses.${i}.name`}
                      {...formProps}
                      placeholder="Address"
                      labelStyle="modal_label_style"
                      containerStyle="mb-0"
                      inputContainerStyle=""
                      validateBy="textRequired"
                      onChange={(e) => handleAddressInputChange(i, e)}
                      value={address.name}
                    />
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <BSelect
                  name="city_id"
                  {...formProps}
                  validationName={`input.addresses.${i}.city_id`}
                  optionLabel="name"
                  optionValue="id"
                  options={Const.Cities}
                  placeholder="City"
                  keepDefaultStyle
                  inputContainerStyle="select-def-input-container"
                  containerStyle="mb-2"
                  validateBy="textRequired"
                  value={address.city_id}
                  onChange={(val, e) => handleAddressSelectChange(i, val, e)}
                  isMulti={false}
                />
              </div>
              <div className="col-md-6">
                <BSelect
                  name="area_id"
                  {...formProps}
                  validationName={`input.addresses.${i}.area_id`}
                  optionLabel="name"
                  optionValue="id"
                  options={Const.Areas}
                  placeholder="Area"
                  keepDefaultStyle
                  inputContainerStyle="select-def-input-container"
                  containerStyle="mb-2"
                  validateBy="textRequired"
                  value={address.area_id}
                  onChange={(val, e) => handleAddressSelectChange(i, val, e)}
                  optDependType="equal"
                  optDependKey={"cityId"}
                  optDependValue={[props.donorForm.addresses[i]?.city_id ?? 0]}
                />
              </div>
            </div>
            <InputForm
              name="map_link"
              {...formProps}
              validationName={`input.addresses.${i}.map_link`}
              placeholder="Map Link"
              labelStyle="modal_label_style"
              containerStyle="mb-2"
              inputContainerStyle=""
              // validateBy="textRequired"
              onChange={(e) => handleAddressInputChange(i, e)}
              value={address.map_link}
            />

            <hr />
          </div>
        ))}

        {/* (Start) Error Message */}
        {donorFormModal.errorMessage && formSubmitting && (
          <div className="warnig-msg-style">{donorFormModal.errorMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    donorForm: state.super.donorForm,
    donorFormModal: state.super.donorFormModal,
    isFormValid: state.super.donorFormValidation,
    donorsFiler: state.super.DonorsFiler,
  };
};

export default connect(mapStateToProps, {
  dismissDonorFormAction,
  upsertDonorAction,
  addDonorAddressAction,
  removeDonorAddressAction,
  onAddreesInputChange,
  onInputChangeAction,
})(DonorForm);
