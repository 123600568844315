import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

const MainModal = ({
  modalTitle = "",
  btnLabel = "Save",
  btnOnClick,
  btnSubmitLoading = false,
  btnLabelCancel = "Cancel",
  btnOnCancelClick,
  children,
  ...props
}) => {
  return (
    <div>
      <Modal fade={false} centered={true} {...props}>
        {modalTitle && <ModalHeader>{modalTitle}</ModalHeader>}
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          {btnOnCancelClick && (
            <Button className="modal_cancel_btn_style" onClick={btnOnCancelClick}>
              {btnLabelCancel}
            </Button>
          )}

          {btnOnClick && (
            <Button
              className="modal_save_btn_style"
              disabled={btnSubmitLoading}
              onClick={btnOnClick}
            >
              {btnSubmitLoading ? (
                <Spinner
                  style={{ width: "1rem", height: "1rem", color: "#fff" }}
                />
              ) : (
                btnLabel
              )}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MainModal;
