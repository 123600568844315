import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
} from "../../Store/Actions";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { AccessAlarms } from "@material-ui/icons";
import { withDependency } from "../../Helpers/HOC/withDependency";
import "./DateTimePickerFormStyle.scss";

const DateTimePickerForm = ({
  formName,
  name,
  formSubmitting,
  formNameValidation,
  validateBy,
  format,
  requestFormat,
  label,
  rootStyle = "",
  containerStyle,
  datePickerContainer,
  inputStyle,
  labelStyle,
  hasIcon = true,
  append,
  appendKey,
  placeholder,
  dateTime,
  timeOnly,
  validateContainerStyle,
  validateMessage,
  reducer,
  weekSelect,
  startDate,
  endDate,
  formServerValidation,
  validationName,
  ...props
}) => {
  format = format
    ? format
    : dateTime
    ? "dd/MM/yyyy hh:mm aa"
    : timeOnly
    ? "hh:mm aa"
    : "dd/MM/yyyy";
  requestFormat = requestFormat
    ? requestFormat
    : dateTime
    ? "yyyy/MM/DD HH:mm"
    : timeOnly
    ? "HH:mm"
    : "yyyy/MM/DD";

  const inputval = props.value ?? props.super[formName][name];
  const isDisabled = props.disabled;
  const isServerValid = !Boolean(props.super[formServerValidation])
    ? false
    : props.super[formServerValidation][validationName]
    ? true
    : false;

  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled,
    props.super[formName][props.dependTime]
  );

  const weekRange = `${moment(startDate).format("DD/MM/yyyy")} - ${moment(
    endDate
  ).format("DD/MM/yyyy")}`;

  const handleDatePickerChange = (value) => {
    let e;
    if (weekSelect && moment(value[0]).isValid()) {
      e = {
        target: {
          name,
          value: moment(value).format(requestFormat),
        },
      };
    } else if (moment(value).isValid()) {
      e = {
        target: {
          name,
          value: moment(value).format(requestFormat),
        },
      };
    } else {
      e = {
        target: {
          name,
          value: null,
        },
      };
    }
    if (append) {
      props.onInputChangeAction(formName, e, "append", appendKey);
    } else {
      props.onInputChangeAction(formName, e);
    }
  };

  const CustomIconInput = ({ value, onClick }) => (
    <div
      className={`${
        inputStyle ? inputStyle : "date-picker-input-default"
      } validity-input-style`}
    >
      <span style={{ padding: "1.5px 6px", backgroundColor: "#f5f5f5" }}>
        {timeOnly ? (
          <AccessAlarms
            style={{ fontSize: 14, color: "rgb(121 121 121 / 69%)" }}
          ></AccessAlarms>
        ) : (
          <DateRangeIcon
            style={{ fontSize: 14, color: "rgb(121 121 121 / 69%)" }}
          ></DateRangeIcon>
        )}
      </span>
      <button
        className={`form-control ${
          inputStyle
            ? inputStyle
            : `form-control p-0 ${!true ? "is-invalid" : ""}`
        }`}
        onClick={onClick}
        {...props}
      >
        {value
          ? weekSelect
            ? weekRange
            : value
          : placeholder
          ? placeholder
          : weekSelect
          ? "Choose Week"
          : timeOnly
          ? "Choose Time"
          : dateTime
          ? "Choose Date"
          : "Choose Day"}
      </button>
    </div>
  );
  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? `${rootStyle} invalid-container-style`;
    }
    return `${rootStyle}`;
  };

  return (
    <div class={vaildContainer()}>
      <div
        className={`${
          containerStyle ? containerStyle : "container-style-default"
        }`}
      >
        {label && (
          <label
            className={`${labelStyle ? labelStyle : ""} validity-label-style`}
          >
            {label}
          </label>
        )}
        <div
          className={`${
            datePickerContainer ? datePickerContainer : "ml-auto"
          } ${dateTime && "fix-datetimepicker-width"}`}
        >
          <DatePicker
            showYearDropdown
            yearDropdownItemNumber={5}
            scrollableYearDropdow
            className={`${
              inputStyle
                ? inputStyle
                : `form-control ${!validate.validity ? "is-invalid" : ""}`
            }`}
            {...(append
              ? {
                  selected: Boolean(props.super[formName][name][appendKey])
                    ? new Date(
                        moment(
                          props.super[formName][name][appendKey],
                          requestFormat
                        )
                      )
                    : "",
                }
              : {
                  selected: props.super[formName][name]
                    ? new Date(
                        moment(props.super[formName][name], requestFormat)
                      )
                    : props.super[formName][name],
                })}
            {...(dateTime && {
              showTimeSelect: true,
            })}
            {...(timeOnly && {
              showTimeSelect: true,
              showTimeSelectOnly: true,
            })}
            {...(weekSelect && {
              startDate,
              endDate,
            })}
            openToDate={new Date()}
            onChange={(date) => handleDatePickerChange(date)}
            dateFormat={format}
            customInput={hasIcon ? <CustomIconInput /> : false}
            {...props}
          />
        </div>
      </div>
      <div className="validity-msg-style">
        {validateMessage ? validateMessage : validate.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(props.super[formServerValidation])
          ? props.super[formServerValidation][validationName] ?? ""
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    super: state[reducer],
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
})(withDependency(DateTimePickerForm));
