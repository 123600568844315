import { call, all } from "redux-saga/effects";

import AuthSaga from "./AuthSaga";
import UserSaga from "./UserSaga";
import SuperSaga from "./SuperSaga";

export default function* rootSaga() {
  yield all([
    call(SuperSaga.onFetchAllUsers),
    call(SuperSaga.onUpsertUser),
    call(SuperSaga.onFetchDonors),
    call(SuperSaga.onFetchAllDonors),
    call(SuperSaga.onFetchCollectorProfile),
    call(SuperSaga.onFetchCollectionsList),
    call(SuperSaga.onFetchPickups),
    call(SuperSaga.onFetchPickupsList),
    call(SuperSaga.onUpsertDonor),
    call(SuperSaga.onFetchDonor),
    call(SuperSaga.onFetchDonorProfileView),
    call(SuperSaga.onFetchDonorPickups),
    call(SuperSaga.onSettleCollectorBalance),
    call(AuthSaga.onLogingInAttempt),
    call(SuperSaga.onfetchPickupDonor),
    call(SuperSaga.onUpsertPickup),
    call(SuperSaga.onCancelPickup),
    call(SuperSaga.onAddCollectorBalance),
    call(UserSaga.onForgotPasswordSaga),
    call(UserSaga.onResetPasswordSaga),
    call(SuperSaga.onEditPickupCollection),
    call(SuperSaga.onDeleteBalance),
    call(SuperSaga.onFetchCollectedPickup),
    call(SuperSaga.onDeleteTransaction),
  ]);
}
