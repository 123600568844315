import React, { useEffect } from "react";
import { connect } from "react-redux";
import DonorPickupsList from "../../Containers/DonorPickupsList";
import DonorProfile from "../../Containers/DonorProfile";
import DonorForm from "../../Containers/DonorsList/DonorForm";
import { fetchDonorProfileViewAction } from "../../Store/Actions";
import { useParams } from "react-router-dom";
import PickupForm from "../../Containers/PickupsList/PickupForm";

const ViewDonorProfile = (props) => {
  const { donorId } = useParams();
  useEffect(() => {
    props.fetchDonorProfileViewAction(donorId);
    return () => {};
  }, []);
  return (
    <>
      <DonorProfile />
      <DonorPickupsList />
      <DonorForm />
      <PickupForm />
    </>
  );
};

export default connect(null, { fetchDonorProfileViewAction })(ViewDonorProfile);
