import React, { useEffect } from "react";
import PickupsList from "../../Containers/PickupsList";
import { fetchPickupsViewAction } from "../../Store/Actions";
import { connect } from "react-redux";
import PickupForm from "../../Containers/PickupsList/PickupForm";
import EditPickupCollection from "../../Containers/CollectorProfile/EditPickupCollection";

const ViewPickupsList = (props) => {
  useEffect(() => {
    props.fetchPickupsViewAction();
    return () => {};
  }, []);
  return (
    <>
      <PickupsList />
      <PickupForm />
      <EditPickupCollection/>
    </>
  );
};

export default connect(null, { fetchPickupsViewAction })(ViewPickupsList);
