import React from "react";
const UnauthorizedPage = () => {
  return (
    <div class="container">
      <h1>403</h1>
      <p>Access denied</p>
    </div>
  );
};

export default UnauthorizedPage;
