import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./Reducers";
import rootSaga from "./Sagas";

const sagaMiddleware = createSagaMiddleware(); // Saga middleware

const middleWares = [sagaMiddleware];
// var devtools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;

const initState = {};

const persistConfig = {
  key: "merqah_root",
  whitelist: ["auth"],
  storage,
  migrate: (state) => {
    console.log("Migration Running!");
    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const Store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middleWares))
);
export default Store;

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(Store);
