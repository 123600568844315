import React from "react";
import UsersList from "../../Containers/UsersList";
import UserForm from "../../Containers/UsersList/UserForm";

const ViewUsersList = () => {
  return (
    <>
      <UsersList />
      <UserForm />
    </>
  );
};

export default ViewUsersList;
