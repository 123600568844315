

export const Privileges = {
    CAN_VIEW_USERS: "CAN_VIEW_USERS",
    CAN_VIEW_DONORS: "CAN_VIEW_DONORS",
    CAN_VIEW_DONOR_PROFILE: "CAN_VIEW_DONOR_PROFILE",
    CAN_VIEW_DONOR_DONATIONS: "CAN_VIEW_DONOR_DONATIONS",
    CAN_VIEW_COLLECTORS: "CAN_VIEW_COLLECTORS",
    CAN_VIEW_COLLECTOR_PROFILE: "CAN_VIEW_COLLECTOR_PROFILE",
    CAN_VIEW_COLLECTORS_BALANCE: "CAN_VIEW_COLLECTORS_BALANCE",
    CAN_VIEW_PICKUPS: "CAN_VIEW_PICKUPS",
    CAN_VIEW_PICKUP_AUDITS: "CAN_VIEW_PICKUP_AUDITS",
    CAN_VIEW_TRANSACTION_AUDITS: "CAN_VIEW_TRANSACTION_AUDITS",
    CAN_VIEW_TRANSACTION_BALANCE: "CAN_VIEW_TRANSACTION_BALANCE",
    CAN_VIEW_COLLECTOR_TRANSACTIONS: "CAN_VIEW_COLLECTOR_TRANSACTIONS",

    CAN_ADD_DONOR: "CAN_ADD_DONOR",
    CAN_ADD_USER: "CAN_ADD_USER",
    CAN_ADD_ADMIN: "CAN_ADD_ADMIN",
    CAN_EDIT_COLLECTOR: "CAN_EDIT_COLLECTOR",

    CAN_ADD_PICKUP: "CAN_ADD_PICKUP",
    CAN_DELETE_PICKUP: "CAN_DELETE_PICKUP",

    CAN_ADD_BALANCE: "CAN_ADD_BALANCE",
    CAN_SETTLE_BALANCE: "CAN_SETTLE_BALANCE",
    CAN_EDIT_TRANSATION: "CAN_EDIT_TRANSATION",
    CAN_DELETE_TRANSATION: "CAN_DELETE_TRANSATION",


}



const { CAN_VIEW_USERS, CAN_VIEW_DONORS, CAN_VIEW_DONOR_PROFILE, CAN_VIEW_DONOR_DONATIONS, CAN_VIEW_COLLECTORS, CAN_VIEW_COLLECTOR_PROFILE, CAN_VIEW_COLLECTORS_BALANCE, CAN_VIEW_PICKUPS, CAN_VIEW_PICKUP_AUDITS, CAN_VIEW_TRANSACTION_AUDITS, CAN_VIEW_TRANSACTION_BALANCE, CAN_VIEW_COLLECTOR_TRANSACTIONS, CAN_ADD_DONOR, CAN_ADD_USER, CAN_ADD_ADMIN, CAN_EDIT_COLLECTOR, CAN_ADD_PICKUP, CAN_DELETE_PICKUP, CAN_ADD_BALANCE, CAN_SETTLE_BALANCE, CAN_EDIT_TRANSATION, CAN_DELETE_TRANSATION } = Privileges



const ADMIN = "1";
const CSR = "2";
const COLLECTOR = "3";
const DONOR = "4";
const BALANCE_SETTLER = "5";
const OBSERVER = "6";
const REVIEWER = "7";

export const RolePrivilege = {
    [ADMIN]: [CAN_VIEW_USERS, CAN_VIEW_DONORS, CAN_VIEW_DONOR_PROFILE, CAN_VIEW_DONOR_DONATIONS, CAN_VIEW_COLLECTORS, CAN_VIEW_COLLECTOR_PROFILE, CAN_VIEW_COLLECTORS_BALANCE, CAN_VIEW_PICKUPS, CAN_VIEW_PICKUP_AUDITS, CAN_VIEW_TRANSACTION_AUDITS, CAN_VIEW_TRANSACTION_BALANCE, CAN_VIEW_COLLECTOR_TRANSACTIONS, CAN_ADD_DONOR, CAN_ADD_USER, CAN_ADD_ADMIN, CAN_EDIT_COLLECTOR, CAN_ADD_PICKUP, CAN_DELETE_PICKUP, CAN_ADD_BALANCE, CAN_SETTLE_BALANCE, CAN_EDIT_TRANSATION, CAN_DELETE_TRANSATION],
    [CSR]: [CAN_VIEW_PICKUPS, CAN_ADD_PICKUP, CAN_DELETE_PICKUP, CAN_ADD_DONOR, CAN_VIEW_DONORS, CAN_VIEW_DONOR_PROFILE],
    [COLLECTOR]: [],
    [DONOR]: [],
    [BALANCE_SETTLER]: [CAN_VIEW_COLLECTORS, CAN_VIEW_COLLECTOR_PROFILE, CAN_VIEW_COLLECTORS_BALANCE, CAN_SETTLE_BALANCE],
    [OBSERVER]: [CAN_VIEW_PICKUPS],
    [REVIEWER]: [CAN_VIEW_COLLECTORS, CAN_VIEW_COLLECTOR_PROFILE, CAN_VIEW_COLLECTOR_TRANSACTIONS, CAN_VIEW_COLLECTORS_BALANCE, CAN_VIEW_TRANSACTION_AUDITS, CAN_VIEW_TRANSACTION_BALANCE],

}
