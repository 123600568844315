import React, { useState } from "react";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import "../../Builder/Form/InputFormStyle.scss";
import Const from "../../Constants";

import {
  onInputChangeAction,
  showUserFormAction,
  showPickupFormAction,
} from "../../Store/Actions";

import { InputForm, BSelect, DateTimePickerForm } from "../../Builder/Form";

const DonorPickupsSearchCard = (props) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleRoleFilter = (val) => {
    let e = {
      target: {
        name: "status_id",
        value: val,
      },
    };
    props.onInputChangeAction(props.formName, e);
  };

  return (
    <div className="custom_nav_tabs_wrapper_style card_wrapper_style pt-2">
      <div className="d-flex justify-content-between">
        <div>
          <Nav className="flex-column flex-md-row" tabs>
            {Const.PickupsFilterOptions.map((status) => (
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      status.value === props.data[props.formName].status_id,
                  })}
                  onClick={() => handleRoleFilter(status.value)}
                >
                  {status.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>

        <div>
          <div className="d-flex">
            <div>
              <label
                className="btn search_btn_style mr-2"
                type="button"
                onClick={handleToggleFilter}
              >
                <SearchOutlinedIcon />
              </label>
            </div>

            <div>
              <button
                className="btn add_new_btn_style"
                type="button"
                onClick={() =>
                  props.showPickupFormAction("new", {
                    id: "",
                    collector_id: null,
                    contact_date: null,
                    collection_date: null,
                    pickup_type_id: "1",
                    main_note: "",
                    donor_id: props.donorProfile?.id,
                    address_id: props.donorProfile?.address?.id,
                    donor: props?.donorProfile,
                  })
                }
              >
                <PersonAddOutlinedIcon />
                <p>Add new pickup</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Collapse isOpen={showFilter}>
        <div className="mt-3 pl-2 pl-md-0">
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <InputForm
                name="name"
                placeholder="Collection ID"
                containerStyle=" "
                inputStyle="input-style-default"
                formName={props.formName}
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <BSelect
                name="collector_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                // isMulti
                isClearable
                optionLabel="name"
                optionValue="id"
                options={props.data?.pickupsFiler?.collectorOptions}
                placeholder="Select Collectors"
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <DateTimePickerForm
                dateTime
                hasIcon={false}
                name="date_from"
                placeholderText="Time Range / From"
                isClearable
                containerStyle="py-0"
                datePickerContainer="w-100"
                inputStyle="date-picker-input-default"
                formName={props.formName}
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <DateTimePickerForm
                dateTime
                hasIcon={false}
                name="date_to"
                placeholder="Time Range / To"
                containerStyle="py-0"
                datePickerContainer="w-100"
                inputStyle="date-picker-input-default"
                formName={props.formName}
                placeholderText="Time Range / To"
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <BSelect
                name="city_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti
                optionLabel="name"
                optionValue="id"
                options={Const.Cities}
                placeholder="Select City"
              />
            </div>

            <div className="col-12 col-md-6 mb-2">
              <BSelect
                name="area_id"
                formName={props.formName}
                keepDefaultStyle
                inputContainerStyle="select-def-input-container"
                containerStyle=" "
                rootStyle=""
                isMulti
                optionLabel="name"
                optionValue="id"
                options={Const.Areas}
                placeholder="Select Area"
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    donorProfile: state.super.donorProfile,
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  showUserFormAction,
  showPickupFormAction,
})(DonorPickupsSearchCard);
