import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm, DateTimePickerForm } from "../../Builder/Form";
import {
  dismissSettleBalanceFormAction,
  onBalanceInputChange,
  settleCollectorBalanceAction,
  deleteTransactionAction,
} from "../../Store/Actions";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import Const from "../../Constants";
import Loader from "../../Components/Loader";

const BalanceForm = ({ balanceFormModal, ...props }) => {
  const formName = "balanceForm";
  const formNameValidation = "balanceFormValidation";
  const formServerValidation = "balanceFormServerValidation";
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { collectorId } = useParams();

  const [error, setError] = useState("");
  const handleSubmitBtn = () => {
    setFormSubmitting(true);
    props.settleCollectorBalanceAction({
      ...props.balanceForm,
      transactions: props.balanceForm.transactions.map(
        ({ amount, ...transaction }) => ({
          ...transaction,
          amount: Number(amount),
        })
      ),
    });
  };

  const formProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  useEffect(() => {
    return () => {
      setFormSubmitting(false);
      // setError("");
    };
  }, [balanceFormModal.isOpen]);

  const handleBalanceInputChange = (index, e) => {
    props.onBalanceInputChange(formName, index, e.target.name, e.target.value);
  };
  const handleBalanceSelectChange = (index, val, e) => {
    let value = val ? val?.id : "";
    props.onBalanceInputChange(formName, index, e.name, value);
  };
  return (
    <MainModal
      isOpen={balanceFormModal.isOpen}
      modalTitle={`${balanceFormModal.type} Balance`}
      btnOnClick={props.isDeleteTransaction ? null : handleSubmitBtn}
      btnOnCancelClick={
        props.isDeleteTransaction
          ? null
          : () => props.dismissSettleBalanceFormAction()
      }
      btnSubmitLoading={balanceFormModal.isLoading}
    >
      {props.isDeleteTransaction ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <div>
            {props.balanceForm?.transactions?.map((transaction, i) => (
              <div key={`${i}`}>
                <div key={`${i + 1}`} className="row">
                  <div key={`${i + 3}`} className="col-md-6">
                    <InputForm
                      name="amount"
                      validationName={`input.transactions.${i}.amount`}
                      {...formProps}
                      placeholder="Amount"
                      containerStyle=" "
                      inputContainerStyle=""
                      // validateBy="textRequired"
                      onChange={(e) => handleBalanceInputChange(i, e)}
                      value={transaction.amount}
                    />
                  </div>
                  <div key={`${i + 2}`} className="col-12 col-xl-6 d-flex">
                    <div className="w-100 mr-2">
                    <BSelect
                      name="currency_id"
                      {...formProps}
                      validationName={`input.transactions.${i}.currency_id`}
                      optionLabel="name"
                      optionValue="id"
                      options={Const.CurrencyOptions}
                      placeholder="currency"
                      keepDefaultStyle
                      inputContainerStyle="select-def-input-container"
                      containerStyle="my-2 my-md-0 mb-md-2"
                      validateBy="textRequired"
                      value={transaction.currency_id}
                      onChange={(val, e) =>
                        handleBalanceSelectChange(i, val, e)
                      }
                      isMulti={false}
                      isDisabled={balanceFormModal.type !== "edit settle"}
                    />
                    </div>
                    {props.balanceForm?.transactions?.length > 1 &&
                    balanceFormModal.type == "edit settle" ? (
                      <div key={i} className="btns_wrapper_style">
                        <button
                          disabled={balanceFormModal.isLoading}
                          className="btn delete_btn_style"
                          type="button"
                          onClick={() =>
                            props.deleteTransactionAction(
                              transaction.id,
                              collectorId
                            )
                          }
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}

            {/* (Start) Error Message */}
            {props.serverValid && formSubmitting && (
              <div className="warnig-msg-style">
                {props.serverValid["input.transactions"] ?? null}
              </div>
            )}
            {/* (End) Error Message */}
          </div>
        </>
      )}
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    collectorProfile: state.super.selectedCollectorProfile,
    balanceForm: state.super.balanceForm,
    balanceFormModal: state.super.balanceFormModal,
    isFormValid: state.super.balanceFormValidation,
    serverValid: state.super.balanceFormServerValidation,
    isDeleteTransaction: state.super.deleteTransactionLoading,
  };
};

export default connect(mapStateToProps, {
  dismissSettleBalanceFormAction,
  settleCollectorBalanceAction,
  onBalanceInputChange,
  deleteTransactionAction,
})(BalanceForm);
