import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { RadioboxForm } from "../../Builder/Form";
import AddPickupAddressInput from "./AddPickupAddressInput";

const RadioBtnData = [
  { value: 1, label: "4053  Hillside Street, SANTA BARBARA" },
  { value: 2, label: "4053  Hillside Street, SANTA BARBARA" },
  { value: 3, label: "4053  Hillside Street, SANTA BARBARA" },
  { value: 4, label: "4053  Hillside Street, SANTA BARBARA" },
  { value: 5, label: "4053  Hillside Street, SANTA BARBARA" },
];

const AddPickupaddresses = (props) => {
  const formName = "pickupAddressForm";
  const formNameValidation = "pickupAddressValidation";
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, []);

  return (
    <div className="add_pickup_addresses_modal_wrapper_style">
      <div className="d-flex mb-3">
        <label>pickup addresses</label>
        <button className="btn sucsses_btn_style ml-3" type="button">
          <AddIcon />
        </button>
      </div>

      <RadioboxForm
        name="pickup_address_options"
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={isSubmitting}
        options={RadioBtnData}
        validateBy="textRequired"
        containerStyle=""
        labelStyle="d-flex mr-5"
        optionsContainerStyle="flex-column"
        optionItemStyle="d-flex align-items-center"
        optionLabelStyle="mb-3 mr-3"
        optionInputStyle="mr-2"
        type="checkbox"
      />

      <AddPickupAddressInput />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pickupAddressForm: state.super.pickupAddressForm,
    isFormValid: state.super.pickupAddressForm,
  };
};

export default connect(mapStateToProps)(AddPickupaddresses);
