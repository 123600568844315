import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchDonorsViewAction } from "../../Store/Actions";
import DonorsList from "../../Containers/DonorsList";
import DonorForm from "../../Containers/DonorsList/DonorForm";

const ViewDonorsList = (props) => {
  useEffect(() => {
    props.fetchDonorsViewAction();
    return () => {};
  }, []);
  return (
    <>
      <DonorsList />
      <DonorForm />
    </>
  );
};

export default connect(null, { fetchDonorsViewAction })(ViewDonorsList);
