import Types from "../Actions/types";

export const initState = {
  loggingInLoading: false,
  userProfile: {},
  forgotPasswordLoading: false,
  resetPasswordLoading: false,
};

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.LOGING_IN_ATTEMPT:
      return { ...state, loggingInLoading: true };

    case Types.LOGED_IN_SUCCESS:
    case Types.LOGED_IN_FAILED:
      return { ...state, loggingInLoading: false };

    case Types.FORGOT_PASSWORD_ATTEMPT:
      return { ...state, forgotPasswordLoading: true };

    case Types.FORGOT_PASSWORD_FINISH:
      return { ...state, forgotPasswordLoading: false };

    case Types.RESET_PASSWORD_ATTEMPT:
      return { ...state, resetPasswordLoading: true };

    case Types.RESET_PASSWORD_SUCCESS:
    case Types.RESET_PASSWORD_FAILED:
      return { ...state, resetPasswordLoading: false };

    case Types.LOG_OUT:
      return { ...initState };

    default:
      return state;
  }
};
