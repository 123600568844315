import React, { useState, useEffect } from "react";
import Validation from "../../Helpers/Validations";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";

import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
  ErrorOutline,
} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import "./commonStyles.scss";
import "./InputFormStyle.scss";

import Icon from "@material-ui/core/Icon";

import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
  onInputIncrementAction,
  onInputDecrementAction,
} from "../../Store/Actions";
import { withDependency } from "../../Helpers/HOC/withDependency";

const InputTooltip = ({ title = "", tooltipStyle }) => {
  return (
    <span className={`${tooltipStyle ? tooltipStyle : "tooltip-icon-style"}`}>
      <Tooltip placement="top" title={title}>
        <ErrorOutline fontSize="inherit" color="inherit" />
      </Tooltip>
    </span>
  );
};

const InputForm = ({
  name,
  dependOn,
  label,
  formName,
  _onChange,
  validateBy,
  formSubmitting,
  handleInValidForm,
  FormValid = [],
  formNameValidation,
  containerStyle,
  inputContainerStyle,
  inputStyle,
  labelStyle,
  stepInputStyle,
  stepInput,
  hasSuffix,
  suffixStyle,
  suffixTitle,
  hasTooltip,
  tooltipTitle,
  tooltipStyle,
  validateContainerStyle,
  validateMessage,
  reducer,
  formServerValidation,
  validationName,
  ...props
}) => {
  const inputval = props.value ?? props.super[formName][name];
  const isDisabled = props.disabled;
  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );
  const isServerValid = !Boolean(props.super[formServerValidation])
    ? false
    : props.super[formServerValidation][validationName]
    ? true
    : false;

  const handleInputChange = (event) => {
    event.persist();
    props.onInputChangeAction(formName, event);
  };

  const handleIncrementChange = (event) => {
    console.log(event);
    props.onInputIncrementAction(formName, name);
  };

  const handleDecrementChange = (event) => {
    props.onInputDecrementAction(formName, name);
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  return (
    <div class={vaildContainer()}>
      <div
        className={`${
          containerStyle ? containerStyle : "container-style-default"
        }`}
      >
        {label && (
          <label
            className={`${
              labelStyle ? labelStyle : "label-style-default"
            } validity-label-style`}
          >
            {label}
            {hasTooltip && (
              <InputTooltip title={tooltipTitle} tooltipStyle={tooltipStyle} />
            )}
          </label>
        )}
        {/* {hasTooltip && (
          <InputTooltip title={tooltipTitle} tooltipStyle={tooltipStyle} />
        )} */}
        {stepInput ? (
          <div
            className={`${
              inputContainerStyle
                ? inputContainerStyle
                : "input-container-style-default"
            } validity-input-style `}
          >
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              className="buttonRoot"
              onClick={handleDecrementChange}
            >
              <RemoveIcon classes={{ root: "iconRoot" }}></RemoveIcon>{" "}
            </IconButton>

            <input
              className={`${stepInputStyle ? stepInputStyle : "stepInputRoot"}`}
              name={name}
              value={props.super[formName][name]}
              onChange={handleInputChange}
              type={props.type}
              {...props}
            />

            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              className="buttonRoot"
              onClick={handleIncrementChange}
            >
              <AddIcon classes={{ root: "iconRoot" }}></AddIcon>{" "}
            </IconButton>
          </div>
        ) : hasSuffix ? (
          <div
            className={`${
              inputContainerStyle
                ? inputContainerStyle
                : "input-container-style-default"
            } validity-input-style`}
          >
            <input
              className={`${
                inputStyle ? inputStyle : "suffix-input"
              } validity-input-style`}
              name={name}
              value={props.super[formName][name]}
              onChange={handleInputChange}
              type={props.type}
              {...props}
            />
            <span
              className={suffixStyle ? suffixStyle : "suffix-style-default"}
            >
              {suffixTitle}
            </span>
          </div>
        ) : (
          <div
            className={`${
              inputContainerStyle
                ? inputContainerStyle
                : "input-container-style-default"
            }`}
          >
            <input
              className={`${
                inputStyle ? inputStyle : "input-style-default"
              } validity-input-style`}
              name={name}
              value={props.super[formName][name]}
              onChange={handleInputChange}
              type={props.type}
              {...props}
            />
          </div>
        )}
      </div>
      <div className="validity-msg-style">
        {validateMessage ? validateMessage : validate.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(props.super[formServerValidation])
          ? props.super[formServerValidation][validationName] ?? ""
          : null}{" "}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    super: state[reducer],
  };
};
export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
  onInputDecrementAction,
  onInputIncrementAction,
})(withDependency(InputForm));
