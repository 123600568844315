import React from "react";

const Loader = () => {
  return (
    <>
      <div class="site-loader-inner">
        <img src={require("../../Assets/img/double-ring.gif").default} />
      </div>
    </>
  );
};

export default Loader;
