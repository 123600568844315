import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HasPrivileges from "../HelperFns/Roles/HasPrivileges";

const ProtectedRouter = ({ userRole, roles = [], children, ...props }) => {
  // if (roles.includes(userRole?.id)) {
  //   return <Route {...props}>{children}</Route>;
  // } else {
  //   return (
  //     <Route {...props}>
  //       <Redirect to="/unauthorized" />
  //     </Route>
  //   );
  // }
  
  return <>
    <HasPrivileges
      can={props.can}
      altChildren={<Route {...props}><Redirect to="/unauthorized" /></Route>}
    >
      <Route {...props}>{children}</Route>
    </HasPrivileges>
  </>
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth?.userProfile?.role,
  };
};

export default connect(mapStateToProps)(ProtectedRouter);
