const getIntials = (name) => {
  if (name) {
    let fullname = name.trim().split(" ");
    return `${fullname[0][0]}${fullname[fullname.length - 1][0]}`;
  }
};

export default {
  getIntials,
};
