import React, { useState } from "react";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Helper from "../../Helpers/HelperFns";
import { NavLink, BrowserRouter as Router } from "react-router-dom";
import { logoutAction } from "../../Store/Actions";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import HasPrivileges from "../../Helpers/HelperFns/Roles/HasPrivileges";
import { Privileges } from "../../Helpers/HelperFns/Roles/Privileges";

const MerqahNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar expand="md">
        <NavbarBrand className="logo_wrapper_style">
          <img
            src={require("../../Assets/img/logo.svg").default}
            className="img-fluid"
          />
        </NavbarBrand>
        <NavbarToggler onClick={handleToggleDropdown} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="w-100" navbar>

            <HasPrivileges can={[Privileges.CAN_VIEW_USERS, Privileges.CAN_VIEW_COLLECTORS]}>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/users"
                  activeClassName="active"
                >
                  users
                </NavLink>
              </NavItem>
            </HasPrivileges>


            <HasPrivileges can={[Privileges.CAN_VIEW_PICKUPS]}>

              <NavItem>
                <NavLink
                  className="nav-link"
                  to="/pickups"
                  activeClassName="active"
                >
                  pickups
                </NavLink>
              </NavItem>
            </HasPrivileges>
            <HasPrivileges can={[Privileges.CAN_VIEW_DONORS]}>
              <NavItem className="mr-lg-auto">
                <NavLink
                  className="nav-link"
                  to="/donors"
                  activeClassName="active"
                >
                  donors
                </NavLink>
              </NavItem>
            </HasPrivileges>

            <UncontrolledDropdown className="ml-md-auto" inNavbar>
              <DropdownToggle nav>
                <Avatar
                  // src={props?.img_path}
                  className="rounded_circle_user_img"
                >
                  {Helper.getIntials(props?.user?.name)}
                </Avatar>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => props.logoutAction()}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, { logoutAction })(MerqahNavbar);
