import MainApp from "./App/MainApp";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Store from "./App/Store";
import { persistor } from "./App/Store";

const App = (props) => {
  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <MainApp />
      </PersistGate>
    </Provider>
  );
};

export default App;
