import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import "./pagination.scss";

const Pagination = ({ tableRef, pagination, styleWraper, ...props }) => {
  const { firstItem, lastItem, total, currentPage } = pagination;

  const numPages = Math.ceil(pagination.total / pagination.perPage);

  const handleNext = () => {
    handlePaginate(currentPage != numPages ? currentPage + 1 : numPages);
  };
  const handlePrevious = () => {
    handlePaginate(currentPage != 1 ? currentPage - 1 : 1);
  };

  const handlePaginate = (page) => {
    props.onPaginate(page);
  };

  return (
    <div
      className={`${styleWraper} custom-pagination dataTables_paginate paging_simple_numbers d-flex pt-3 pr-0 m-0 justify-content-end`}
      id="DataTables_Table_0_paginate"
    >
      <div
        className="dataTables_info pr-2"
        id="DataTables_Table_0_info"
        role="status"
        aria-live="polite"
      >
        Showing {firstItem} to {lastItem} of {total} entries
      </div>
      <ul className="pagination">
        <li
          className="paginate_button page-item previous"
          id="DataTables_Table_0_previous"
        >
          <a
            aria-controls="DataTables_Table_0"
            data-dt-idx="0"
            tabIndex="0"
            className="page-link"
            onClick={handlePrevious}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </a>
        </li>
        {Array.from(
          {
            length:
              currentPage == numPages
                ? currentPage
                : currentPage + 3 > numPages
                ? numPages
                : currentPage + 3,
          },
          (_, i) => i + 1
        )
          .slice(currentPage - 4 > 0 ? currentPage - 4 : 0)
          .map((page) => (
            <li
              key={page}
              className={`paginate_button page-item ${
                page == currentPage ? "active" : " "
              }`}
            >
              <a
                aria-controls="DataTables_Table_0"
                data-dt-idx="1"
                tabIndex="0"
                className="page-link"
                onClick={() => handlePaginate(page)}
              >
                {page}
              </a>
            </li>
          ))}
        <li
          className="paginate_button page-item next "
          id="DataTables_Table_0_next"
        >
          <a
            aria-controls="DataTables_Table_0"
            data-dt-idx="2"
            tabIndex="0"
            className="page-link"
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </a>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state, { tableRef, reducer = "super" }) => {
  return {
    pagination: state[reducer][tableRef].pagination,
  };
};
export default connect(mapStateToProps)(Pagination);
