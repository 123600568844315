import React from "react";

const PickupAddresses = (props) => {
  return (
    <>
      {props?.pickup_address_data?.map((address, i) => (
        <div className="d-flex">
          <p className="address_title_style">Address {i + 1}</p>
          <p className="address_info_style">
            {address.name} - {address?.area?.name}, {address?.area?.city?.name}
          </p>
        </div>
      ))}
    </>
  );
};

export default PickupAddresses;
