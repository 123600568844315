import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import moment from "moment";

const PickupModal = (props) => {
  return (
    <>
      <Modal
        centered
        fade={false}
        isOpen={props?.ModalIsOpen}
        toggle={props?.toggleModal}
      >
        <ModalHeader>{props?.pickup_name}</ModalHeader>
        <ModalBody>
          <Timeline className="custom_timeline_wrapper_style">
            {props?.pickup_timeline_data?.reverse()?.map((pickup) => (
              <TimelineItem key={pickup?.id}>
                <TimelineOppositeContent>
                  <h1>{moment(pickup?.created_at).format("hh:mm a")}</h1>
                  <h2>
                    {moment(pickup?.created_at).format("DD-MM-yyyy")}
                    {/* {pickup?.date} */}
                  </h2>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    className={`${pickup.status}_status_style`}
                    variant="outlined"
                  />
                  <TimelineDot className="small_circle_style" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <h3>{pickup?.description}</h3>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PickupModal;
